import React, { useContext, useEffect, useState } from "react";
import Preloader from "../componant/Preloader";
import categoryCon from "../context/Category/CategoryContext";
import UrduKeyboard from "../componant/Urdu/UrduKeyboard";
import HindiKeyboard from "../componant/Hindi/HindiKeyboard";
import DataTableFooter from "../componant/DataTableFooter";

export const Category = (props) => {
  const [preloader, setPreloader] = useState("");

  /*   search */
  const [query, setquery] = useState("");

  /* search end */
  const myContext = useContext(categoryCon);
  const {
    category,
    total_category,
    createSubCategory,
    getAllCatagory,
    getCatagory,
    createCategory,
    updateCategory,
    deleteCategory,
    deleteSubCategory,
    updateSubCategory,
  } = myContext;

  useEffect(() => {
    // getAllCatagory("story")
    getCatagory(1, 10, "page");
  }, []);

  /*   console.log ( category.) */

  const edit_sub_cate = async (id, s) => {
    s.p_id = id;
    setupdatesubcat(s);

    seteditsubCategoryModal(!editsubCategoryModal);
  };

  const delsub_cate = async (id, sub_id) => {
    const res = await deleteSubCategory(id, sub_id);
    if (res.status === "Failed") {
      props.showAlert(res.message, "warning");
    } else {
      props.showAlert(res.message, "success");
    }
  };

  const style = {
    background: "#00000080",
    display: "block",
  };
  const [newCategory, setnewCategory] = useState({
    cat_name: "",
    cat_type: "",
    language: localStorage.getItem("lang"),
  });
  const [addCategoryModal, setAddCategoryModal] = useState(false);
  const [addsubCategoryModal, setAddsubCategoryModal] = useState(false);
  const [editsubCategoryModal, seteditsubCategoryModal] = useState(false);
  const [updatesubcate, setupdatesubcat] = useState({});

  const openData = () => {
    setAddCategoryModal(!addCategoryModal);
  };

  const openSubData = () => {
    setAddsubCategoryModal(!addsubCategoryModal);
  };

  const onChange = (e) => {
    setnewCategory({ ...newCategory, [e.target.name]: e.target.value });
  };

  const handleClick = async (e) => {
    e.preventDefault();
    setPreloader("preShow");
    if (
      localStorage.getItem("lang") === "2" ||
      localStorage.getItem("lang") === "1"
    ) {
      newCategory.cat_name = urdudata.cat_name;
    }
    const response = await createCategory(newCategory);
    console.log(newCategory);
    if (response.status === "success") {
      openData();
      props.showAlert(response.message, "success");
    } else {
      props.showAlert(response.message, "warning");
    }
    setPreloader("");
  };

  // delete category

  const delete_category = async (id) => {
    setPreloader("preShow");
    const response = await deleteCategory(id);
    console.log(id);
    if (response.status === "Success") {
      props.showAlert(response.message, "success");
    } else {
      props.showAlert(response.message, "warning");
    }
    setPreloader("");
  };

  // add sub category data
  const [newSubCat, setSubCat] = useState({ sub_cat_name: "", cat_id: "" });
  const onchange = (e) => {
    setSubCat({ ...newSubCat, [e.target.name]: e.target.value });
  };

  const save_sub = async (e) => {
    e.preventDefault();
    setPreloader("preShow");
    const subcat = await createSubCategory(newSubCat.cat_id, {
      sub_cat_name: newSubCat.sub_cat_name,
    });
    if (subcat.status === "Success") {
      openSubData();
    }
    setPreloader("");
  };
  const changeStatus = async (id, status, type) => {
    setPreloader("preShow");
    let response = {};
    if (type === "Category ") {
      response = await updateCategory({ status: status }, id);
    } else {
      response = await updateCategory({ status: status }, id);
    }
    response.status === "Success"
      ? props.showAlert(`${type}  status is updated`, "success")
      : props.showAlert(response.message, "danger");
    setPreloader("");
  };
  /* edit section */
  const onedit = (e) => {
    setupdateCat({ ...updateCat, [e.target.name]: e.target.value });
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    setPreloader("preShow");
    if (
      localStorage.getItem("lang") === "2" ||
      localStorage.getItem("lang") === "1"
    ) {
      updateCat.cat_name = urdudata.cat_name;
    }
    const response = await updateCategory(updateCat, updateCat._id);

    if (response.status === "Success") {
      openCatEdit({});
      props.showAlert(response.message, "success");
    } else {
      props.showAlert(response.message, "warning");
    }
    setPreloader("");
  };
  const [editCatModel, seteditCatModel] = useState(false);
  const [updateCat, setupdateCat] = useState({});
  const openCatEdit = (cat) => {
    console.log(cat);
    seteditCatModel(!editCatModel);
    setupdateCat(cat);
  };

  /* slider start */
  const [statusChange, setstatusChange] = useState(null);
  const clickBlock = (status) => {
    setstatusChange(status);
    console.log(statusChange);
    const st = category.filter((cat) => {
      return cat.status === statusChange;
    });
    console.log(st);
  };
  /* slider ends */

  const [urdudata, seturdudata] = useState({
    cat_name: "",
  });
  const inputUrdu = (key, value) => {
    urdudata[key] = value;
    console.log("key" + key + " value-" + value);
  };

  const handle_edit_sub = async (e) => {
    e.preventDefault();
    console.log(updatesubcate);
    setPreloader("preShow");
    const res = await updateSubCategory(updatesubcate);
    if (res.status === "Success") {
      props.showAlert(res.message, "success");
      seteditsubCategoryModal(!editsubCategoryModal);
    } else {
      props.showAlert(res.message, "warning");
    }
    setPreloader("");
  };

  // pagination
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  useEffect(() => {
    getCatagory(page, limit, "page");
    // getAllCatagory("blog", page, limit);
  }, [page, limit]);

  useEffect(() => {
    setPage(1);
    getCatagory(1, limit, "page");    
    // getAllCatagory("blog", 1, limit);
  }, [limit]);

  return (
    <>
      <Preloader show={preloader} />

      {editCatModel && (
        <div className="modal reviewModal" style={style}>
          <div className="modal-dialog" style={{ width: "40%" }}>
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Edit Category
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    openCatEdit({});
                  }}
                ></button>
              </div>
              <div className="modal-body">
                <form id="formAccountSettings">
                  <div className="row">
                    {/* category add section */}
                    <div className="col-md-12  my-1">
                      <form method="post">
                        <div className="form-group">
                          {localStorage.getItem("lang") === "0" ? (
                            <input
                              type="text"
                              className={`form-control   m-0`}
                              name="cat_name"
                              id=""
                              aria-describedby="helpId"
                              placeholder="Update Category Name"
                              defaultValue={updateCat.cat_name}
                              onChange={onedit}
                            />
                          ) : localStorage.getItem("lang") === "1" ? (
                            <HindiKeyboard
                              inputvalue={updateCat.cat_name}
                              keyinput="cat_name"
                              onchange={inputUrdu}
                            />
                          ) : localStorage.getItem("lang") === "2" ? (
                            <UrduKeyboard
                              inputvalue={updateCat.cat_name}
                              inputUrdu={inputUrdu}
                              inputkey="cat_name"
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      </form>
                      <div className="form-group">
                        <label className="form-label">
                          Update Meta Discription
                        </label>
                        <input
                          type="textarea"
                          className="form-control    m-0"
                          name="meta_description"
                          id=""
                          aria-describedby="helpId"
                          defaultValue={updateCat.meta_description}
                          placeholder="Update Meta Discription"
                          onChange={onedit}
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <label className="form-label"> Update Status</label>
                        <table width="100%">
                          <tr>
                            <td>
                              <div className="form-check">
                                <input
                                  type="radio"
                                  className="form-check-input"
                                  name="status"
                                  value={false}
                                  defaultChecked={
                                    updateCat.status ? false : true
                                  }
                                  onChange={onedit}
                                />
                                <label className="form-label"> Draft</label>
                              </div>
                            </td>
                            <td>
                              <div className="form-check">
                                <input
                                  type="radio"
                                  className="form-check-input"
                                  name="status"
                                  value={true}
                                  defaultChecked={
                                    updateCat.status ? true : false
                                  }
                                  onChange={onedit}
                                />
                                <label className="form-label"> Publish</label>
                              </div>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <label className="form-label">Update Meta Title</label>
                        <input
                          type="text"
                          className="form-control    m-0"
                          name="meta_title"
                          id=""
                          aria-describedby="helpId"
                          defaultValue={updateCat.meta_title}
                          placeholder="Update Meta Title"
                          onChange={onedit}
                        />
                      </div>

                      <div className="form-group">
                        <label className="form-label">Update Meta Others</label>
                        <input
                          type="text"
                          className="form-control    m-0"
                          name="meta_others"
                          id=""
                          aria-describedby="helpId"
                          defaultValue={updateCat.meta_others}
                          placeholder="Update Meta Others"
                          onChange={onedit}
                        />
                      </div>
                    </div>
                    {/* category add section end */}
                  </div>
                  <div className="mt-3">
                    <button
                      type="reset"
                      className="btn btn-outline-primary me-2 my-btn"
                      onClick={() => {
                        openCatEdit({});
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="btn orange-btn btn-primary me-2"
                      onClick={handleUpdate}
                    >
                      Update
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}

      {addCategoryModal && (
        <div className="modal reviewModal" style={style}>
          <div className="modal-dialog" style={{ width: "40%" }}>
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Add Category
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={openData}
                ></button>
              </div>
              <div className="modal-body">
                <form
                  id="formAccountSettings"
                  method="POST"
                  onSubmit={handleClick}
                >
                  <div className="row">
                    {/* category add section */}
                    <div className="col-md-12  my-1">
                      <form method="post">
                        <div className="form-group">
                          <label className="form-label">Category Name</label>
                          {localStorage.getItem("lang") === "0" ? (
                            <input
                              type="text"
                              className={`form-control mb-2  m-0`}
                              name="cat_name"
                              id=""
                              aria-describedby="helpId"
                              placeholder="Enter Title"
                              onChange={onChange}
                            />
                          ) : localStorage.getItem("lang") === "1" ? (
                            <HindiKeyboard
                              keyinput="cat_name"
                              onchange={inputUrdu}
                            />
                          ) : localStorage.getItem("lang") === "2" ? (
                            <UrduKeyboard
                              inputUrdu={inputUrdu}
                              inputkey="cat_name"
                            />
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="form-group">
                          <label className="form-label">Category Type</label>
                          <select
                            className="form-control mb-2"
                            name="cat_type"
                            onChange={onChange}
                          >
                            <option value="story">Story</option>
                            <option value="classified">Classified</option>
                            <option value="directory">Directory</option>
                          </select>
                        </div>
                      </form>
                      <div className="form-group">
                        <label className="form-label">
                          Enter Meta Discription
                        </label>
                        <textarea
                          type="textarea"
                          className="form-control mb-2   m-0"
                          name="meta_description"
                          id=""
                          aria-describedby="helpId"
                          placeholder="Enter Meta Discription"
                          onChange={onChange}
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <label className="form-label"> Status</label>
                        <table width="100%">
                          <tr>
                            <td>
                              <div className="form-check">
                                <input
                                  type="radio"
                                  className="form-check-input"
                                  name="status"
                                  value={0}
                                  onChange={onChange}
                                />
                                <label className="form-label"> Draft</label>
                              </div>
                            </td>
                            <td>
                              <div className="form-check">
                                <input
                                  type="radio"
                                  className="form-check-input"
                                  name="status"
                                  value={1}
                                  onChange={onChange}
                                />
                                <label className="form-label"> Publish</label>
                              </div>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <label className="form-label">Enter Meta Title</label>
                        <input
                          type="text"
                          className="form-control  mb-2  m-0"
                          name="meta_title"
                          id=""
                          aria-describedby="helpId"
                          placeholder="Enter Meta Title"
                          onChange={onChange}
                        />
                      </div>

                      <div className="form-group">
                        <label className="form-label">Enter Meta Others</label>
                        <input
                          type="text"
                          className="form-control    m-0"
                          name="meta_others"
                          id=""
                          aria-describedby="helpId"
                          placeholder="Enter Meta Others"
                          onChange={onChange}
                        />
                      </div>
                    </div>
                    {/* category add section end */}
                  </div>
                  <div className="mt-3">
                    <button
                      type="reset"
                      className="btn btn-outline-primary me-2 my-btn"
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="btn orange-btn btn-primary me-2"
                      onClick={handleClick}
                    >
                      Add
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}

      {addsubCategoryModal && (
        <div className="modal reviewModal" style={style}>
          <div className="modal-dialog" style={{ width: "40%" }}>
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Add Sub Category
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={openSubData}
                ></button>
              </div>
              <div className="modal-body">
                <form
                  id="formAccountSettings"
                  method="POST"
                  onSubmit={handleClick}
                >
                  <div className="row">
                    {/* category add section */}
                    <div className="col-md-12  my-1">
                      <form method="post">
                        <div className="form-group mb-2">
                          <select
                            type="text"
                            className="form-control    m-0"
                            name="cat_id"
                            id=""
                            aria-describedby="helpId"
                            placeholder="Category Name"
                            onChange={onchange}
                          >
                            <option value="">Select Category</option>
                            {category.map((cat, i) => {
                              return (
                                <option value={cat._id}>{cat.cat_name}</option>
                              );
                            })}
                          </select>
                        </div>

                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control    m-0"
                            name="sub_cat_name"
                            id=""
                            aria-describedby="helpId"
                            placeholder="Sub Category Name"
                            onChange={onchange}
                          />
                        </div>
                      </form>
                    </div>
                    {/* category add section end */}
                  </div>
                  <div className="mt-3">
                    <button
                      type="reset"
                      className="btn btn-outline-primary me-2 my-btn"
                      onClick={openSubData}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="btn orange-btn btn-primary me-2"
                      onClick={save_sub}
                    >
                      Add
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}

      {editsubCategoryModal && (
        <div className="modal reviewModal" style={style}>
          <div className="modal-dialog" style={{ width: "40%" }}>
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Update Sub Category
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    seteditsubCategoryModal(!editsubCategoryModal);
                  }}
                ></button>
              </div>
              <div className="modal-body">
                <form
                  id="formAccountSettings"
                  method="POST"
                  onSubmit={handle_edit_sub}
                >
                  <div className="row">
                    {/* category add section */}
                    <div className="col-md-12  my-1">
                      <form method="post">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control    m-0"
                            name="sub_cat_name"
                            id=""
                            aria-describedby="helpId"
                            placeholder="Sub Category Name"
                            defaultValue={updatesubcate.cat_name}
                            onChange={(e) => {
                              updatesubcate.cat_name = e.target.value;
                            }}
                          />
                        </div>
                      </form>
                    </div>
                    {/* category add section end */}
                  </div>
                  <div className="mt-3">
                    <button
                      type="reset"
                      className="btn btn-outline-primary me-2 my-btn"
                      onClick={() => {
                        seteditsubCategoryModal(!editsubCategoryModal);
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="btn orange-btn btn-primary me-2"
                      onClick={handle_edit_sub}
                    >
                      Save
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="content-wrapper">
        <div className="container-fluid flex-grow-1 container-p-y">
          <div className="fixed-button">
            <div
              className={`btn btn-sm fixed_button btn-outline-primary ${
                addCategoryModal ? "d-none" : "d-block"
              }`}
              onClick={openData}
            >
              + Add Category
            </div>
            <div
              className="btn btn-sm  bulkuplod btn-outline-primary"
              onClick={openSubData}
            >
              + Add Sub Category
            </div>
          </div>
          <div className="row">
            <h4 className="card-header c_heading">
              <span className="headingcontent">
                My Categories ({total_category})
              </span>
            </h4>

            <div className="col-lg-12 mb-5 pb-5 ">
              <div class="card">
                <h5 class="card-header">
                  <div className="row">
                    <div className="col-lg-6 font-bold">
                      <ul className="slider_button slider-white plain-orange">
                        <li
                          className={`${
                            statusChange === null ? "slider-active" : ""
                          }`}
                          onClick={() => {
                            clickBlock(null);
                          }}
                        >
                          All
                        </li>
                        <li
                          className={`${
                            statusChange !== null && statusChange === false
                              ? "slider-active"
                              : ""
                          }`}
                          onClick={() => {
                            clickBlock(false);
                          }}
                        >
                          Block
                        </li>
                        <li
                          className={`${
                            statusChange !== null && statusChange === true
                              ? "slider-active"
                              : ""
                          }`}
                          onClick={() => {
                            clickBlock(true);
                          }}
                        >
                          Unblock
                        </li>
                      </ul>
                    </div>
                    <div className="col-lg-6 text-right">
                      <input
                        type="text"
                        className="form-control search"
                        placeholder="Search Category..."
                        onChange={(e) => {
                          setquery(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </h5>

                <div class="table-responsive text-nowrap mb-1">
                  <table class="table table-hover">
                    <thead className="order_history_header">
                      <tr>
                        <th>Primary Category</th>
                        <th> Type</th>
                        {/*    <th>Language</th> */}
                        <th>Status </th>
                        {/*  <th>Meta Title</th>
                        <th>Meta Discription</th>
                        <th>Meta Others</th> */}
                        <th>Sub Category</th>
                      </tr>
                    </thead>
                    <tbody class="table-border-bottom-0 order_history_body">
                      {category
                        .filter((cat) => {
                          return (
                            statusChange === null ||
                            (cat.status === statusChange &&
                              (cat.cat_name
                                .toLowerCase()
                                .includes(query?.toLowerCase()) ||
                                cat.meta_title
                                  .toLowerCase()
                                  .includes(query.toLowerCase())))
                          );
                        })
                        .map((cat, index) => {
                          return (
                            <tr key={index}>
                              <td>
                                {cat.cat_name}
                                {/* <br /> <small>Section - {cat.section_id}</small> */}
                                <div className="dropdown ">
                                  <span className="badge bg-danger me-1">
                                    <i
                                      className="bx bx-trash "
                                      onClick={() => {
                                        delete_category(cat._id);
                                      }}
                                    ></i>
                                  </span>
                                  <span className="badge bg-secondary">
                                    <i
                                      className="bx bx-edit"
                                      onClick={() => {
                                        openCatEdit(cat);
                                      }}
                                    ></i>
                                  </span>
                                </div>
                              </td>
                              <td>{cat.cat_type}</td>
                              {/* <td>{cat.cat_type}</td> */}
                              {/*  <td>{cat.language}</td>  */}
                              <td>
                                {cat.status ? (
                                  <i
                                    className="bx bx-check"
                                    onClick={() => {
                                      changeStatus(cat._id, false, "Category ");
                                    }}
                                  ></i>
                                ) : (
                                  <i
                                    className="bx bx-block"
                                    onClick={() => {
                                      changeStatus(cat._id, true, "Category ");
                                    }}
                                  ></i>
                                )}
                              </td>

                              {/*  <td>{cat.meta_title}</td>
                            <td>{cat.meta_description}</td>
                            <td>{cat.meta_others}</td>  */}

                              <td>
                                {cat.sub_cat.map((s, j) => {
                                  return (
                                    <div
                                      className="dropdown subcategory"
                                      style={{ display: "inline-flex" }}
                                      key={j}
                                    >
                                      <button
                                        type="button"
                                        className="btn p-0 dropdown-toggle hide-arrow"
                                        data-bs-toggle="dropdown"
                                      >
                                        {s.cat_name}
                                        <i className="bx bx-dots-vertical-rounded"></i>
                                      </button>
                                      <div className="dropdown-menu">
                                        <p
                                          className="dropdown-item"
                                          onClick={() => {
                                            edit_sub_cate(cat._id, s);
                                          }}
                                        >
                                          <i className="bx bx-edit me-1"></i>
                                          Edit
                                        </p>
                                        <p
                                          className="dropdown-item"
                                          onClick={() => {
                                            delsub_cate(cat._id, s._id);
                                          }}
                                        >
                                          <i className="bx bx-trash me-1"></i>
                                          Delete
                                        </p>
                                      </div>
                                    </div>
                                  );
                                })}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                    <DataTableFooter
                      col={5}
                      range={
                        (page === 1 ? "1" : (page - 1) * limit) +
                        "-" +
                        (page === 1 ? limit : page * limit)
                      }
                      total_item={total_category}
                      optionChange={(e) => {
                        setLimit(e.target.value);
                      }}
                      prePage={() => {
                        if (page > 1) {
                          setPage(page - 1);
                        }
                      }}
                      nextPage={() => {
                        setPage(page + 1);
                      }}
                    />
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
