import React, { useState } from "react";
import categoryCon from "./CategoryContext";
/* require("dotenv").config(); */
const CatalogState = (props) => {
  const host = process.env.REACT_APP_URL;

  const token = localStorage.getItem("token");

  const [category, setCategory] = useState([]);
  const [total_category, set_total_category] = useState(0);

  const createCategory = async (body) => {
    try {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "auth-token": token,
        },
        body: JSON.stringify(body),
      };
      const response = await fetch(
        `${host}/api/catagory/createcatagory`,
        requestOptions
      );
      const dataresp = await response.json();
      console.log(dataresp);
      if (dataresp.status === "success") {
        setCategory(category.concat(dataresp.data));
      }
      return dataresp;
    } catch (e) {}
  };
  const updateCategory = async (body, id) => {
    try {
      const requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "auth-token": token,
        },
        body: JSON.stringify(body),
      };
      const response = await fetch(
        `${host}/api/catagory/updatecatagory/${id}`,
        requestOptions
      );
      const dataresp = await response.json();
      console.log(dataresp);
      if (dataresp.status === "Success") {
        for (let i = 0; i < category.length; i++) {
          let element = category[i];
          if (element._id.toString() === id) {
            category[i] = dataresp.data;
          }
        }
        setCategory(category);
      }
      return dataresp;
    } catch (e) {}
  };

  const deleteCategory = async (id) => {
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "auth-token": token,
      },
    };
    const response = await fetch(
      `${host}/api/catagory/deleteCategory/${id}`,
      requestOptions
    );
    const datares = await response.json();

    console.log(datares);
    /* setCategory(datares.data); */
    if (datares.status === "Success") {
      const updateData = category.filter((cat) => {
        return cat._id !== id;
      });

      setCategory(updateData);
    }
    return datares;
  };
  const getAllCatagory = async (type, page = 0, limit = 10) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "auth-token": token,
      },
    };
    const response = await fetch(
      `${host}/api/catagory/getCategory?type=${type}&page=${page}
      &limit=${limit}&pagination=${page !== 0 ? "page" : "all"}`,
      requestOptions
    );
    const datares = await response.json();

    if (datares.status === "Success") {
      // const upateData = datares.data.filter((cat) => {
      //   return cat.cat_type === type;
      // });
      // console.log("updat data = " + datares.data);
      // if (page > 1) {
      //   setCategory(category.concat(datares.data));
      // } else {
      set_total_category(datares.total);
      setCategory(datares.data);
      // }
    }
  };

  const getCatagory = async (page = 0, limit = 10, pagination) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "auth-token": token,
      },
    };
    const response = await fetch(
      `${host}/api/catagory/getCategory/?page=${page}
      &limit=${limit}&pagination=${pagination}`,
      requestOptions
    );
    const datares = await response.json();

    console.log(datares);
    if (datares.status === "Success") {
      setCategory(datares.data);
      set_total_category(datares.total);
    }
  };

  const createSubCategory = async (id, body) => {
    try {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "auth-token": token,
        },
        body: JSON.stringify(body),
      };
      const response = await fetch(
        `${host}/api/catagory/addSubCat/${id}`,
        requestOptions
      );
      const datares = await response.json();
      console.log(datares);
      if (datares.status === "Success") {
        const comp = datares.data;
        console.log(comp);
        let oldCategory = JSON.parse(JSON.stringify(category));
        for (let index = 0; index < oldCategory.length; index++) {
          const element = oldCategory[index];
          if (element._id === id) {
            oldCategory[index] = comp;
            //console.log(newCompany);
            break;
          }
        }
        setCategory(oldCategory);
      }
      return datares;
    } catch (e) {}
  };

  // delete sub category

  const deleteSubCategory = async (id, sub_id) => {
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "auth-token": token,
      },
    };
    const response = await fetch(
      `${host}/api/catagory/deleteSubCat/${id}/${sub_id}`,
      requestOptions
    );
    const datares = await response.json();

    console.log(datares);
    /* setCategory(datares.data); */
    if (datares.status === "Success") {
      for (let i = 0; i < category.length; i++) {
        const cat = category[i];
        for (let j = 0; j < cat.sub_cat.length; j++) {
          const sub_cat = cat.sub_cat[j];
          if (sub_cat._id === sub_id) {
            cat.sub_cat.splice(j, 1);
            break;
          }
        }
      }
      setCategory(category);
      console.log(category);
    }
    return datares;
  };

  const updateSubCategory = async (body) => {
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "auth-token": token,
      },
      body: JSON.stringify(body),
    };
    const response = await fetch(
      `${host}/api/catagory/updateSubCat/`,
      requestOptions
    );
    const datares = await response.json();

    console.log(datares);

    if (datares.status === "Success") {
      for (let i = 0; i < category.length; i++) {
        const cat = category[i];
        for (let j = 0; j < cat.sub_cat.length; j++) {
          const sub_cat = cat.sub_cat[j];
          if (sub_cat._id === body.sub_id) {
            cat.sub_cat.cat_name = body.cat_name;
            break;
          }
        }
      }
      setCategory(category);
    }
    return datares;
  };

  //writers functions

  const [writer_info, setWriter_info] = useState([]);

  const getAllWriter_Info = async () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "auth-token": token,
      },
    };
    const response = await fetch(
      `${host}/api/writer_info/getWriter_info`,
      requestOptions
    );
    const datares = await response.json();

    console.log(datares);
    if (datares.status === "Success") {
      setWriter_info(datares.data);
    }
  };

  return (
    <categoryCon.Provider
      value={{
        total_category,
        category,
        getAllCatagory,
        createCategory,
        deleteCategory,
        updateCategory,
        getCatagory,
        createSubCategory,
        updateSubCategory,
        writer_info,
        getAllWriter_Info,
        deleteSubCategory,
      }}
    >
      {props.children}
    </categoryCon.Provider>
  );
};
export default CatalogState;
