import React, { useContext, useRef, useEffect, useState } from "react";
import Preloader from "../componant/Preloader";
import blogPostCon from "../context/Blog-Post/Blog-PostCon";
import categoryCon from "../context/Category/CategoryContext";
import MediaCon from "../context/Media/MediaCon";
import JoditEditor from "jodit-react";
import parse from "html-react-parser";
import UrduKeyboard from "../componant/Urdu/UrduKeyboard";
import HindiKeyboard from "../componant/Hindi/HindiKeyboard";
import ChooseMedia from "../componant/Media/ChooseMedia";

export const Blog_Post = (props) => {
  const [preloader, setPreloader] = useState("");

  const myContext = useContext(blogPostCon);
  const {
    blog_post,
    get_BlogPost,
    addBlogPost,
    delete_BlogPost,
    updateBlogPost,
    updateBlogOrder,
  } = myContext;

  const catContext = useContext(categoryCon);
  const { category, getAllCatagory, writer_info, getAllWriter_Info } =
    catContext;

  /* jodit start */
  const editor = useRef(null);
  const [content, setContent] = useState("");
  console.log(content);
  /* jodit end */

  const [query, setquery] = useState("");
  useEffect(() => {
    get_BlogPost();
    getAllCatagory("blog");
    getAllWriter_Info();
  }, []);
  const style = {
    background: "#00000080",
    display: "block",
  };
  const [newBlogPost, setnewBlogpost] = useState({
    title: "",
    description: "",
    is_admin_post: true,
    u_id: "",
    order: "",
    image: "",
    status: false,
    url: "",
    language: localStorage.getItem("lang"),
  });

  const [addBlog_Post_Model, setAddBlog_Post_Model] = useState(false);

  const openData = () => {
    setAddBlog_Post_Model(!addBlog_Post_Model);
    newBlogPost.image = "";
  };

  const delete_blog_post = async (id) => {
    setPreloader("preShow");
    const response = await delete_BlogPost(id);
    console.log(id);
    if (response.status === "Success") {
      props.showAlert(response.message, "success");
    } else {
      props.showAlert(response.message, "warning");
    }
    setPreloader("");
  };
  const handleClick = async (e) => {
    e.preventDefault();
    if (
      localStorage.getItem("lang") === "2" ||
      localStorage.getItem("lang") === "1"
    ) {
      newBlogPost.title = urdudata.title;
    }
    newBlogPost.description = content;
    setPreloader("preShow");
    const response = await addBlogPost(newBlogPost);
    console.log(newBlogPost);
    if (response.status === "success") {
      openData();
      props.showAlert(response.message, "success");
    } else {
      props.showAlert(response.message, "warning");
    }
    setPreloader("");
  };
  const changPostOrder = async (id, type) => {
    setPreloader("preShow");
    const response = await updateBlogOrder(id, type);
    if (response.status === "success") {
      props.showAlert(response.message, "success");
      get_BlogPost();
    }
    setPreloader("");
  };

  const onChange = (e) => {
    setnewBlogpost({ ...newBlogPost, [e.target.name]: e.target.value });
  };
  const [mediaModel, setmediaModel] = useState(false);
  const myConte = useContext(MediaCon);
  const { media, getAllMedia } = myConte;
  const openMedia = async () => {
    setmediaModel(!mediaModel);
    if (!mediaModel) {
      setPreloader("preShow");
      await getAllMedia();
      setPreloader("");
    }
  };

  const selectImage = (url) => {
    openMedia();
    setnewBlogpost({ ...newBlogPost, image: url });
  };

  /*   status update */
  const changeStatus = async (id, status, type) => {
    setPreloader("preShow");
    let response = {};
    if (type === "Admin") {
      response = await updateBlogPost(id, { is_admin_post: status });
    } else {
      response = await updateBlogPost(id, { status: status });
    }
    response.status === "Success"
      ? props.showAlert(`${type}  status is updated`, "success")
      : props.showAlert(response.message, "danger");
    setPreloader("");
  };

  /* edit section */
  const onedit = (e) => {
    setupdateBpost({ ...updateBpost, [e.target.name]: e.target.value });
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    setPreloader("preShow");
    if (
      localStorage.getItem("lang") === "2" ||
      localStorage.getItem("lang") === "1"
    ) {
      updateBpost.title = urdudata.title;
    }
    const response = await updateBlogPost(updateBpost._id, updateBpost);
    if (response.status === "Success") {
      openBpostEdit({});
      props.showAlert(response.message, "success");
    } else {
      props.showAlert(response.message, "warning");
    }
    setPreloader("");
  };
  const [editBpostModel, seteditBpostModel] = useState(false);
  const [updateBpost, setupdateBpost] = useState({});
  const openBpostEdit = (cat) => {
    console.log(cat);
    seteditBpostModel(!editBpostModel);
    setupdateBpost(cat);
  };
  /* slider start */
  const [statusChange, setstatusChange] = useState(null);
  const clickBlock = (status) => {
    setstatusChange(status);
    console.log(statusChange);
    const st = blog_post.filter((blog_post) => {
      return blog_post.status === statusChange;
    });
    console.log(st);
  };
  /* slider ends */

  const [urdudata, seturdudata] = useState({ title: "" });

  const inputUrdu = (key, value) => {
    urdudata[key] = value;
    console.log("key" + key + " value-" + value);
  };

  return (
    <>
      <Preloader show={preloader} />
      {editBpostModel && (
        <div className="modal reviewModal" style={style}>
          <div className="modal-dialog" style={{ width: "80%" }}>
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Upadte Blog Post
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    openBpostEdit({});
                  }}
                ></button>
              </div>
              <div className="modal-body">
                <form id="formAccountSettings">
                  <div className="row">
                    {/* category add section */}
                    <div className="col-md-12  my-1">
                      {/*   <form method="post"> */}

                      <div className="form-group">
                        <label className="form-label">Enter Title</label>
                        {localStorage.getItem("lang") === "0" ? (
                          <input
                            type="text"
                            className={`form-control   m-0`}
                            name="title"
                            id=""
                            aria-describedby="helpId"
                            placeholder="Update Writer title"
                            defaultValue={updateBpost.title}
                            onChange={onedit}
                          />
                        ) : localStorage.getItem("lang") === "1" ? (
                          <HindiKeyboard
                            inputvalue={updateBpost.title}
                            keyinput="title"
                            onchange={inputUrdu}
                          />
                        ) : localStorage.getItem("lang") === "2" ? (
                          <UrduKeyboard
                            inputvalue={updateBpost.title}
                            inputUrdu={inputUrdu}
                            inputkey="title"
                          />
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="form-group">
                        <label className="form-label">Enter Discription</label>
                        <JoditEditor
                          ref={editor}
                          value={content}
                          onChange={(newContent) => setContent(newContent)}
                        />
                      </div>
                      <div className="form-group">
                        <label className="form-label">Enter description</label>
                        <input
                          type="text"
                          className="form-control    m-0"
                          name="description"
                          id=""
                          aria-describedby="helpId"
                          value={updateBpost.description}
                          placeholder="description"
                          onChange={onedit}
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <label className="form-label">Choose Category</label>
                        <select
                          className="form-control"
                          name="cat_id"
                          onChange={onedit}
                        >
                          <option value={0}>Choose Category</option>
                          {category.map((cat, i) => {
                            return (
                              <option value={cat._id}>{cat.cat_name}</option>
                            );
                          })}
                        </select>
                      </div>

                      <div className="form-group">
                        <label className="form-label">Enter User Id</label>
                        <select
                          className="form-control"
                          name="u_id"
                          onChange={onedit}
                        >
                          <option value={0}>Choose writers</option>
                          {writer_info.map((writers, i) => {
                            return (
                              <option value={writers._id}>
                                {writers.name}
                              </option>
                            );
                          })}
                        </select>
                      </div>

                      <div className="form-group">
                        <label className="form-label">Enter Order</label>
                        <input
                          type="text"
                          className="form-control    m-0"
                          name="order"
                          id=""
                          aria-describedby="helpId"
                          value={updateBpost.order}
                          placeholder="Order"
                          onChange={onChange}
                        />
                      </div>
                      <div className="form-group">
                        <label className="form-label"> Is Admin Post</label>
                        <table width="100%">
                          <tr>
                            <td>
                              <div className="form-check">
                                <input
                                  type="radio"
                                  className="form-check-input"
                                  name="is_admin_post"
                                  value={false}
                                  defaultChecked={
                                    updateBpost.is_admin_post ? false : true
                                  }
                                  onChange={onedit}
                                />
                                <label className="form-label"> No</label>
                              </div>
                            </td>
                            <td>
                              <div className="form-check">
                                <input
                                  type="radio"
                                  className="form-check-input"
                                  name="is_admin_post"
                                  value={true}
                                  defaultChecked={
                                    updateBpost.is_admin_post ? true : false
                                  }
                                  onChange={onedit}
                                />
                                <label className="form-label"> Yes</label>
                              </div>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <label className="form-label"> Status</label>
                        <table width="100%">
                          <tr>
                            <td>
                              <div className="form-check">
                                <input
                                  type="radio"
                                  className="form-check-input"
                                  name="status"
                                  value={false}
                                  defaultChecked={
                                    updateBpost.status ? false : true
                                  }
                                  onChange={onedit}
                                />
                                <label className="form-label"> Draft</label>
                              </div>
                            </td>
                            <td>
                              <div className="form-check">
                                <input
                                  type="radio"
                                  className="form-check-input"
                                  name="status"
                                  value={true}
                                  defaultChecked={
                                    updateBpost.status ? true : false
                                  }
                                  onChange={onedit}
                                />
                                <label className="form-label"> Publish</label>
                              </div>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="form-group">
                        <label className="form-label">Enter URL</label>
                        <input
                          type="text"
                          className="form-control    m-0"
                          name="url"
                          id=""
                          aria-describedby="helpId"
                          value={updateBpost.url}
                          placeholder="URL"
                          onChange={onedit}
                        />
                      </div>

                      <div className="form-group">
                        <label className="form-label">Choose Image</label>
                        <div className="image_div_content" onClick={openMedia}>
                          <i className="bx bxs-image-add"></i>
                          <p className="normalhyperlink">click to upload</p>
                        </div>
                        <div className="">
                          <img
                            className={`img-fluid ${
                              newBlogPost.image === "" ? "d-none" : "d-block"
                            }`}
                            src={newBlogPost.image}
                            alt="selected_image"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* category add section end */}

                  <div className="mt-3">
                    <button
                      type="reset"
                      className="btn btn-outline-primary me-2 my-btn"
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="btn orange-btn btn-primary me-2"
                      onClick={handleUpdate}
                    >
                      Update
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}

      {addBlog_Post_Model && (
        <div className="modal reviewModal" style={style}>
          <div className="modal-dialog" style={{ width: "80%" }}>
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Add New Blog Post
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={openData}
                ></button>
              </div>
              <div className="modal-body">
                <form
                  id="formAccountSettings"
                  method="POST"
                  onSubmit={handleClick}
                >
                  <div className="row">
                    {/* category add section */}
                    <div className="col-md-12  my-1">
                      {/*   <form method="post"> */}

                      <div className="form-group">
                        <label className="form-label">Enter Title</label>
                        {localStorage.getItem("lang") === "0" ? (
                          <input
                            type="text"
                            className={`form-control   m-0`}
                            name="title"
                            id=""
                            aria-describedby="helpId"
                            placeholder="Name"
                            onChange={onChange}
                          />
                        ) : localStorage.getItem("lang") === "1" ? (
                          <HindiKeyboard
                            keyinput="title"
                            onchange={inputUrdu}
                          />
                        ) : localStorage.getItem("lang") === "2" ? (
                          <UrduKeyboard
                            inputUrdu={inputUrdu}
                            inputkey="title"
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <label className="form-label">Choose Category</label>
                        <select
                          className="form-control"
                          name="cat_id"
                          onChange={onChange}
                        >
                          <option value={0}>Choose Category</option>
                          {category.map((cat, i) => {
                            return (
                              <option value={cat._id}>{cat.cat_name}</option>
                            );
                          })}
                        </select>
                      </div>

                      <div className="form-group">
                        <label className="form-label">Enter User Id</label>
                        <select
                          className="form-control"
                          name="u_id"
                          onChange={onChange}
                        >
                          <option value={0}>Choose writers</option>
                          {writer_info.map((writers, i) => {
                            return (
                              <option value={writers.id}>{writers.name}</option>
                            );
                          })}
                        </select>
                      </div>

                      <div className="form-group">
                        <label className="form-label"> Is Admin Post</label>
                        <table width="100%">
                          <tr>
                            <td>
                              <div className="form-check">
                                <input
                                  type="radio"
                                  className="form-check-input"
                                  name="adminpost"
                                  value={0}
                                  onChange={onChange}
                                />
                                <label className="form-label"> No</label>
                              </div>
                            </td>
                            <td>
                              <div className="form-check">
                                <input
                                  type="radio"
                                  className="form-check-input"
                                  name="adminpost"
                                  value={1}
                                  onChange={onChange}
                                />
                                <label className="form-label"> Yes</label>
                              </div>
                            </td>
                          </tr>
                        </table>
                      </div>

                      <div className="form-group">
                        <label className="form-label"> Status</label>
                        <table width="100%">
                          <tr>
                            <td>
                              <div className="form-check">
                                <input
                                  type="radio"
                                  className="form-check-input"
                                  name="status"
                                  value={0}
                                  onChange={onChange}
                                />
                                <label className="form-label"> Draft</label>
                              </div>
                            </td>
                            <td>
                              <div className="form-check">
                                <input
                                  type="radio"
                                  className="form-check-input"
                                  name="status"
                                  value={1}
                                  onChange={onChange}
                                />
                                <label className="form-label"> Publish</label>
                              </div>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div className="col-md-6 col-6">
                      <div className="form-group">
                        <label className="form-label">Enter URL</label>
                        <input
                          type="text"
                          className="form-control    m-0"
                          name="url"
                          id=""
                          aria-describedby="helpId"
                          placeholder="URL"
                          onChange={onChange}
                        />
                      </div>

                      <div className="form-group">
                        <label className="form-label">Choose Image</label>

                        <div className="" onClick={openMedia}>
                          <img
                            className="img-fluid"
                            src={
                              newBlogPost.image === undefined ||
                              newBlogPost.image === ""
                                ? "./assets/img/illustrations/upload.png"
                                : newBlogPost.image
                            }
                            alt="selected_image"
                            width={150}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12 col-12">
                      <div className="form-group">
                        <label className="form-label">Enter description</label>
                        <JoditEditor
                          ref={editor}
                          value={content}
                          onChange={(newContent) => setContent(newContent)}
                        />
                      </div>
                    </div>
                  </div>
                  {/* category add section end */}

                  <div className="mt-3">
                    <button
                      type="reset"
                      className="btn btn-outline-primary me-2 my-btn"
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="btn orange-btn btn-primary me-2"
                      onClick={handleClick}
                    >
                      Add
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}

      {mediaModel && <ChooseMedia open={openMedia} selectImage={selectImage} />}

      <div className="content-wrapper">
        <div className="container-fluid flex-grow-1 container-p-y">
          <div className="fixed-button">
            <div
              className={`btn btn-sm fixed_button btn-outline-primary ${
                addBlog_Post_Model ? "d-none" : "d-block"
              }`}
              onClick={openData}
            >
              + Add post
            </div>
          </div>
          <div className="row">
            <h4 className="card-header c_heading">
              <span className="headingcontent">
                My Blog Posts({blog_post.length})
              </span>
            </h4>

            <div className="col-lg-12 mb-4 ">
              <div class="card">
                <h5 class="card-header">
                  <div className="row">
                    <div className="col-lg-6 font-bold">
                      <ul className="slider_button slider-white plain-orange">
                        <li
                          className={`${
                            statusChange === null ? "slider-active" : ""
                          }`}
                          onClick={() => {
                            clickBlock(null);
                          }}
                        >
                          All
                        </li>
                        <li
                          className={`${
                            statusChange !== null && statusChange === false
                              ? "slider-active"
                              : ""
                          }`}
                          onClick={() => {
                            clickBlock(false);
                          }}
                        >
                          Block
                        </li>
                        <li
                          className={`${
                            statusChange !== null && statusChange === true
                              ? "slider-active"
                              : ""
                          }`}
                          onClick={() => {
                            clickBlock(true);
                          }}
                        >
                          Unblock
                        </li>
                      </ul>
                    </div>
                    <div className="col-lg-6 text-right">
                      <input
                        type="text"
                        className="form-control search"
                        placeholder="Search Posts..."
                        onChange={(e) => {
                          setquery(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </h5>

                <div class="table-responsive text-nowrap mb-1">
                  <table class="table table-hover">
                    <thead className="order_history_header">
                      <tr>
                        <th className="action">Action</th>
                        <th>Old ID</th>
                        <th>Image</th>
                        <th> Title</th>
                        {/*  <th>description</th> */}
                        <th>Is Admin Post</th>
                        {/* <th>Category </th> */}
                        {/* <th>User Id</th> */}
                        <th>Date</th>
                        <th>Order</th>
                        <th>Language</th>
                        <th>Status</th>
                        {/*     <th>Url</th> */}
                      </tr>
                    </thead>
                    <tbody class="table-border-bottom-0 order_history_body">
                      {blog_post
                        .filter((blog_post) => {
                          return (
                            (statusChange === null ||
                              blog_post.status === statusChange) &&
                            blog_post.title
                              ?.toLowerCase()
                              .includes(query?.toLowerCase())
                          );
                        })
                        .map((blog_obj, index) => {
                          const date = blog_obj.date.split("T");
                          return (
                            <tr key={index}>
                              <td className="action">
                                <div className="dropdown ">
                                  <span className="badge bg-danger me-1">
                                    <i
                                      className="bx bx-trash "
                                      onClick={() => {
                                        delete_BlogPost(blog_obj._id);
                                      }}
                                    ></i>
                                  </span>
                                  <span className="badge bg-secondary">
                                    <i
                                      className="bx bx-edit"
                                      onClick={() => {
                                        openBpostEdit(blog_obj);
                                      }}
                                    ></i>
                                  </span>
                                </div>
                              </td>
                              <td>{blog_obj.id}</td>
                              <td>
                                <img
                                  className=""
                                  style={{ height: "100px", width: "100px" }}
                                  src={`${process.env.REACT_APP_URL}/media/blog/${blog_obj.image}`}
                                  alt={`blog_image_${index}`}
                                />
                              </td>
                              <td>{blog_obj.title}</td>
                              {/*   <td>{parse(blog_obj.description)}</td> */}
                              <td>
                                {blog_obj.is_admin_post ? (
                                  <span className="badge bg-success">
                                    <i
                                      className="bx bx-check"
                                      onClick={() => {
                                        changeStatus(
                                          blog_obj._id,
                                          false,
                                          "Admin"
                                        );
                                      }}
                                    ></i>
                                  </span>
                                ) : (
                                  <span className="badge bg-danger">
                                    <i
                                      className="bx bx-block"
                                      onClick={() => {
                                        changeStatus(
                                          blog_obj._id,
                                          true,
                                          "Admin"
                                        );
                                      }}
                                    ></i>
                                  </span>
                                )}
                              </td>
                              {/* <td>
                                {blog_obj.cat_id === null
                                  ? "..."
                                  : blog_obj.cat_id.cat_name}
                              </td>
                              <td>
                                {blog_obj.u_id === null
                                  ? "..."
                                  : blog_obj.u_id.name}
                              </td> */}

                              <td>{date[0]} </td>
                              <td>
                                {blog_post.length === blog_obj.order ? (
                                  ""
                                ) : (
                                  <i
                                    class="bx bxs-up-arrow"
                                    onClick={() => {
                                      changPostOrder(blog_obj._id, "increase");
                                    }}
                                  ></i>
                                )}
                                {blog_obj.order === 1 ? (
                                  ""
                                ) : (
                                  <i
                                    class="bx bxs-down-arrow"
                                    onClick={() => {
                                      changPostOrder(blog_obj._id, "decrease");
                                    }}
                                  ></i>
                                )}

                                {blog_obj.order}
                              </td>
                              <td>{blog_obj.language}</td>
                              <td>
                                {blog_obj.status ? (
                                  <i
                                    className="bx bx-check"
                                    onClick={() => {
                                      changeStatus(
                                        blog_obj._id,
                                        false,
                                        "post "
                                      );
                                    }}
                                  ></i>
                                ) : (
                                  <i
                                    className="bx bx-block"
                                    onClick={() => {
                                      changeStatus(blog_obj._id, true, "post");
                                    }}
                                  ></i>
                                )}
                              </td>
                              {/*  <td>{blog_obj.url}</td> */}
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
