import React, { useContext, useEffect, useState } from "react";

import Preloader from "../componant/Preloader";
import MediaCon from "../context/Media/MediaCon";
import categoryCon from "../context/Category/CategoryContext";

export const Media = (props) => {

    const [preloader, setPreloader] = useState("");

    const catContext = useContext(categoryCon)
    const { category,
        getAllCatagory,
    } = catContext
    const myContext = useContext(MediaCon)
    const { media,
        getAllMedia, deleteMedia, uploadMedia, uploadYtube, UpdateTitle } = myContext

    useEffect(() => {
        getAllMedia()
        getAllCatagory("blog");

    }, [])
    const style = {
        background: "#00000080",
        display: "block",
    };
    const [newMedia, setnewMedia] = useState({
        files: [],
        m_type: false
    })
    const [addMediaModal, setAddMediaModal] = useState(false);
    const [mediaInfo, setmediaInfo] = useState({
        "title": "",
        "title2": "",
        "title3": "",
        "cat_id": "",
        "m_type": "youtube",
        "m_url": ""
    });
    const onChangeinfo = (e) => {
        setmediaInfo({ ...mediaInfo, [e.target.name]: e.target.value })
    }

    const openData = () => {
        setAddMediaModal(!addMediaModal);
    };
/* const [newYTube, setnewYTube] = useState({
    "title": "",
    "title2": "",
    "title3": "",
    "cat_id":"",
    "m_type":"youtube",
    "m_url":""
}) */
/* const OnYChange=(e)=>{
setnewYTube({...newYTube,[e.target.name]:e.target.value})
}
  */   const handleClick = async (e) => {
        setPreloader("preShow");
        e.preventDefault();
        let from_data = null
        let response;
        if (!yTube) {
            from_data = new FormData()
            for (let i = 0; i < newMedia.files.length; i++) {
                const element = newMedia.files[i];
                from_data.append("file", element)
            }
            from_data.append("title", mediaInfo.title)
            from_data.append("title2", mediaInfo.title2)
            from_data.append("title3", mediaInfo.title3)
            from_data.append("cat_id", mediaInfo.cat_id)
            response = await uploadMedia(from_data)
        }
        else {
            response = await uploadYtube(mediaInfo)
        }

        if (response.status === "success") {
            props.showAlert(response.message, "success")
            openData();
            getAllMedia()
            setnewMedia({
                files: [],
                m_type: false
            })

        }
        else {
            props.showAlert(response.message, "warning")
        }
        setPreloader("")

        console.log(newMedia);

    }
    const delete_media = async (id) => {
        setPreloader("preShow")
        const response = await deleteMedia(id)
        console.log(id);
        if (response.status === "Success") {
            props.showAlert(response.message, "success")
        }
        else {
            props.showAlert(response.message, "danger")
        }
        setPreloader("")
    }
    const onChange = (e) => {
        setnewMedia({ files: [...newMedia.files, ...e.target.files] })
    }
    const [changeimg, setchangeimg] = useState("image")
    const ImageChange = ((m_type) => {
        setchangeimg(m_type)

    })

    const [yTube, setyTube] = useState(false)

    const totalemdia = media.filter((img) => {
        return img.m_type?.includes(changeimg?.toLowerCase())
    })
    console.log(totalemdia);

    const onedit = (e) => {
        setupdatetitle({ ...updatetitle, [e.target.name]: e.target.value });
    };
    const handleUpdate = async (e) => {
        e.preventDefault();
        setPreloader("preShow");

        const response = await UpdateTitle(updatetitle._id,updatetitle);

        if (response.status === "Success") {
            openTitleUp({});
            props.showAlert(response.message, "success");
        } else {
            props.showAlert(response.message, "warning");
        }
        setPreloader("");
    };


    const [editTitleModel, setedittitle] = useState(false);
    const [updatetitle, setupdatetitle] = useState({});
    const openTitleUp = (media) => {
        setedittitle(!editTitleModel);
        setupdatetitle(media);
    };
    return (
        <>
            <Preloader show={preloader} />
            {editTitleModel && (
                <div className="modal reviewModal" style={style}>
                    <div className="modal-dialog" style={{ width: "40%" }}>
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">
                                    Update Media
                                </h5>
                                <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                    onClick={() => {
                                        openTitleUp({});
                  }}
                                ></button>
                            </div>
                            <div className="modal-body">
                                <form
                                    id="formAccountSettings"
                                    method="POST"
                                //onSubmit={handleClick}
                                >
                                    <div className="row">
                                        {/* category add section */}
                                        <div className="col-md-12  my-1">
                                            <div className="form-group">
                                                <label className="form-label"> Select Media Type</label>
                                                <table width="100%">
                                                    <tr>
                                                        <td><div className="form-check">
                                                            <input type="radio" className="form-check-input" name="type" defaultChecked={true} value={false} onChange={() => { setyTube(false) }} />
                                                            <label className="form-label"> Media</label>
                                                        </div></td>
                                                        <td><div className="form-check">
                                                            <input type="radio" className="form-check-input" name="type" value={true} onChange={() => { setyTube(true) }} />
                                                            <label className="form-label"> YouTube Link</label>
                                                        </div></td>

                                                    </tr>
                                                </table></div>

                                            <form method="post">
                                                <div className="form-group">
                                                    <label className="form-label">Choose Category</label>
                                                    <select className="form-control" name="cat_id" onChange={onedit} >
                                                        <option value={0}>Choose Category</option>
                                                        {category.map((cat, i) => {
                                                            return (<option value={cat._id}>{cat.cat_name}</option>)
                                                        })}
                                                    </select>

                                                </div>
                                                <label className="form-label">Enter Title</label>
                                                <input
                                                    type="text"
                                                    className="form-control    m-0"
                                                    name="title"
                                                    id=""
                                                    multiple
                                                    aria-describedby="helpId"
                                                    placeholder="Enter Title"
                                                    defaultValue={updatetitle.title}
                                                    onChange={(e) => {
                                                        onedit(e)
                                                    }}
                                                />
                                                <label className="form-label">Enter title urdu</label>
                                                <input
                                                    type="text"
                                                    className="form-control    m-0"
                                                    name="title2"
                                                    id=""
                                                    multiple
                                                    aria-describedby="helpId"
                                                    defaultValue={updatetitle.title2}
                                                    placeholder="Enter Title Urdu"
                                                    onChange={(e) => {
                                                        onedit(e)
                                                    }}
                                                />   <label className="form-label">Enter title hindi</label>
                                                <input
                                                    type="text"
                                                    className="form-control    m-0"
                                                    name="title3"
                                                    id=""
                                                    multiple
                                                    aria-describedby="helpId"
                                                    placeholder="Enter Title Hindi"
                                                    defaultValue={updatetitle.title3}
                                                    onChange={onedit}
                                                />
                                                {(yTube) ?
                                                    <>




                                                        <div className="form-group">
                                                            <label className="form-label">Enter YouTube Link</label>
                                                            <input
                                                                type="text"
                                                                className="form-control    m-0"
                                                                name="m_url"
                                                                id=""
                                                                multiple
                                                                aria-describedby="helpId"
                                                                placeholder="Enter YouTube LInk"
                                                                defaultValue={updatetitle.m_url}
                                                                onChange={onedit}
                                                            />
                                                        </div>

                                                    </> : <>


                                                        <div className="form-group">
                                                            <label className="form-label">Choose File</label>
                                                            <input
                                                                type="file"
                                                                className="form-control    m-0"
                                                                name="file"
                                                                id=""
                                                                multiple
                                                                aria-describedby="helpId"

                                                                onChange={onedit}
                                                            />
                                                        </div>

                                                    </>}


                                            </form>
                                        </div>
                                        {/* category add section end */}
                                    </div>
                                    <div className="mt-3">
                                        <button
                                            type="reset"
                                            className="btn btn-outline-primary me-2 my-btn"
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            type="submit"
                                            className="btn orange-btn btn-primary me-2"
                                            onClick={handleUpdate}
                                        >
                                            Update 
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {addMediaModal && (
                <div className="modal reviewModal" style={style}>
                    <div className="modal-dialog" style={{ width: "40%" }}>
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">
                                    Add Media
                                </h5>
                                <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                    onClick={openData}
                                ></button>
                            </div>
                            <div className="modal-body">
                                <form
                                    id="formAccountSettings"
                                    method="POST"
                                //onSubmit={handleClick}
                                >
                                    <div className="row">
                                        {/* category add section */}
                                        <div className="col-md-12  my-1">
                                            <div className="form-group">
                                                <label className="form-label"> Select Media Type</label>
                                                <table width="100%">
                                                    <tr>
                                                        <td><div className="form-check">
                                                            <input type="radio" className="form-check-input" name="type" defaultChecked={true} value={false} onChange={() => { setyTube(false) }} />
                                                            <label className="form-label"> Media</label>
                                                        </div></td>
                                                        <td><div className="form-check">
                                                            <input type="radio" className="form-check-input" name="type" value={true} onChange={() => { setyTube(true) }} />
                                                            <label className="form-label"> YouTube Link</label>
                                                        </div></td>

                                                    </tr>
                                                </table></div>

                                            <form method="post">
                                                <div className="form-group">
                                                    <label className="form-label">Choose Category</label>
                                                    <select className="form-control" name="cat_id" onChange={onChangeinfo} >
                                                        <option value={0}>Choose Category</option>
                                                        {category.map((cat, i) => {
                                                            return (<option value={cat._id}>{cat.cat_name}</option>)
                                                        })}
                                                    </select>

                                                </div>
                                                <label className="form-label">Enter Title</label>
                                                <input
                                                    type="text"
                                                    className="form-control    m-0"
                                                    name="title"
                                                    id=""
                                                    multiple
                                                    aria-describedby="helpId"
                                                    placeholder="Enter Title"
                                                    onChange={(e) => {
                                                        onChangeinfo(e)
                                                    }}
                                                />
                                                <label className="form-label">Enter title urdu</label>
                                                <input
                                                    type="text"
                                                    className="form-control    m-0"
                                                    name="title2"
                                                    id=""
                                                    multiple
                                                    aria-describedby="helpId"
                                                    placeholder="Enter Title Urdu"
                                                    onChange={(e) => {
                                                        onChangeinfo(e)
                                                    }}
                                                />   <label className="form-label">Enter title hindi</label>
                                                <input
                                                    type="text"
                                                    className="form-control    m-0"
                                                    name="title3"
                                                    id=""
                                                    multiple
                                                    aria-describedby="helpId"
                                                    placeholder="Enter Title Hindi"
                                                    onChange={(e) => {
                                                        onChangeinfo(e)
                                                    }}
                                                />
                                                {(yTube) ?
                                                    <>




                                                        <div className="form-group">
                                                            <label className="form-label">Enter YouTube Link</label>
                                                            <input
                                                                type="text"
                                                                className="form-control    m-0"
                                                                name="m_url"
                                                                id=""
                                                                multiple
                                                                aria-describedby="helpId"
                                                                placeholder="Enter YouTube LInk"
                                                                onChange={onChangeinfo}
                                                            />
                                                        </div>

                                                    </> : <>


                                                        <div className="form-group">
                                                            <label className="form-label">Choose File</label>
                                                            <input
                                                                type="file"
                                                                className="form-control    m-0"
                                                                name="file"
                                                                id=""
                                                                multiple
                                                                aria-describedby="helpId"

                                                                onChange={onChange}
                                                            />
                                                        </div>

                                                    </>}


                                            </form>
                                        </div>
                                        {/* category add section end */}
                                    </div>
                                    <div className="mt-3">
                                        <button
                                            type="reset"
                                            className="btn btn-outline-primary me-2 my-btn"
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            type="submit"
                                            className="btn orange-btn btn-primary me-2"
                                            onClick={handleClick}
                                        >
                                            Add
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <div className="content-wrapper">
                <div className="container-fluid flex-grow-1 container-p-y">
                    <div className="fixed-button">
                        <div
                            className="btn btn-sm fixed_button btn-outline-primary"
                            onClick={openData}
                        >
                            + Add Media
                        </div>

                    </div>
                    <div className="row">
                        <h4 className="card-header c_heading">
                            <span className="headingcontent">
                                {/*  My Media ({media.length}) */}
                                My Media ({totalemdia.length})

                            </span>


                        </h4>

                        <div className="col-lg-12 mb-4 ">
                            <div className="card">
                                <h5 className="card-header">
                                    <div className="row">
                                        <div className="col-lg-6 font-bold">
                                            <ul className="slider_button slider-white plain-orange">
                                                <li className={`${changeimg !== "audio" ? "" : "slider-active"}`}
                                                    onClick={() => {
                                                        ImageChange("audio")
                                                    }}>

                                                    Audio</li>
                                                <li className={`${changeimg === "video" || changeimg === "audio" ? "" : "slider-active"}`}
                                                    onClick={() => {
                                                        ImageChange("image")
                                                    }}>
                                                    Images</li>
                                                <li className={`${changeimg === "image" || changeimg === "audio" ? "" : "slider-active"}`}
                                                    onClick={() => {
                                                        ImageChange("video")
                                                    }}>

                                                    Video</li>

                                            </ul>
                                        </div>
                                        <div className="col-lg-6 text-right">
                                            {/* <input
                                                type="text"
                                                className="form-control search"
                                                placeholder="Search Media..."
                                            /> */}
                                        </div>
                                    </div>
                                </h5>
                                <div className="card-body">
                                    <div className="row">
                                        {media.filter((img) => {
                                            return img.m_type?.includes(changeimg?.toLowerCase())
                                        }).map((med, i) => {
                                            return (
                                                <div className="col-2 mb-2" style={{ position: "relative" }}>
                                                    <div className="img_del"
                                                        onClick={() => {
                                                            delete_media(med._id)
                                                        }}>
                                                        <i className="fa fa-trash" />
                                                    </div>
                                                    <div className="img_edit">
                                                        <i
                                                            className="bx bx-edit me-1"
                                                            onClick={() => {
                                                                openTitleUp(med._id);
                                                            }}
                                                        ></i>
                                                    </div>
                                                    {med.m_type?.includes("image") ?
                                                        <img className="img-fluid" style={{ height: "100px", width: "100%" }} src={med.m_url} alt="media_images" />
                                                        :
                                                        <video width="100%" height="100" controls >
                                                            <source src={med.m_url} type={med.m_type} />
                                                        </video>

                                                    }

                                                </div>)
                                        })}

                                    </div>
                                </div>



                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
