import React, { useContext, useRef, useEffect, useState } from "react";
import Preloader from "../componant/Preloader";
import PageCon from "../context/Pages/PageCon";
import Alert from "../componant/Alert";
import JoditEditor from "jodit-react";
import parse from "html-react-parser";

function Page(props) {
  const mycontext = useContext(PageCon);
  const {
    page,

    getAllPage,
    createPage,
    deletePage,
    updateAllPage,
  } = mycontext;

  useEffect(() => {
    getAllPage("blog");
  }, []);

  /* jodit start */
  const editor = useRef(null);
  const [content, setContent] = useState("");
  console.log(content);
  /* jodit end */

  const [query, setquery] = useState("");

  const style = {
    background: "#00000080",
    display: "block",
  };
  const [addPageModel, setaddPageModel] = useState(false);
  const [preloader, setPreloader] = useState("");
  const [newPage, setnewPage] = useState({
    cat_name: "",
    cat_type: "blog",
    language: localStorage.getItem("lang")
  });
  const openData = () => {
    setaddPageModel(!addPageModel);
  };

  const onChange = (e) => {
    setnewPage({ ...newPage, [e.target.name]: e.target.value });
  };

  const handleClick = async (e) => {
    e.preventDefault();
    newPage.content = content;
    setPreloader("preShow");
    const response = await createPage(newPage);
    console.log(response);
    if (response.status === "success") {
      openData();
      props.showAlert(response.message, "success");
    } else {
      props.showAlert(response.message, "warning");
    }
    setPreloader("");
  };
  const deletePages = async (id) => {
    setPreloader("preShow");

    const response = await deletePage(id);
    console.log(id);
    if (response.status === "Success") {
      props.showAlert(response.message, "success");
    } else {
      props.showAlert(response.message, "warning");
    }
    setPreloader("");
  };

  /* update category */

  const changeStatus = async (id, status, type) => {
    setPreloader("preShow");
    let response = {};
    if (type === "status") {
      response = await updateAllPage(id, { status: status });
    } else {
      response = await updateAllPage(id, { status: status });
    }
    response.status === "Success"
      ? props.showAlert(`${type}  status is updated`, "success")
      : props.showAlert(response.message, "danger");
    setPreloader("");
  };

  /* edit section */
  const onedit = (e) => {
    setupdatepag({ ...updatepag, [e.target.name]: e.target.value });
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    setPreloader("preShow");
    const response = await updateAllPage(updatepag._id, updatepag);

    if (response.status === "Success") {
      openPageEdit({});
      props.showAlert(response.message, "success");
    } else {
      props.showAlert(response.message, "warning");
    }
    setPreloader("");
  };
  const [editPageModel, seteditPageModel] = useState(false);
  const [updatepag, setupdatepag] = useState({});
  const openPageEdit = (cat) => {
    console.log(cat);
    seteditPageModel(!editPageModel);
    setupdatepag(cat);
  };
  /* slider start */
  const [statusChange, setstatusChange] = useState(null);
  const clickBlock = (status) => {
    setstatusChange(status);
    console.log(statusChange);
    const st = page.filter((page) => {
      return page.status === statusChange;
    });
    console.log(st);
  };
  /* slider ends */

  return (
    <>
      <Preloader show={preloader} />

      {editPageModel && (
        <div className="modal reviewModal" style={style}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Update Page
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    openPageEdit({});
                  }}
                ></button>
              </div>
              <div className="modal-body">
                <form id="formAccountSettings">
                  <div className="row">
                    {/* category add section */}
                    <div className="col-md-6  ">
                      
                        <div className="form-group">
                          <label className="form-label">Enter Title</label>
                          <input
                            type="text"
                            className="form-control    m-0"
                            name="title"
                            id=""
                            aria-describedby="helpId"
                            placeholder="Enter Title"
                            defaultValue={updatepag.title}
                            onChange={onedit}
                          />
                        </div>
                        
                        <div className="form-group">
                          <label className="form-label"> Status</label>
                          <table width="100%">
                            <tr>
                              <td>
                                <div className="form-check">
                                  <input
                                    type="radio"
                                    className="form-check-input"
                                    name="status"
                                    value={false}
                                    defaultChecked={
                                      updatepag.status ? false : true
                                    }
                                    onChange={onedit}
                                  />
                                  <label className="form-label"> Draft</label>
                                </div>
                              </td>
                              <td>
                                <div className="form-check">
                                  <input
                                    type="radio"
                                    className="form-check-input"
                                    name="status"
                                    value={true}
                                    defaultChecked={
                                      updatepag.status ? true : false
                                    }
                                    onChange={onedit}
                                  />
                                  <label className="form-label"> Publish</label>
                                </div>
                              </td>
                            </tr>
                          </table>
                        </div>
                        <div className="form-group">
                          <label className="form-label">Enter  Meta Title</label>
                          <input
                            type="text"
                            className="form-control    m-0"
                            name="meta_title"
                            id=""
                            aria-describedby="helpId"
                            placeholder="Enter meta title"
                            defaultValue={updatepag.meta_title}
                            onChange={onedit}
                          />
                        </div>
                    </div>

                    <div className="col-md-6">
                    <div className="form-group">
                          <label className="form-label">Enter Type</label>
                          <input
                            type="text"
                            className="form-control    m-0"
                            name="type"
                            id=""
                            aria-describedby="helpId"
                            placeholder="Enter Type"
                            defaultValue={updatepag.type}
                            onChange={onedit}
                          />
                        </div>
                       
                        <div className="form-group">
                          <label className="form-label">Enter  Meta Discription</label>
                          <input
                            type="text"
                            className="form-control    m-0"
                            name="meta_des"
                            id=""
                            aria-describedby="helpId"
                            placeholder="Enter Discription"
                            defaultValue={updatepag.meta_des}
                            onChange={onedit}
                          />
                        </div>
                        <div className="form-group">
                          <label className="form-label">Enter  Meta Keywords</label>
                          <input
                            type="text"
                            className="form-control    m-0"
                            name="meta_des"
                            id=""
                            aria-describedby="helpId"
                            placeholder="Enter Meta Keywords"
                            defaultValue={updatepag.meta_keywords}
                            onChange={onedit}
                          />
                        </div>
                    </div>

                    <div className="col-12">
                      <div className="form-group">
                        <label className="form-label">Enter Content</label>
                        <JoditEditor
                          ref={editor}
                          value={content}
                          onChange={(newContent) => setContent(newContent)}
                        />
                      </div>
                    </div>

                    {/* category add section end */}
                  </div>
                  <div className="mt-3">
                    <button
                      type="reset"
                      className="btn btn-outline-primary me-2 my-btn"
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="btn orange-btn btn-primary me-2"
                      onClick={handleUpdate}
                    >
                      Update
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}

      {addPageModel && (
        <div className="modal reviewModal" style={style}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Add New Page
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={openData}
                ></button>
              </div>
              <div className="modal-body">
                <form
                  id="formAccountSettings"
                  method="POST"
                  onSubmit={handleClick}
                >
                  <form method="post">
                    <div className="row">
                      {/* category add section */}

                      <div className="col-md-12  col-12">
                        <div className="form-group">
                          <label className="form-label">Enter Title</label>
                          <input
                            type="text"
                            className="form-control    m-0"
                            name="title"
                            id=""
                            aria-describedby="helpId"
                            placeholder="Enter Title"
                            onChange={onChange}
                          />
                        </div>
                        <div className="form-group">
                          <label className="form-label">Enter Content</label>
                          <JoditEditor
                            ref={editor}
                            value={content}
                            onChange={(newContent) => setContent(newContent)}
                          />
                        </div>
                      </div>
                      <div className="col-md-6 col-6">
                        <div className="form-group">
                          <label className="form-label">Enter Meta Title</label>
                          <input
                            type="text"
                            className="form-control    m-0"
                            name="meta_title"
                            id=""
                            aria-describedby="helpId"
                            placeholder="Enter Meta Title"
                            onChange={onChange}
                          />
                        </div>
                        <div className="form-group">
                          <label className="form-label">
                            Enter Meta Others
                          </label>
                          <input
                            type="text"
                            className="form-control    m-0"
                            name="meta_others"
                            id=""
                            aria-describedby="helpId"
                            placeholder="Enter Meta Others"
                            onChange={onChange}
                          />
                        </div>
                        <div className="form-group">
                          <label className="form-label">
                            Enter Meta Discription
                          </label>
                          <textarea
                            type="textarea"
                            className="form-control    m-0"
                            name="meta_des"
                            id=""
                            aria-describedby="helpId"
                            placeholder="Enter Meta Discription"
                            onChange={onChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-6 col-6">
                        <div className="form-group">
                          <label className="form-label">Enter Type</label>
                          <input
                            type="text"
                            className="form-control    m-0"
                            name="type"
                            id=""
                            aria-describedby="helpId"
                            placeholder="Enter Type"
                            onChange={onChange}
                          />
                        </div>
                        <div className="form-group">
                          <label className="form-label">
                            Enter Meta Keywords
                          </label>
                          <input
                            type="text"
                            className="form-control    m-0"
                            name="meta_keywords"
                            id=""
                            aria-describedby="helpId"
                            placeholder="Enter Meta Keywords"
                            onChange={onChange}
                          />
                        </div>
                        <div className="form-group">
                          <label className="form-label"> Status</label>
                          <table width="100%">
                            <tr>
                              <td>
                                <div className="form-check">
                                  <input
                                    type="radio"
                                    className="form-check-input"
                                    name="status"
                                    value={false}
                                    onChange={onChange}
                                  />
                                  <label className="form-label"> Draft</label>
                                </div>
                              </td>
                              <td>
                                <div className="form-check">
                                  <input
                                    type="radio"
                                    className="form-check-input"
                                    name="status"
                                    value={true}
                                    onChange={onChange}
                                  />
                                  <label className="form-label"> Publish</label>
                                </div>
                              </td>
                            </tr>
                          </table>
                        </div>
                      </div>

                      {/* category add section end */}
                    </div>
                  </form>
                  <div className="mt-3">
                    <button
                      type="reset"
                      className="btn btn-outline-primary me-2 my-btn"
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="btn orange-btn btn-primary me-2"
                      onClick={handleClick}
                    >
                      Add
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="content-wrapper">
        <div className="container-fluid flex-grow-1 container-p-y">
          <div className="fixed-button">
            <div
              className={`btn btn-sm fixed_button btn-outline-primary ${
                addPageModel ? "d-none" : "d-block"
              }`}
              onClick={openData}
            >
              + Add Page
            </div>
          </div>
          <div className="row">
            <h4 className="card-header c_heading">
              <span className="headingcontent">My Pages ({page.length})</span>
            </h4>

            <div className="col-lg-12 mb-4 ">
              <div className="card">
                <h5 className="card-header">
                  <div className="row">
                    <div className="col-lg-6 font-bold">
                    <ul className="slider_button slider-white plain-orange">
                        <li
                          className={`${statusChange === null ? "slider-active" : ""}`}
                          onClick={() => {
                            clickBlock(null);
                          }}
                        >All</li>
                        <li
                          className={`${(statusChange !== null && statusChange === false) ? "slider-active" : ""}`}
                          onClick={() => {
                            clickBlock(false);
                          }}
                        >
                          Block
                        </li>
                        <li
                          className={`${(statusChange !== null && statusChange === true) ? "slider-active" : ""}`}
                          onClick={() => {
                            clickBlock(true);
                          }}
                        >
                          Unblock
                        </li>
                      </ul>
                    </div>
                    <div className="col-lg-6 text-right">
                      <input
                        type="text"
                        className="form-control search"
                        placeholder="Search Pages..."
                        onChange={(e) => {
                          setquery(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </h5>

                <div className="table-responsive text-nowrap mb-1">
                  <table className="table table-hover">
                    <thead className="order_history_header">
                      <tr>
                        <th> Title</th>
                        <th>Type</th>
                        {/*  <th>Content</th> */}
                        <th>Status</th>
                        <th>Created Date</th>
                        <th>Created By</th>

                        <th className="action">Action</th>
                      </tr>
                    </thead>
                    <tbody className="table-border-bottom-0 order_history_body">
                      {page
                        .filter((page) => {
                          return (
                            (statusChange === null ||
                              page.status === statusChange) &&
                            page.title
                              ?.toLowerCase()
                              .includes(query?.toLowerCase())
                          );
                        })
                        .map((page, index) => {
                            const date = page.create_date.split("T")
                          return (
                            <tr key={index}>
                              <td>{page.title}</td>
                              <td>{page.type}</td>
                              {/*  <td>{parse(page.content)}</td> */}
                              <td>
                                {page.status ? (
                                  <i
                                    className="bx bx-check"
                                    onClick={() => {
                                      changeStatus(page._id, false, "page");
                                    }}
                                  ></i>
                                ) : (
                                  <i
                                    className="bx bx-block"
                                    onClick={() => {
                                      changeStatus(page._id, true, "page");
                                    }}
                                  ></i>
                                )}
                              </td>
                              <td>{date[0]}</td>
                              <td>{page.created_by}</td>

                              <td className="action">
                                <div className="dropdown ">
                                  <i
                                    className="bx bx-trash me-1"
                                    onClick={() => {
                                      deletePages(page._id);
                                    }}
                                  ></i>

                                  <i
                                    className="bx bx-edit me-1"
                                    onClick={() => {
                                      openPageEdit(page);
                                    }}
                                  ></i>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Page;
