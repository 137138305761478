import React, { useContext, useEffect, useRef, useState } from "react";
import Preloader from "../componant/Preloader";
import StoryCon from "../context/Stories/StoryCon";
import categoryCon from "../context/Category/CategoryContext";
import ChooseMedia from "../componant/Media/ChooseMedia";
import MediaCon from "../context/Media/MediaCon";
import JoditEditor from "jodit-react";
import parse from "html-react-parser";
import { Link } from "react-router-dom";
import UrduKeyboard from "../componant/Urdu/UrduKeyboard";
import HindiKeyboard from "../componant/Hindi/HindiKeyboard";

export const Stories = (props) => {
  const [preloader, setPreloader] = useState("");

  const myContext = useContext(StoryCon);
  const { story, getAllStory, deleteStory, createStory, updateAllstory } =
    myContext;

  const catContext = useContext(categoryCon);
  const { category, getAllCatagory } = catContext;

  const { writer_info, getAllWriter_Info } = catContext;

  const [query, setquery] = useState("");
  useEffect(() => {
    getAllStory();
    getAllWriter_Info("");
    getAllCatagory("blog");
  }, []);
  const style = {
    background: "#00000080",
    display: "block",
  };
  const [newStory, setnewStory] = useState({
    title: "",
    writer_name: "",
    url: "",
    short_des: "",
    keywords: "",
    commentable: true,
    rating: "",
    main_des: "",
    multimedia: "",
    status: true,
    language: localStorage.getItem("lang"),
  });
  const [addStory_Model, setAddStory_Model] = useState(false);

  const openData = () => {
    setAddStory_Model(!addStory_Model);
    newStory.image = "";
  };

  /* jodit start */
  const editor = useRef(null);
  const [content, setContent] = useState("");

  /* jodit end */

  const delete_Story = async (id) => {
    setPreloader("preShow");
    const response = await deleteStory(id);
    console.log(id);
    if (response.status === "Success") {
      props.showAlert(response.message, "success");
    } else {
      props.showAlert(response.message, "warning");
    }
    setPreloader("");
  };
  const handleClick = async (e) => {
    e.preventDefault();

    setPreloader("preShow");
    if (
      localStorage.getItem("lang") === "2" ||
      localStorage.getItem("lang") === "1"
    ) {
      newStory.title = urdudata.title;
      newStory.short_des = urdudata.short_des;
    }
    const response = await createStory(newStory);
    console.log(newStory);
    if (response.status === "success") {
      openData();
      props.showAlert(response.message, "success");
    } else {
      props.showAlert(response.message, "warning");
    }
    setPreloader("");
  };

  const onChange = (e) => {
    setnewStory({ ...newStory, [e.target.name]: e.target.value });
    console.log(
      "short des = " + e.target.name + " -- value = " + e.target.value
    );
    console.log(newStory);
  };

  /*   status update */
  const changeStatus = async (id, status, type) => {
    setPreloader("preShow");
    let response = {};
    if (type === "Commentable") {
      response = await updateAllstory(id, { commentable: status });
    } else {
      response = await updateAllstory(id, { status: status });
    }
    response.status === "Success"
      ? props.showAlert(`${type}  status is updated`, "success")
      : props.showAlert(response.message, "danger");
    setPreloader("");
  };

  /* edit section */
  const onedit = (e) => {
    setupdatestory({ ...updatestory, [e.target.name]: e.target.value });
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    setPreloader("preShow");
    if (
      localStorage.getItem("lang") === "2" ||
      localStorage.getItem("lang") === "1"
    ) {
      updatestory.title = urdudata.title;
    }
    const response = await updateAllstory(updatestory._id, updatestory);

    if (response.status === "Success") {
      openStoryEdit({});
      props.showAlert(response.message, "success");
    } else {
      props.showAlert(response.message, "warning");
    }
    setPreloader("");
  };
  const [editstorymodel, seteditstorymodel] = useState(false);
  const [updatestory, setupdatestory] = useState({});
  const openStoryEdit = (cat) => {
    console.log(cat);
    seteditstorymodel(!editstorymodel);
    setupdatestory(cat);
  };
  const [mediaModel, setmediaModel] = useState(false);
  const [editmediaModel, seteditmediaModel] = useState(false);
  const myConte = useContext(MediaCon);
  const { media, getAllMedia } = myConte;
  const openMedia = async () => {
    setmediaModel(!mediaModel);
    if (!mediaModel) {
      setPreloader("preShow");

      setPreloader("");
    }
  };

  const selectImage = (url) => {
    openMedia();
    if (editstorymodel) {
      setupdatestory({ ...updatestory, image: url });
    } else {
      setnewStory({ ...newStory, image: url });
    }
  };

  /* slider start */
  const [statusChange, setstatusChange] = useState(null);
  const clickBlock = (status) => {
    setstatusChange(status);
    console.log(statusChange);
    const st = story.filter((story) => {
      return story.status === statusChange;
    });
    console.log(st);
  };

  const [urdudata, seturdudata] = useState({ title: "", short_des: "" });
  const inputUrdu = (key, value) => {
    urdudata[key] = value;
    console.log("key" + key + " value-" + value);

    console.log(newStory);
  };

  console.log("new story image data = " + newStory.image);

  return (
    <>
      <Preloader show={preloader} />

      {editstorymodel && (
        <div className="modal reviewModal" style={style}>
          <div className="modal-dialog" style={{ width: "100%" }}>
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Update Story
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    openStoryEdit({});
                  }}
                ></button>
              </div>
              <div className="modal-body">
                <form
                  id="formAccountSettings"
                  method="POST"
                  onSubmit={handleClick}
                >
                  <div className="row">
                    {/* category add section */}
                    <div className="col-md-12  my-1">
                      <div className="form-group">
                        <label className="form-label">Update Title</label>
                        {localStorage.getItem("lang") === "0" ? (
                          <input
                            type="text"
                            className={`form-control mb-2  m-0`}
                            name="title"
                            id=""
                            aria-describedby="helpId"
                            placeholder="Update  title"
                            defaultValue={updatestory.title}
                            onChange={onedit}
                          />
                        ) : localStorage.getItem("lang") === "1" ? (
                          <HindiKeyboard
                            inputvalue={updatestory.title}
                            keyinput="title"
                            onchange={inputUrdu}
                          />
                        ) : localStorage.getItem("lang") === "2" ? (
                          <UrduKeyboard
                            inputvalue={updatestory.title}
                            inputUrdu={inputUrdu}
                            inputkey="title"
                          />
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="form-group">
                        <label className="form-label">Choose Category</label>
                        <select
                          className="form-control mb-2"
                          name="cat_id"
                          onChange={onChange}
                        >
                          <option value={0}>Choose Category</option>
                          {category.map((cat, i) => {
                            return (
                              <option value={cat._id}>{cat.cat_name}</option>
                            );
                          })}
                        </select>
                      </div>
                      <div className="form-group">
                        <label className="form-label">
                          Update Short Discription
                        </label>
                        {localStorage.getItem("lang") === "0" ? (
                          <textarea
                            type="textarea"
                            className={`form-control mb-2  m-0`}
                            name="short_des"
                            id=""
                            aria-describedby="helpId"
                            placeholder="Update Short Discription"
                            defaultValue={updatestory.short_des}
                            onChange={onedit}
                          />
                        ) : localStorage.getItem("lang") === "1" ? (
                          <HindiKeyboard
                            inputvalue={updatestory.short_des}
                            keyinput="short_des"
                            onchange={inputUrdu}
                          />
                        ) : localStorage.getItem("lang") === "2" ? (
                          <UrduKeyboard
                            inputvalue={updatestory.short_des}
                            inputUrdu={inputUrdu}
                            inputkey="short_des"
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-group ">
                        <label className="form-label"> Commentable</label>
                        <table width="100%" className="form-radio-table">
                          <tr>
                            <td>
                              <div className="form-check">
                                <input
                                  type="radio"
                                  className="form-check-input"
                                  name="commentable"
                                  value={false}
                                  defaultChecked={
                                    updatestory.commentable ? false : true
                                  }
                                  onChange={onedit}
                                />
                                <label className="form-label"> NO</label>
                              </div>
                            </td>
                            <td>
                              <div className="form-check">
                                <input
                                  type="radio"
                                  className="form-check-input"
                                  name="commentable"
                                  value={true}
                                  defaultChecked={
                                    updatestory.commentable ? true : false
                                  }
                                  onChange={onedit}
                                />
                                <label className="form-label"> YES</label>
                              </div>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="form-group mt-2">
                        <label className="form-label">Choose Writer</label>
                        <select
                          className="form-control"
                          name="writer_name"
                          onChange={onChange}
                        >
                          <option value={0}>Choose writers</option>
                          {writer_info.map((writers, i) => {
                            return (
                              <option value={writers._id}>
                                {writers.name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      <div className="form-group mt-2">
                        <label className="form-label">Update Status</label>
                        <table width="100%" className="form-radio-table">
                          <tr>
                            <td>
                              <div className="form-check">
                                <input
                                  type="radio"
                                  className="form-check-input"
                                  name="status"
                                  value={false}
                                  defaultChecked={
                                    updatestory.status ? false : true
                                  }
                                  onChange={onedit}
                                />
                                <label className="form-label"> Draft</label>
                              </div>
                            </td>
                            <td>
                              <div className="form-check">
                                <input
                                  type="radio"
                                  className="form-check-input"
                                  name="status"
                                  value={true}
                                  defaultChecked={
                                    updatestory.status ? true : false
                                  }
                                  onChange={onedit}
                                />
                                <label className="form-label"> Publish</label>
                              </div>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="form-group mt-2">
                        <label className="form-label">Update Multimedia </label>
                        <select
                          className="form-control"
                          name="multimedia"
                          onChange={onedit}
                        >
                          <option>Audio</option>
                          <option>vedio</option>
                          <option>Image</option>
                        </select>
                      </div>

                      <div className="mt-2" onClick={openMedia}>
                        {updatestory.image?.includes("mp4") ? (
                          <video
                            width="300"
                            height="200"
                            autoPlay={true}
                            controls={true}
                          >
                            <source src={updatestory.image} type="" />
                          </video>
                        ) : (
                          <img
                            src={
                              updatestory.image === undefined ||
                              updatestory.image === ""
                                ? "assets/img/illustrations/upload.png"
                                : updatestory.image
                            }
                            className="img-fluid"
                            width={100}
                            alt={updatestory.image}
                          />
                        )}
                      </div>
                      {/* 
                      <div className="">
                        <img
                          className="img-fluid"
                          src={newStory.image}
                          alt="selected_image"
                        />
                      </div> */}

                      <div className="form-group mt-2">
                        <label className="form-label">Update Keywords</label>
                        <input
                          type="text"
                          className="form-control    m-0"
                          name="keywords"
                          id=""
                          aria-describedby="helpId"
                          placeholder="Update Keywords"
                          defaultValue={updatestory.keywords}
                          onChange={onedit}
                        />
                      </div>
                    </div>

                    <div className="col-6">
                      <div className="form-group mt-2">
                        <label className="form-label">Update URL</label>
                        <input
                          type="text"
                          className="form-control    m-0"
                          name="url"
                          id=""
                          aria-describedby="helpId"
                          placeholder="Update URL "
                          defaultValue={updatestory.url}
                          onChange={onedit}
                        />
                      </div>

                      <div className="form-group">
                        <label className="form-label mt-2"> Rating</label>
                        <table width="100%">
                          <tr>
                            <td>
                              <div className="form-check">
                                <input
                                  type="radio"
                                  className="form-check-input"
                                  name="rating"
                                  value={false}
                                  defaultChecked={
                                    updatestory.status ? false : true
                                  }
                                  onChange={onedit}
                                />
                                <label className="form-label"> Draft</label>
                              </div>
                            </td>
                            <td>
                              <div className="form-check">
                                <input
                                  type="radio"
                                  className="form-check-input"
                                  name="rating"
                                  value={true}
                                  defaultChecked={
                                    updatestory.status ? true : false
                                  }
                                  onChange={onedit}
                                />
                                <label className="form-label"> Publish</label>
                              </div>
                            </td>
                          </tr>
                        </table>
                      </div>

                      <div className="form-group mt-2">
                        <label className="form-label">Update User Id</label>
                        <select
                          className="form-control"
                          name="u_id"
                          onChange={onChange}
                        >
                          <option value={0}>Choose writers</option>
                          {writer_info.map((writers, i) => {
                            return (
                              <option value={writers._id}>
                                {writers.name}
                              </option>
                            );
                          })}
                        </select>
                      </div>

                      <div className="form-group mt-2">
                        <label className="form-label">Update Order</label>
                        <input
                          type="text"
                          className="form-control    m-0"
                          name="order"
                          id=""
                          aria-describedby="helpId"
                          placeholder="Order"
                          onChange={onChange}
                        />
                      </div>
                      <div className="form-group mt-2">
                        <label className="form-label"> Is Admin Post</label>
                        <table width="100%">
                          <tr>
                            <td>
                              <div className="form-check">
                                <input
                                  type="radio"
                                  className="form-check-input"
                                  name="adminpost"
                                  value={false}
                                  defaultChecked={
                                    updatestory.status ? false : true
                                  }
                                  onChange={onedit}
                                />
                                <label className="form-label"> No</label>
                              </div>
                            </td>
                            <td>
                              <div className="form-check">
                                <input
                                  type="radio"
                                  className="form-check-input"
                                  name="adminpost"
                                  value={true}
                                  defaultChecked={
                                    updatestory.status ? true : false
                                  }
                                  onChange={onedit}
                                />
                                <label className="form-label"> Yes</label>
                              </div>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>

                  {/* category add section end */}

                  <div className="mt-3">
                    <button
                      type="reset"
                      className="btn btn-outline-primary me-2 my-btn"
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="btn orange-btn btn-primary me-2"
                      onClick={handleUpdate}
                    >
                      Update
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
      {addStory_Model && (
        <div className="modal reviewModal" style={style}>
          <div className="modal-dialog" style={{ width: "80%" }}>
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Add New Story
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={openData}
                ></button>
              </div>
              <div className="modal-body">
                <form
                  id="formAccountSettings"
                  method="POST"
                  onSubmit={handleClick}
                >
                  <div className="row">
                    {/* category add section */}
                    <div className="col-md-6 ">
                      {/*   <form method="post"> */}

                      <div className="form-group">
                        <label className="form-label">Enter Title</label>
                        {localStorage.getItem("lang") === "0" ? (
                          <input
                            type="text"
                            className={`form-control mb-2  m-0`}
                            name="title"
                            id=""
                            aria-describedby="helpId"
                            placeholder="Enter Name"
                            onChange={onChange}
                          />
                        ) : localStorage.getItem("lang") === "1" ? (
                          <HindiKeyboard
                            keyinput="title"
                            onchange={inputUrdu}
                          />
                        ) : localStorage.getItem("lang") === "2" ? (
                          <UrduKeyboard
                            inputUrdu={inputUrdu}
                            inputkey="title"
                            refName="titleref"
                          />
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="form-group">
                        <label className="form-label">Choose Category</label>
                        <select
                          className="form-control mb-2"
                          name="cat_id"
                          onChange={onChange}
                        >
                          <option value={0}>Choose Category</option>
                          {category.map((cat, i) => {
                            return (
                              <option value={cat._id}>{cat.cat_name}</option>
                            );
                          })}
                        </select>
                      </div>

                      <div className="form-group">
                        <label className="form-label">Enter URL</label>
                        <input
                          type="text"
                          className="form-control mb-2   m-0"
                          name="url"
                          id=""
                          aria-describedby="helpId"
                          placeholder="Enter URL "
                          onChange={onChange}
                        />
                      </div>

                      <div className="form-group">
                        <label className="form-label">Enter Keywords</label>
                        <textarea
                          type="textarea"
                          className="form-control  mb-2  m-0"
                          name="keywords"
                          id=""
                          aria-describedby="helpId"
                          placeholder="Enter Keywords"
                          onChange={onChange}
                        />
                      </div>

                      <div className="form-group">
                        <label className="form-label">Choose Writer</label>
                        <select
                          className="form-control mb-2"
                          name="writer_name"
                          onChange={onChange}
                        >
                          <option value={0}>Choose writers</option>
                          {writer_info.map((writers, i) => {
                            return (
                              <option value={writers._id}>
                                {writers.name}
                              </option>
                            );
                          })}
                        </select>
                      </div>

                      <div className="form-group">
                        <label className="form-label">
                          Enter Short Discription
                        </label>
                        {localStorage.getItem("lang") === "0" ? (
                          <textarea
                            type="textarea"
                            className={`form-control mb-2 m-0`}
                            name="short_des"
                            id=""
                            aria-describedby="helpId"
                            placeholder="Enter Short discription"
                            onChange={onChange}
                          />
                        ) : localStorage.getItem("lang") === "1" ? (
                          <HindiKeyboard
                            keyinput="short_des"
                            onchange={inputUrdu}
                          />
                        ) : localStorage.getItem("lang") === "2" ? (
                          <UrduKeyboard
                            inputUrdu={inputUrdu}
                            inputkey="short_des"
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="col-md-6 my-1">
                      <div className="form-group mt-2">
                        <label className="form-label"> Commentable</label>
                        <table width="100%" className="form-radio-table">
                          <tr>
                            <td>
                              <div className="form-check">
                                <input
                                  type="radio"
                                  className="form-check-input"
                                  name="commentable"
                                  value={0}
                                  onChange={onChange}
                                />
                                <label className="form-label"> NO</label>
                              </div>
                            </td>
                            <td>
                              <div className="form-check">
                                <input
                                  type="radio"
                                  className="form-check-input"
                                  name="commentable"
                                  value={1}
                                  onChange={onChange}
                                />
                                <label className="form-label"> YES</label>
                              </div>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="form-group mt-2">
                        <label className="form-label"> Rating</label>
                        <table width="100%" className="form-radio-table">
                          <tr>
                            <td>
                              <div className="form-check">
                                <input
                                  type="radio"
                                  className="form-check-input"
                                  name="rating"
                                  value={0}
                                  onChange={onChange}
                                />
                                <label className="form-label"> Draft</label>
                              </div>
                            </td>
                            <td>
                              <div className="form-check">
                                <input
                                  type="radio"
                                  className="form-check-input"
                                  name="rating"
                                  value={1}
                                  onChange={onChange}
                                />
                                <label className="form-label"> Publish</label>
                              </div>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="form-group mt-2">
                        <label className="form-label"> Status</label>
                        <table width="100%" className="form-radio-table">
                          <tr>
                            <td>
                              <div className="form-check">
                                <input
                                  type="radio"
                                  className="form-check-input"
                                  name="status"
                                  value={0}
                                  onChange={onChange}
                                />
                                <label className="form-label"> Draft</label>
                              </div>
                            </td>
                            <td>
                              <div className="form-check">
                                <input
                                  type="radio"
                                  className="form-check-input"
                                  name="status"
                                  value={1}
                                  onChange={onChange}
                                />
                                <label className="form-label"> Publish</label>
                              </div>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="form-group mt-2">
                        <label className="form-label">Multimedia </label>
                        <select
                          className="form-control"
                          name="multimedia"
                          onChange={onChange}
                        >
                          <option>Audio</option>
                          <option>vedio</option>
                          <option>Image</option>
                        </select>

                        <label className="form-label mt-2">Choose Image</label>

                        <div className="" onClick={openMedia}>
                          {newStory.image?.includes("mp4") ? (
                            <video
                              width="300"
                              height="200"
                              autoPlay={true}
                              controls={true}
                            >
                              <source src={newStory.image} type="" />
                            </video>
                          ) : (
                            <img
                              src={
                                newStory.image === undefined ||
                                newStory.image === ""
                                  ? "assets/img/illustrations/upload.png"
                                  : newStory.image
                              }
                              className="img-fluid"
                              width={100}
                            />
                          )}

                          <span className="m-3">Click to upload</span>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12">
                      {/* jodi editor for main description */}
                      <div className="form-group">
                        <label className="form-label">
                          Enter Main Discription
                        </label>
                        <JoditEditor
                          ref={editor}
                          value={content}
                          placeholder="Enter Main Discription"
                          onChange={(newContent) => setContent(newContent)}
                        />
                      </div>
                    </div>
                  </div>

                  {/* category add section end */}

                  <div className="mt-3">
                    <button
                      type="reset"
                      className="btn btn-outline-primary me-2 my-btn"
                      onClick={openData}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="btn orange-btn btn-primary me-2"
                      onClick={handleClick}
                    >
                      Add
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
      {mediaModel && (
        <ChooseMedia
          open={openMedia}
          selectImage={selectImage}
          showAlert={props.showAlert}
        />
      )}

      <div className="content-wrapper">
        <div className="container-fluid flex-grow-1 container-p-y">
          <div className="fixed-button">
            <div
              className={`btn btn-sm fixed_button btn-outline-primary ${
                addStory_Model ? "d-none" : "d-block"
              }`}
              onClick={openData}
            >
              + Add Story
            </div>
          </div>
          <div className="row">
            <h4 className="card-header c_heading">
              <span className="headingcontent">
                My Stories ({story.length})
              </span>
            </h4>

            <div className="col-lg-12 mb-4 ">
              <div class="card">
                <h5 class="card-header">
                  <div className="row">
                    <div className="col-lg-6 font-bold">
                      <ul className="slider_button slider-white plain-orange">
                        <li
                          className={`${
                            statusChange === null ? "slider-active" : ""
                          }`}
                          onClick={() => {
                            clickBlock(null);
                          }}
                        >
                          All
                        </li>
                        <li
                          className={`${
                            statusChange !== null && statusChange === false
                              ? "slider-active"
                              : ""
                          }`}
                          onClick={() => {
                            clickBlock(false);
                          }}
                        >
                          Block
                        </li>
                        <li
                          className={`${
                            statusChange !== null && statusChange === true
                              ? "slider-active"
                              : ""
                          }`}
                          onClick={() => {
                            clickBlock(true);
                          }}
                        >
                          Unblock
                        </li>
                      </ul>
                    </div>
                    <div className="col-lg-6 text-right">
                      <input
                        type="text"
                        className="form-control search"
                        placeholder="Search Stories..."
                        onChange={(e) => {
                          setquery(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </h5>

                <div class="table-responsive text-nowrap mb-1">
                  <table class="table table-hover">
                    <thead className="order_history_header">
                      <tr>
                        {/* <th>Image</th> */}
                        <th>Title</th>
                        <th>Section</th>
                        <th>Writer Name</th>
                        <th>Url</th>
                        <th>Views</th>
                        <th>Commentable</th>
                        <th>Status</th>
                        <th className="action">Action</th>
                      </tr>
                    </thead>
                    <tbody class="table-border-bottom-0 order_history_body">
                      {story
                        .filter((Story) => {
                          return (
                            statusChange === null ||
                            (Story.status === statusChange &&
                              (Story.title
                                ?.toLowerCase()
                                .includes(query?.toLowerCase()) ||
                                Story.keywords
                                  ?.toLowerCase()
                                  .includes(query?.toLowerCase())))
                          );
                        })
                        .map((Story, index) => {
                          return (
                            <tr key={index}>
                              {/*  <td>
                                                            {(Story.image.includes('jpg') || Story.image.includes('png')) ?
                                                                <img className="img-fluid" style={{ height: "100px", width: "100px" }} src={Story.image} alt="story_media" />
                                                                :
                                                                (<video width="100" height="100" controls={true}>
                                                                    <source src={Story.image} type="" />
                                                                </video>)
                                                            }

                                                        </td> */}
                              <td>{Story.title}</td>
                              <td>{Story.section}</td>
                              {/*  <td>{Story.cat_id === null ? "..." : Story.cat_id.cat_name}</td> */}
                              <td>
                                {Story.writer_name === null
                                  ? "..."
                                  : Story.writer_name.name}
                              </td>
                              <td>{Story.url}</td>
                              <th>{Story.views}</th>
                              {/* <td>{Story.short_des}</td> */}

                              {/* <td>{Story.keywords}</td> */}
                              <td>
                                {Story.commentable ? (
                                  <i
                                    className="bx bx-check"
                                    onClick={() => {
                                      changeStatus(
                                        Story._id,
                                        false,
                                        "Commentable"
                                      );
                                    }}
                                  ></i>
                                ) : (
                                  <i
                                    className="bx bx-block"
                                    onClick={() => {
                                      changeStatus(
                                        Story._id,
                                        true,
                                        "Commentable"
                                      );
                                    }}
                                  ></i>
                                )}
                              </td>
                              {/*    <td>{Story.rating}</td>
                                                        <td>{Story.multimedia}</td> */}
                              <td>
                                {Story.status ? (
                                  <i
                                    className="bx bx-check"
                                    onClick={() => {
                                      changeStatus(Story._id, false, "Story ");
                                    }}
                                  ></i>
                                ) : (
                                  <i
                                    className="bx bx-block"
                                    onClick={() => {
                                      changeStatus(Story._id, true, "Story");
                                    }}
                                  ></i>
                                )}
                              </td>
                              <td className="action">
                                <div className="dropdown ">
                                  <i
                                    className="bx bx-trash me-1"
                                    onClick={() => {
                                      delete_Story(Story._id);
                                    }}
                                  ></i>

                                  <i
                                    className="bx bx-edit me-1"
                                    onClick={() => {
                                      openStoryEdit(Story);
                                    }}
                                  ></i>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Stories;
