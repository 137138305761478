import React, { useState } from "react";
import tagCon from "./TagContext";
const TagEState = (props) => {
  const host = process.env.REACT_APP_URL;
  console.log(host);
  const token = localStorage.getItem("token");
  console.log(token);
  const [tag, setTag] = useState([]);

  const getAllTag = async (id) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "auth-token": token
      },
    };
    const response = await fetch(
      `${host}/api/tag/gettag`,
      requestOptions
    );
    const datares = await response.json();

    console.log(datares);
    setTag(datares.data);
  };
  const deleteTag = async (id) => {
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "auth-token": token
      },
    };
    const response = await fetch(
      `${host}/api/tag/deleteTag/${id}`,
      requestOptions
    );
    const datares = await response.json();

    console.log(datares);
    /* setCategory(datares.data); */
    if (datares.status === "Success") {
      const updateData = tag.filter((tag) => {
        return tag._id !== id

      })
      setTag(updateData)

    }
    return(datares)
  };

  const createTag = async (body) => {
    try {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "auth-token": token
        }, body: JSON.stringify(body)
      };
      const response = await fetch(
        `${host}/api/tag/createtag`,
        requestOptions
      );
      const dataresp = await response.json();
      
      if (dataresp.status === "Success") {
        setTag(tag.concat(dataresp.data))
       
      }
     return dataresp

    }
    catch (e) {

    }
  }
  return (
    <tagCon.Provider
      value={{
        tag,
        getAllTag,
        deleteTag,
        createTag
      }}

    >
      {props.children}
    </tagCon.Provider>
  );
}
export default TagEState;