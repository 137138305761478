import React, { useState } from "react";
import directoryCon from "./DirectoryContext";
/* require("dotenv").config(); */
const DirectoryState = (props) => {
  const host = process.env.REACT_APP_URL;
  console.log(host);
  const token =
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7ImlkIjoiNjNkZjg4YWFhNDllYjE2NzFjYTdkZGNhIn0sImlhdCI6MTY3NTU5NTEzN30.xGYO_zIQPGR5YvcYhQFDlInCydDomn6Lrsqv6bZmrMQ";
  console.log(token);

  const [directory, setDirectory] = useState([]);
  const [totalDire, setTotalDire] = useState(0);

  const createDirectory = async (body) => {
    try {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "auth-token": token,
        },
        body: JSON.stringify(body),
      };
      const response = await fetch(
        `${host}/api/directory/createdirectory`,
        requestOptions
      );
      const dataresp = await response.json();
      console.log(dataresp);
      if (dataresp.status === "success") {
        setDirectory(directory.concat(dataresp.data));
      }
      return dataresp;
    } catch (e) {}
  };
  const deleteDirectory = async (id) => {
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "auth-token": token,
      },
    };
    const response = await fetch(
      `${host}/api/directory/deletedirectory/${id}`,
      requestOptions
    );
    const datares = await response.json();

    console.log(datares);
    setDirectory(datares.data);
    if (datares.status === "Success") {
      const updateData = directory.filter((cat) => {
        return cat._id !== id;
      });
      setDirectory(updateData);
    }
    return datares;
  };
  const getAllDirectory = async (page = 0, limit = 10, pagination = "all") => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "auth-token": token,
      },
    };
    const response = await fetch(
      `${host}/api/directory/getDirectory/?page=${page}
      &limit=${limit}&pagination=${pagination}`,
      requestOptions
    );
    const datares = await response.json();

    console.log(datares);
    setDirectory(datares.data);
    setTotalDire(datares.total);
  };
  const updateDirectory = async (id, body) => {
    try {
      const requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "auth-token": token,
        },
        body: JSON.stringify(body),
      };
      const response = await fetch(
        `${host}/api/directory/updatedirectory/${id}`,
        requestOptions
      );
      const dataresp = await response.json();
      console.log(dataresp);
      if (dataresp.status === "Success") {
        for (let i = 0; i < directory.length; i++) {
          let element = directory[i];
          if (element._id.toString() === id) {
            directory[i] = dataresp.data;
          }
        }
        setDirectory(directory);
      }
      return dataresp;
    } catch (e) {}
  };

  return (
    <directoryCon.Provider
      value={{
        directory,
        totalDire,
        getAllDirectory,
        deleteDirectory,
        createDirectory,
        updateDirectory,
      }}
    >
      {props.children}
    </directoryCon.Provider>
  );
};
export default DirectoryState;
