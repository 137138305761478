import React, { useContext, useEffect, useState } from "react";
import Preloader from "../componant/Preloader";
import PollCon from "../context/Polls/PollCon";
import UrduKeyboard from "../componant/Urdu/UrduKeyboard";
import HindiKeyboard from "../componant/Hindi/HindiKeyboard";

export const Poll = (props) => {
  /* option value change state */

  const [pollOption, setpollOption] = useState([
    {
      id: 0,
      opt: "",
      score: 0,
    },
  ]);

  console.log(pollOption);
  const addnewoption = (e) => {
    e.preventDefault();
    const value = {
      id: pollOption.length,
      opt: "",
      score: 0,
    };
    setpollOption(pollOption.concat(value));
  };

  const removeoption = (e, mid) => {
    e.preventDefault();
    const newoption = pollOption.filter((com) => {
      return com.id !== mid;
    });
    if (pollOption.length > 0) {
      setpollOption(newoption);
    }

  };

  /* end option */

  const [preloader, setPreloader] = useState("");

  const myContext = useContext(PollCon);
  const { poll, getAllpoll, addnewpoll, deletePoll, updateNewPoll } = myContext;

  useEffect(() => {
    getAllpoll();
  }, []);
  const style = {
    background: "#00000080",
    display: "block",
  };
  const [newPoll, setnewPoll] = useState({
    title: "",
    last_ip: "",
    language: localStorage.getItem("lang"),
    status: "",
    live_Poll: true,
  });
  const [addPollModal, setAddPollModal] = useState(false);
  const openData = () => {
    setAddPollModal(!addPollModal);

    setpollOption([{
      id: 0,
      opt: "",
      score: 0,
    }])
  };

  const onOptionChange = (e, index) => {
    const elemet = pollOption[index];
    elemet.opt = e.target.value;

  };

  const delete_Poll = async (id) => {
    setPreloader("preShow");
    const response = await deletePoll(id);
    console.log(id);
    if (response.status === "Success") {
      props.showAlert(response.message, "success");
    } else {
      props.showAlert(response.message, "danger");
    }
    setPreloader("");
  };
  const handleClick = async (e) => {
    e.preventDefault();
    setPreloader("preShow");
    if (localStorage.getItem("lang") === "2" || localStorage.getItem("lang") === "1") {
      newPoll.title = urdudata.title
    }
    newPoll.option = pollOption;
    const response = await addnewpoll(newPoll);
    console.log(newPoll);
    if (response.status === "success") {
      openData();
      props.showAlert(response.message, "success");
    } else {
      props.showAlert(response.message, "warning");
    }
    setPreloader("");
  };

  const onChange = (e) => {
    setnewPoll({ ...newPoll, [e.target.name]: e.target.value });
  };

  const changeStatus = async (id, status, type) => {
    setPreloader("preShow");
    let response = {};
    if (type === "livepoll") {
      response = await updateNewPoll(id, { live_poll: status });
    } else {
      response = await updateNewPoll(id, { status: status });
    }
    response.status === "Success"
      ? props.showAlert(`${type}  status is updated`, "success")
      : props.showAlert(response.message, "danger");
    setPreloader("");
  };


  /* edit section */

  const onedit = (e) => {
    setupdatePol({ ...updatePol, [e.target.name]: e.target.value });
  };
  const handleUpdate = async (e) => {
    e.preventDefault()
    setPreloader("preShow");
    updatePol.option = pollOption
    const response = await updateNewPoll(updatePol._id, updatePol)

    if (response.status === "Success") {
      openPollEdit({});
      props.showAlert(response.message, "success");
    } else {
      props.showAlert(response.message, "warning");
    }
    setPreloader("");

  }

  const [editPollModel, seteditPollModel] = useState(false)
  const [updatePol, setupdatePol] = useState({})
  const openPollEdit = (dir) => {

    seteditPollModel(!editPollModel)
    setupdatePol(dir)
    setpollOption(dir.option)

  }

  const [query, setquery] = useState("")
  /* edit close */

  /* slider start */
  const [statusChange, setstatusChange] = useState(null)
  const clickBlock = (status) => {

    setstatusChange(status)
    console.log(statusChange)
    const st = poll.filter((poll) => {
      return poll.status === statusChange
    })
    console.log(st)
  }

  const [showOpIndex, setshowOpIndex] = useState(-1)
  /* slider ends */
  const [urdudata, seturdudata] = useState({
    "title": "",
  })
  const inputUrdu = ((key, value) => {
    urdudata[key] = value
  })
  return (
    <>
      <Preloader show={preloader} />
      {editPollModel && (
        <div className="modal reviewModal" style={style}>
          <div className="modal-dialog" >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Update Poll
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    openPollEdit({})
                  }}
                ></button>
              </div>
              <div className="modal-body">
                <form
                  id="formAccountSettings"

                >
                  <div className="row">
                    {/* category add section */}


                    <div className="col-6">
                      <div className="form-group">
                        <label className="form-label">Enter Title</label>
                        {localStorage.getItem("lang") === "0" ?
                          <input
                            type="text"
                            className={`form-control   m-0`}
                            name="title"
                            id=""
                            aria-describedby="helpId"
                            placeholder="Title"
                            defaultValue={updatePol.title}
                            onChange={onedit}
                          />
                          :
                          (localStorage.getItem("lang") === "1") ? "hindiInput"
                            : (localStorage.getItem("lang") === "2") ?
                              <UrduKeyboard inputUrdu={inputUrdu} inputkey="title" />
                              : ""}
                      </div>
                     
                      <div className="form-group">
                        <label className="form-label"> Live Poll</label>
                        <table width="100%">
                          <tr>
                            <td>
                              <div className="form-check">
                                <input
                                  type="radio"
                                  className="form-check-input"
                                  name="live_poll"
                                  value={false}
                                  defaultChecked={updatePol.live_poll ? false : true}
                                  onChange={onedit}
                                />
                                <label className="form-label"> No</label>
                              </div>
                            </td>
                            <td>
                              <div className="form-check">
                                <input
                                  type="radio"
                                  className="form-check-input"
                                  name="live_poll"
                                  value={true}
                                  defaultChecked={updatePol.live_poll ? true : false}
                                  onChange={onedit}
                                />
                                <label className="form-label"> Yes</label>
                              </div>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="form-group">
                        <label className="form-label"> Status</label>
                        <table width="100%">
                          <tr>
                            <td>
                              <div className="form-check">
                                <input
                                  type="radio"
                                  className="form-check-input"
                                  name="status"
                                  value={false}
                                  defaultChecked={updatePol.status ? false : true}
                                  onChange={onedit}
                                />
                                <label className="form-label"> draft</label>
                              </div>
                            </td>
                            <td>
                              <div className="form-check">
                                <input
                                  type="radio"
                                  className="form-check-input"
                                  name="status"
                                  value={true}
                                  defaultChecked={updatePol.status ? true : false}
                                  onChange={onedit}
                                />
                                <label className="form-label"> publish</label>
                              </div>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div className="col-6">
                      {pollOption.map((p, i) => {
                        return (
                          <>
                            <div className="form-group">
                              <div id="TextBoxesGroup">
                                <input
                                  type="text"
                                  className="form-control  mt-2  m-0"
                                  name="opt"
                                  id=""
                                  aria-describedby="helpId"
                                  placeholder="Enter Option"
                                  defaultValue={p.opt}
                                  onChange={(e) => {
                                    onOptionChange(e, i);
                                  }}
                                />
                              </div>

                              <i
                                className="bx bx-plus"
                                onClick={(e) => {
                                  addnewoption(e);
                                }}
                              ></i>
                              <i
                                className="bx bx-minus"
                                onClick={(e) => {
                                  removeoption(e, i);
                                }}
                              ></i>
                            </div>
                          </>
                        );
                      })}
                    </div>
                  </div>

                  {/* category add section end */}

                  <div className="mt-3">
                    <button
                      type="reset"
                      className="btn btn-outline-primary me-2 my-btn"
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="btn orange-btn btn-primary me-2"
                      onClick={handleUpdate}
                    >
                      Update
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}

      {addPollModal && (
        <div className="modal reviewModal" style={style}>
          <div className="modal-dialog" >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Add New Poll
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={openData}
                ></button>
              </div>
              <div className="modal-body">
                <form
                  id="formAccountSettings"
                  method="POST"
                  onSubmit={handleClick}
                >
                  <div className="row">
                    {/* category add section */}


                    <div className="col-6">
                      <div className="form-group">
                        <label className="form-label">Enter Title</label>
                        {localStorage.getItem("lang") === "0" ?
                          <input
                            type="text"
                            className={`form-control   m-0`}
                            name="title"
                            id=""
                            aria-describedby="helpId"
                            placeholder="Title"
                            onChange={onChange}
                          />
                          :
                          (localStorage.getItem("lang") === "1") ? <HindiKeyboard keyinput='title' onchange={inputUrdu} />
                            : (localStorage.getItem("lang") === "2") ?
                              <UrduKeyboard inputUrdu={inputUrdu} inputkey="title" />
                              : ""}
                      </div>
                  
                      <div className="form-group">
                        <label className="form-label"> Live Poll</label>
                        <table width="100%">
                          <tr>
                            <td>
                              <div className="form-check">
                                <input
                                  type="radio"
                                  className="form-check-input"
                                  name="live_Poll"
                                  value={false}
                                  onChange={onChange}
                                />
                                <label className="form-label"> Yes</label>
                              </div>
                            </td>
                            <td>
                              <div className="form-check">
                                <input
                                  type="radio"
                                  className="form-check-input"
                                  name="live_Poll"
                                  value={true}
                                  onChange={onChange}
                                />
                                <label className="form-label"> No</label>
                              </div>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="form-group">
                        <label className="form-label"> Status</label>
                        <table width="100%">
                          <tr>
                            <td>
                              <div className="form-check">
                                <input
                                  type="radio"
                                  className="form-check-input"
                                  name="status"
                                  value={false}
                                  onChange={onChange}
                                />
                                <label className="form-label"> draft</label>
                              </div>
                            </td>
                            <td>
                              <div className="form-check">
                                <input
                                  type="radio"
                                  className="form-check-input"
                                  name="status"
                                  value={true}
                                  onChange={onChange}
                                />
                                <label className="form-label"> publish</label>
                              </div>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div className="col-6">
                      {pollOption.map((p, i) => {
                        return (
                          <>
                            <div className="form-group">
                              <div id="TextBoxesGroup">
                                <input
                                  type="text"
                                  className="form-control  mt-2  m-0"
                                  name="opt"
                                  id=""
                                  aria-describedby="helpId"
                                  placeholder="Enter Option"
                                  onChange={(e) => {
                                    onOptionChange(e, i);
                                  }}
                                />
                              </div>

                              <i
                                className="bx bx-plus"
                                onClick={(e) => {
                                  addnewoption(e);
                                }}
                              ></i>
                              <i
                                className="bx bx-minus"
                                onClick={(e) => {
                                  removeoption(e, i);
                                }}
                              ></i>
                            </div>
                          </>
                        );
                      })}
                    </div>
                  </div>

                  {/* category add section end */}

                  <div className="mt-3">
                    <button
                      type="reset"
                      className="btn btn-outline-primary me-2 my-btn"
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="btn orange-btn btn-primary me-2"
                      onClick={handleClick}
                    >
                      Add
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="content-wrapper">
        <div className="container-fluid flex-grow-1 container-p-y">
          <div className="fixed-button">
            <div
              className={`btn btn-sm fixed_button btn-outline-primary ${addPollModal?"d-none":"d-block"}`}
              onClick={openData}
            >
              + Add New Poll
            </div>
          </div>
          <div className="row">
            <h4 className="card-header c_heading">
              <span className="headingcontent">My Poll({poll.length})</span>


            </h4>

            <div className="col-lg-12 mb-4 ">
              <div className="card">
                <h5 className="card-header">
                  <div className="row">
                    <div className="col-lg-6 font-bold">
                    <ul className="slider_button slider-white plain-orange">
                        <li
                          className={`${statusChange === null ? "slider-active" : ""}`}
                          onClick={() => {
                            clickBlock(null);
                          }}
                        >All</li>
                        <li
                          className={`${(statusChange !== null && statusChange === false) ? "slider-active" : ""}`}
                          onClick={() => {
                            clickBlock(false);
                          }}
                        >
                          Block
                        </li>
                        <li
                          className={`${(statusChange !== null && statusChange === true) ? "slider-active" : ""}`}
                          onClick={() => {
                            clickBlock(true);
                          }}
                        >
                          Unblock
                        </li>
                      </ul>
                    </div>
                    <div className="col-lg-6 text-right">
                      <input
                        type="text"
                        className="form-control search"
                        placeholder="Search Polls..."
                        onChange={(e) => {
                          setquery(e.target.value)
                        }}
                      />
                    </div>
                  </div>
                </h5>

                <div className="table-responsive text-nowrap mb-1">
                  <table className="table table-hover">

                    <thead className="order_history_header">
                      <tr>
                        <th>Title</th>
                        {/*  <th>Options</th>
                        <th>Last Ip</th>
                        <th> Language</th> */}
                        <th>Status</th>
                        <th>View Option</th>
                        {/*   <th>Live Poll</th> */}

                        <th className="action">Action</th>
                      </tr>
                    </thead>
                    <tbody className="table-border-bottom-0 order_history_body">
                      {poll
                        .filter((Poll) => {
                          return (statusChange === null || Poll.status === statusChange)
                            && (Poll.title?.toLowerCase().includes(query?.toLowerCase()))
                        })
                        .map((Poll, index) => {
                          return (
                            <tr key={index}>
                              <td><span>{Poll.title}</span>
                                <ul className={`optionlist ${index === showOpIndex ? "show" : "hide"}`}>
                                  {Poll.option.map((popt, i) => {
                                    return (
                                      <>

                                        <li key={i}>{popt.opt} - ({popt.score})</li>

                                      </>
                                    )
                                  })}

                                </ul></td>

                              {/*  <td className="">

                              <ul>
                                {Poll.option.map((popt, i) => {
                                  return (
                                    <>

                                      <li key={i}>{popt.opt} - ({popt.score})</li>

                                    </>
                                  )
                                })}

                              </ul>
                            </td> */}
                              {/*   <td>{Poll.last_ip}</td>
                            <td>{Poll.language}</td>
 */}
                              <td>
                                {Poll.status ? (
                                  <i
                                    className="bx bx-check"
                                    onClick={() => {
                                      changeStatus(Poll._id, false, "Poll");
                                    }}
                                  ></i>
                                ) : (
                                  <i
                                    className="bx bx-block"
                                    onClick={() => {
                                      changeStatus(Poll._id, true, "Poll");
                                    }}
                                  ></i>
                                )}
                              </td>
                              <td><span onClick={() => {
                                setshowOpIndex(index)
                              }}>View Option</span></td>

                              {/*  <td>
                              {Poll.live_poll ? (
                                <i
                                  className="bx bx-check"
                                  onClick={() => {
                                    changeStatus(Poll._id, false, "livepoll");
                                  }}
                                ></i>
                              ) : (
                                <i
                                  className="bx bx-block"
                                  onClick={() => {
                                    changeStatus(Poll._id, true, "livepoll");
                                  }}
                                ></i>
                              )}
                            </td> */}

                              <td className="action">

                                <div className="dropdown ">
                                  <i className="bx bx-trash me-1"
                                    onClick={() => {
                                      delete_Poll(Poll._id)
                                    }}></i>

                                  <i className="bx bx-edit me-1"
                                    onClick={() => {
                                      openPollEdit(Poll)
                                    }}></i>


                                </div></td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
