import React, { useState } from "react";
import PollCon from "./PollCon";

const PollState = (props) => {
  const host = process.env.REACT_APP_URL;
  console.log(host);
  const token = localStorage.getItem("token");
  console.log(token);


  const [poll, setPoll] = useState([]);

  const addnewpoll = async (body) => {
    try {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "auth-token": token
        }, body: JSON.stringify(body)
      };
      const response = await fetch(
        `${host}/api/polls/addPolls`,
        requestOptions
      );
      const dataresp = await response.json();
      console.log(dataresp);
      if (dataresp.status === "success") {
        setPoll(poll.concat(dataresp.data))
      }
      return dataresp;

    }
    catch (e) {

    }
  }
  const deletePoll = async (id) => {
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "auth-token": token
      },
    };
    const response = await fetch(
      `${host}/api/polls/deletepolls/${id}`,
      requestOptions
    );
    const datares = await response.json();

    console.log(datares);

    if(datares.status=== "Success"){
      const updateData = poll.filter((pol)=>{
        return pol._id!==id

      })
      setPoll(updateData)
    }
    return datares
  };
  const getAllpoll = async ( type) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "auth-token": token
      },
    };
    const response = await fetch(
      `${host}/api/polls/getPolls`,
      requestOptions
    );
    const datares = await response.json();

    console.log(datares);
    if(datares.status === "Success"){
     
      setPoll(datares.data);
    }

    
  };
  const updateNewPoll = async (id,body) => {
    try {
      const requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "auth-token": token
        }, body: JSON.stringify(body)
      };
      const response = await fetch(
        `${host}/api/polls/updatePolls/${id}`,
        requestOptions
      );
      const dataresp = await response.json();
      console.log(dataresp);
      if (dataresp.status === "Success") {

        for(let i = 0; i < poll.length; i++){
          let element = poll[i];
          if(element._id.toString() === id){
            poll[i] = dataresp.data;
          }
        }
        setPoll(poll);
      }
      return dataresp;

    }
    catch (e) {

    }
  }


  
  
 
  return (
    <PollCon.Provider
      value={{
        poll,
        getAllpoll,
        addnewpoll,
        deletePoll,
        updateNewPoll
      }}

    >
      {props.children}
    </PollCon.Provider>
  );
}
export default PollState;