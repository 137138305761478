import React, { useState } from "react";
import VedioCon from "./VedioCon";

const BlogVedioState = (props) => {
  const host = process.env.REACT_APP_URL;
  console.log(host);
  const token = localStorage.getItem("token");
  console.log(token);


  const [blog_vedio, setblog_vedio] = useState([]);

   const addblog_vedio  = async (body) => {
    try {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "auth-token": token
        }, body: JSON.stringify(body)
      };
      const response = await fetch(
        `${host}/api/blog_vedio/addBlog_vedio`,
        requestOptions
      );
      const dataresp = await response.json();
      console.log(dataresp);
      if (dataresp.status === "success") {
        setblog_vedio(blog_vedio.concat(dataresp.data))
        
      }
     return dataresp

    }
    catch (e) {

    }
  } 
  const deleteblog_vedio = async (id) => {
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "auth-token": token
      },
    };
    const response = await fetch(
      `${host}/api/blog_vedio/deleteBlog_vedio/${id}`,
      requestOptions
    );
    const datares = await response.json();

    console.log(datares);
    
    if(datares.status=== "Success"){
      const updateData = blog_vedio.filter((cat)=>{
        return cat._id!==id

      })

      setblog_vedio(updateData)
    }
    return datares
  };

  const getAllVedio = async (type) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "auth-token": token
      },
    };
    const response = await fetch(
      `${host}/api/blog_vedio/getBlog_vedio`,
      requestOptions
    );
    const datares = await response.json();

    console.log(datares);
    if (datares.status === "Success") {

      setblog_vedio(datares.data);
    }


  };

  const updateblog_vedio = async (id,body) => {
    try {
      const requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "auth-token": token
        }, body: JSON.stringify(body)
      };
      const response = await fetch(
        `${host}/api/blog_vedio/updateBlog_vedio/${id}`,
        requestOptions
      );
      const dataresp = await response.json();
      console.log(dataresp);
      if (dataresp.status === "Success") {

        for(let i = 0; i < blog_vedio.length; i++){
          let element = blog_vedio[i];
          if(element._id.toString() === id){
            blog_vedio[i] = dataresp.data;
          }
        }
        setblog_vedio(blog_vedio);
      }
      return dataresp;

    }
    catch (e) {

    }
  }

  return (
    <VedioCon.Provider
      value={{
        blog_vedio,
        getAllVedio,
        deleteblog_vedio,
        addblog_vedio,
        updateblog_vedio
       
      }}

    >
      {props.children}
    </VedioCon.Provider>
  );
}
export default BlogVedioState;