import React, { useContext, useEffect, useState } from "react";
import Choosecategory from "../componant/Media/Choosecategory";
import Preloader from "../componant/Preloader";
import categoryCon from "../context/Category/CategoryContext";
import postCon from "../context/Post/PostContext";
import MediaCon from "../context/Media/MediaCon";
import UserCon from "../context/User/UserCon"



export const AddNewPost = (props) => {
    const [preloader, setPreloader] = useState("");
    const myConte = useContext(MediaCon)
    const { media,
        getAllMedia, uploadMedia } = myConte
    const myContex = useContext(postCon)
    const { post,
        getAllPost, createPost, deletePost } = myContex
    const style = {
        background: "#00000080",
        display: "block",
    };
    const [newPost, setnewPost] = useState({
        post_title: "tegstg",
        post_status: true,
        user_id: "63df88aaa49eb1671ca7ddca",
        tag: ["yrhdhd"],
        author: "Test",
        p_type: "gdts",
        content: "test",
        cate_id: [{ cat_id: "63e64c86bb2dc5d2fcd5185c" }],
        f_image: "test",
        f_type: "test",
        author_status: true
    })
    useEffect(() => {
        getAllPost(); getAllCatagory("post"); getallUser("user")
    }, [])

    const myContext = useContext(categoryCon)
    const { category,
        getAllCatagory, createCategory, } = myContext

    const userCon = useContext(UserCon)
    const { user, getallUser } = userCon

    const [mediaModel, setmediaModel] = useState(false)

    const openMedia = async () => {
        setmediaModel(!mediaModel)
        if (!mediaModel) {
            setPreloader("preShow")
            await getAllMedia()
            setPreloader("")
        }


    }
    const onchange = (e) => {
        setnewPost({ ...newPost, [e.target.name]: e.target.value })
    }
    const submitPost = async () => {
        console.log(newPost)
        setPreloader("preShow");
        const response = await createPost(newPost)
        console.log(newPost);
        if (response === true) {
            setPreloader("");
        }

    }
    const selectImage = (url) => {
        openMedia()
        setnewPost({ ...newPost, f_image: url })
    }

    return (
        <>
            <Preloader show={preloader} />
            {mediaModel && (
                <div className="modal reviewModal" style={style}>
                    <div className="modal-dialog" style={{ width: "40%" }}>
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">
                                    choose image
                                </h5>
                                <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                    onClick={openMedia}
                                ></button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    {media.map((med, i) => {

                                        return (<div className="col-2 mb-2" key={i} onClick={() => {
                                            selectImage(med.m_url)
                                        }} style={{ position: "relative" }}>
                                            <img className="img-fluid" style={{ height: "100px", width: "100%" }} src={med.m_url} />
                                        </div>)
                                    })}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <div className="content-wrapper">
                <div className="container-fluid flex-grow-1 container-p-y">
                    <div className="fixed-button">
                        <div
                            className="btn btn-sm fixed_button btn-outline-primary"
                            onClick={submitPost}
                        >
                            Save Post
                        </div>


                    </div>
                    <div className="row">
                        <h4 className="card-header c_heading">
                            <span className="headingcontent">
                                Add New Post

                            </span>

                            <div className="heading_buttons">
                                <input
                                    className="form-control  search  mb-4"
                                    placeholder="Search category..."
                                />
                            </div>
                        </h4>
                        <div className="col-12">
                            <div className="row addnewproduct">
                                <div className="col-6 my-1">
                                    <div className="heading">
                                        <h6>Post Info</h6>
                                    </div>
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="form-group">
                                                <label className="form-label">Post Title</label>
                                                <input type="text" className="form-control" name="post_title" onChange={onchange} />
                                            </div>
                                            <div className="form-group">
                                                <label className="form-label">Content</label>
                                                <input type="text" className="form-control" name="content" onChange={onchange} />
                                            </div>
                                        </div>
                                    </div>


                                    <div className="card">
                                        <div className="card-body">

                                            <Choosecategory onChange={onchange} type={"blog"} />


                                            <div className="form-group">
                                                <label className="form-label">Enter User Id</label>
                                                <select className="form-control" name="user_id" onChange={onchange} >
                                                    <option value={0}>Choose user</option>
                                                    {user.map((user, i) => {
                                                        return (<option value={user._id}>{user.fname}</option>)
                                                    })}
                                                </select></div>

                                            <div className="form-group">
                                                <label className="form-label">Author</label>
                                                <input type="text" className="form-control" name="author" onChange={onchange} />
                                            </div>

                                        </div>


                                    </div>
                                    <div className="heading">
                                        <h6>Post Status</h6>
                                    </div>
                                    <div className="card mb-3">
                                        <div className="card-body">
                                            <table width="100%">
                                                <tr>
                                                    <td><div className="form-check">
                                                        <input type="radio" className="form-check-input" name="post_status" value={false} onChange={onchange} />
                                                        <label className="form-label"> Draft</label>
                                                    </div></td>
                                                    <td><div className="form-check">
                                                        <input type="radio" className="form-check-input" name="post_status" value={true} onChange={onchange} />
                                                        <label className="form-label"> Publish</label>
                                                    </div></td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6 my-1">
                                    <div className="heading">
                                        <h6>Post Image</h6>
                                    </div>
                                    <div className="card">

                                        <div className="card-body">
                                            <table width="100%">
                                                <tr>
                                                    <td><div className="form-check">
                                                        <input type="radio" className="form-check-input" name="f_type" onChange={onchange} />
                                                        <label className="form-label"> Image</label>
                                                    </div></td>
                                                    <td><div className="form-check">
                                                        <input type="radio" className="form-check-input" name="f_type" onChange={onchange} />
                                                        <label className="form-label"> Vedio</label>
                                                    </div></td>
                                                    <td><div className="form-check">
                                                        <input type="radio" className="form-check-input" name="f_type" onChange={onchange} />
                                                        <label className="form-label"> Embd</label>
                                                    </div></td>
                                                </tr>
                                            </table>
                                            <div className="image_div_content"
                                                onClick={openMedia}>
                                                <i className="bx bxs-image-add"></i>
                                                <p className="normalhyperlink">click to upload</p>
                                            </div>
                                            <div className="">
                                                <img className="img-fluid" src={newPost.f_image} />
                                            </div>
                                        </div>

                                        <div className="card-footer">
                                            <span className="hinttext text-muted">You can upload Maximum size of 5 mb</span>
                                        </div>
                                    </div>
                                    <div className="card mb-3">
                                        <div className="card-body">
                                            <div className="form-group">
                                                <label className="form-label">Tag</label>
                                                <input type="text" className="form-control" />
                                            </div>
                                            <div className="form-group">
                                                <label className="form-label">Post Type</label>
                                                <select className="form-control" name="p_type" onChange={onchange}>
                                                    <option>Article</option>
                                                    <option>Story</option>
                                                    <option>Event</option>
                                                </select>
                                            </div>

                                        </div>

                                    </div>

                                    <div className="heading">
                                        <h6>Author Status</h6>
                                    </div>
                                    <div className="card mb-3">
                                        <div className="card-body">
                                            <table width="100%">
                                                <tr>
                                                    <td><div className="form-check">
                                                        <input type="radio" className="form-check-input" name="author_status" value={false} onChange={onchange} />
                                                        <label className="form-label"> Show</label>
                                                    </div></td>
                                                    <td><div className="form-check">
                                                        <input type="radio" className="form-check-input" name="author_status" value={true} onChange={onchange} />
                                                        <label className="form-label"> Hide</label>
                                                    </div></td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}
