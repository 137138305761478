import React, { useContext, useEffect, useState } from "react";

import Preloader from "../componant/Preloader";
import UserCon from "../context/User/UserCon";


export const User = (props) => {
    const host = process.env.REACT_APP_URL;
    console.log(host);
    const token = localStorage.getItem("token");
    console.log(token);

    const [preloader, setPreloader] = useState("");

    const myCont = useContext(UserCon);
    const { user,
        getallUser, deleteuser,
        createuser, updateUser
    } = myCont



    useEffect(() => {
        getallUser("user");
    }, [])
    const style = {
        background: "#00000080",
        display: "block",
    };
    const [newUser, setnewUser] = useState({
        fname: "",
        lanme: "",
        email: "",
        phone: "",
        utype: "user",
        country: "",
        date: "",
        nextRenewDate: "",
        varificationCode: "",
        varified: true,
        adsAllow: false,
        imgAllow: true,
        newsletters: false,
        avtar: "",
        signature: "",
        status: true,
        language: localStorage.getItem("lang"),
        "abouttext": ""
    })
    const [addUsermodel, setAddUsermodel] = useState(false);

    const openData = () => {
        setAddUsermodel(!addUsermodel);
    };


    const onChange = (e) => {
        setnewUser({ ...newUser, [e.target.name]: e.target.value })
    }
    const deleteUser = async (id) => {
        setPreloader("preShow");
        const response = await deleteuser(id)
        console.log(id);
        if (response.status === "Success") {
            props.showAlert(response.message, "success")
        }
        else {

            props.showAlert(response.message, "danger")
        }
        setPreloader("");
    }
    const handleClick = async (e) => {
        e.preventDefault()


        setPreloader("preShow");
        const response = await createuser(newUser)

        if (response.status === "success") {
            openData();
            props.showAlert(response.message, "success")
        } else {
            props.showAlert(response.message, "warning")
        }

        setPreloader("");
        console.log(newUser);
    }
    const changeStatus = async (id, status, type) => {
        setPreloader("preShow")
        let response = {}
        if (type === "varified") {
            response = await updateUser(id, { varified: status })

        }
        else {
            response = await updateUser(id, { status: status })
        }
        (response.status === "Success") ?
            props.showAlert(`${type}  status is updated`, "success") : props.showAlert(response.message, "danger")
        setPreloader("")
    }
    const [mediaModel, setMediaModel] = useState(false)
    const openMedia = () => {
        setMediaModel(!mediaModel)
    }
    const selectImage = (url) => {
        openMedia()
        setnewUser({ ...newUser, image: url })
    }


    const [statusChange, setstatusChange] = useState(null)
    const clickBlock = (status) => {

        setstatusChange(status)
        console.log(statusChange)
        const st = user.filter((cat) => {
            return cat.status === statusChange
        })
        console.log(st)
    }

    const onedit = (e) => {
        setupdateuser({ ...updateuser, [e.target.name]: e.target.value });
    };

    const handleUpdate = async (e) => {
        e.preventDefault()
        setPreloader("preShow");
        const response = await updateUser(updateuser._id, updateuser)

        if (response.status === "Success") {
            openUserEdit({});
            props.showAlert(response.message, "success");
        } else {
            props.showAlert(response.message, "warning");
        }
        setPreloader("");
    }
    const [editusermodel, seteditusermodel] = useState(false)
    const [updateuser, setupdateuser] = useState({})
    const openUserEdit = (cat) => {
        console.log(cat)
        seteditusermodel(!editusermodel)
        setupdateuser(cat)

    }
    const [query, setquery] = useState("")
    return (
        <>
            <Preloader show={preloader} />

            {editusermodel && (
                <div className="modal reviewModal" style={style}>
                    <div className="modal-dialog" >
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">
                                    Update  User
                                </h5>
                                <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                    onClick={() => {
                                        openUserEdit({})
                                    }}>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div
                                    id="formAccountSettings"

                                >
                                    <div className="row">

                                        <div className="col-md-12  my-1">
                                            <div className="form-group">
                                                <label className="form-label">Update First Name</label>
                                                <input
                                                    type="text"
                                                    className="form-control    m-0"
                                                    name="fname"
                                                    id=""
                                                    aria-describedby="helpId"
                                                    placeholder="Enter First Name"
                                                    defaultValue={updateuser.fname}
                                                    onChange={onedit}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label className="form-label">Update Last Name</label>
                                                <input
                                                    type="text"
                                                    className="form-control    m-0"
                                                    name="lname"
                                                    id=""
                                                    aria-describedby="helpId"
                                                    placeholder="Enter Last Name"
                                                    defaultValue={updateuser.lname}
                                                    onChange={onedit}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-6">

                                            <div className="form-group">
                                                <label className="form-label">Update Email</label>
                                                <input
                                                    type="text"
                                                    className="form-control    m-0"
                                                    name="email"
                                                    id=""
                                                    aria-describedby="helpId"
                                                    placeholder="Enter Email"
                                                    defaultValue={updateuser.email}
                                                    onChange={onedit}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label className="form-label">Update Phone</label>
                                                <input
                                                    type="text"
                                                    className="form-control    m-0"
                                                    name="Phone"
                                                    id=""
                                                    aria-describedby="helpId"
                                                    placeholder="Enter Phone"
                                                    defaultValue={updateuser.phone}
                                                    onChange={onedit}
                                                /></div>
                                            <div className="form-group">
                                                <label className="form-label">Update Password</label>
                                                <input
                                                    type="password"
                                                    className="form-control    m-0"
                                                    name="pass"
                                                    id=""
                                                    aria-describedby="helpId"
                                                    placeholder="Update Password"
                                                    defaultValue={updateuser.pass}
                                                    onChange={onedit}
                                                /></div>
                                            <div className="form-group">
                                                <label className="form-label">Update Country</label>
                                                <input
                                                    type="text"
                                                    className="form-control    m-0"
                                                    name="country"
                                                    id=""
                                                    aria-describedby="helpId"
                                                    placeholder="Enter Country"
                                                    defaultValue={updateuser.country}
                                                    onChange={onedit}
                                                /></div>
                                            <div className="form-group">
                                                <label className="form-label">Update Date</label>
                                                <input
                                                    type="date"
                                                    className="form-control    m-0"
                                                    name="date"
                                                    id=""
                                                    aria-describedby="helpId"
                                                    placeholder="Enter Date"
                                                    defaultValue={updateuser.date}
                                                    onChange={onedit}
                                                /></div>
                                            <div className="form-group">
                                                <label className="form-label">Update NextReNewDate</label>
                                                <input
                                                    type="date"
                                                    className="form-control    m-0"
                                                    name="nextRenewDate"
                                                    id=""
                                                    aria-describedby="helpId"
                                                    placeholder="Enter NextReNewDate"
                                                    defaultValue={updateuser.nextRenewDate}
                                                    onChange={onedit}
                                                /></div>
                                            <div className="form-group">
                                                <label className="form-label">Update Varification Code</label>
                                                <input
                                                    type="text"
                                                    className="form-control    m-0"
                                                    name="varificationcode"
                                                    id=""
                                                    aria-describedby="helpId"
                                                    placeholder="Enter Varification Code"
                                                    defaultValue={updateuser.varificationCode}
                                                    onChange={onedit}
                                                /></div>


                                            <div className="form-group">
                                                <label className="form-label"> Varified</label>
                                                <table width="100%">
                                                    <tr>
                                                        <td><div className="form-check">
                                                            <input type="radio" className="form-check-input" name="varified" value={false} defaultChecked={updateuser.status ? false : true} onChange={onedit} />
                                                            <label className="form-label"> no</label>
                                                        </div></td>
                                                        <td><div className="form-check">
                                                            <input type="radio" className="form-check-input" name="varified" value={true} defaultChecked={updateuser.status ? true : false} onChange={onedit} />
                                                            <label className="form-label"> yes</label>
                                                        </div></td>
                                                    </tr>
                                                </table>
                                            </div>

                                        </div>

                                        <div className="col-6">

                                            <div className="form-group">
                                                <label className="form-label"> Image Allow</label>
                                                <table width="100%">
                                                    <tr>
                                                        <td><div className="form-check">
                                                            <input type="radio" className="form-check-input" name="imgAllow" value={false} defaultChecked={updateuser.status ? false : true} onChange={onedit} />
                                                            <label className="form-label"> no</label>
                                                        </div></td>
                                                        <td><div className="form-check">
                                                            <input type="radio" className="form-check-input" name="imgAllow" value={true} defaultChecked={updateuser.status ? true : false} onChange={onedit} />
                                                            <label className="form-label"> yes</label>
                                                        </div></td>
                                                    </tr>
                                                </table>
                                            </div>
                                            <div className="form-group">
                                                <label className="form-label"> Newsletters</label>
                                                <table width="100%">
                                                    <tr>
                                                        <td><div className="form-check">
                                                            <input type="radio" className="form-check-input" name="newsLetters" value={false} defaultChecked={updateuser.status ? false : true} onChange={onedit} />
                                                            <label className="form-label"> No</label>
                                                        </div></td>
                                                        <td><div className="form-check">
                                                            <input type="radio" className="form-check-input" name="newsLetters" value={true} defaultChecked={updateuser.status ? true : false} onChange={onedit} />
                                                            <label className="form-label"> yes</label>
                                                        </div></td>
                                                    </tr>
                                                </table>
                                            </div>
                                            <div className="form-group">
                                                <div className="image_div_content"
                                                    onClick={openMedia}>
                                                    <i className="bx bxs-image-add"></i>
                                                    <p className="normalhyperlink">click to upload Avtar</p>
                                                </div>
                                                <div className="">
                                                    <img className="img-fluid" src={user.avtar} alt="writer_image" style={{ height: "100px", width: "100%" }} />
                                                </div></div>
                                            <div className="form-group">
                                                <label className="form-label">Update Signature</label>
                                                <input
                                                    type="text"
                                                    className="form-control    m-0"
                                                    name="singnature"
                                                    id=""
                                                    aria-describedby="helpId"
                                                    placeholder="Update singnature"
                                                    defaultValue={updateUser.signature}
                                                    onChange={onedit}
                                                /></div>
                                            <div className="form-group">
                                                <label className="form-label"> Status</label>
                                                <table width="100%">
                                                    <tr>
                                                        <td><div className="form-check">
                                                            <input type="radio" className="form-check-input" name="status" value={false} defaultChecked={updateUser.status ? false : true} onChange={onedit} />
                                                            <label className="form-label"> Draft</label>
                                                        </div></td>
                                                        <td><div className="form-check">
                                                            <input type="radio" className="form-check-input" name="status" value={true} defaultChecked={updateUser.status ? true : false} onChange={onedit} />
                                                            <label className="form-label"> Publish</label>
                                                        </div></td>
                                                    </tr>
                                                </table>
                                            </div>
                                            <div className="form-group">
                                                <label className="form-label">Update About Text</label>
                                                <input
                                                    type="text"
                                                    className="form-control    m-0"
                                                    name="abouttext"
                                                    id=""
                                                    aria-describedby="helpId"
                                                    placeholder="Enter About Text"

                                                    onChange={onChange}
                                                /></div>
                                            <div className="form-group">
                                                <label className="form-label"> Ads Allow</label>
                                                <table width="100%">
                                                    <tr>
                                                        <td><div className="form-check">
                                                            <input type="radio" className="form-check-input" name="adsAllow" value={false} defaultChecked={updateuser.status ? false : true} onChange={onedit} />
                                                            <label className="form-label"> no</label>
                                                        </div></td>
                                                        <td><div className="form-check">
                                                            <input type="radio" className="form-check-input" name="adsAllow" value={true} defaultChecked={updateuser.status ? true : false} onChange={onedit} />
                                                            <label className="form-label"> yes</label>
                                                        </div></td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    {/* category add section end */}

                                    <div className="mt-3">
                                        <button
                                            type="reset"
                                            className="btn btn-outline-primary me-2 my-btn"
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            type="submit"
                                            className="btn orange-btn btn-primary me-2"
                                            onClick={handleUpdate}
                                        >
                                            Update
                                        </button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div >

            )
            }

            {addUsermodel && (
                <div className="modal reviewModal" style={style}>
                    <div className="modal-dialog" >
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">
                                    Add New User
                                </h5>
                                <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                    onClick={openData}
                                ></button>
                            </div>
                            <div className="modal-body">
                                <div
                                    id="formAccountSettings"
                                    method="POST"
                                    onSubmit={handleClick}
                                >
                                    <div className="row">

                                        <div className="col-md-12  my-1">
                                            <div className="form-group">
                                                <label className="form-label">Enter First Name</label>
                                                <input
                                                    type="text"
                                                    className="form-control    m-0"
                                                    name="fname"
                                                    id=""
                                                    aria-describedby="helpId"
                                                    placeholder="Enter First Name"

                                                    onChange={onChange}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label className="form-label">Enter Last Name</label>
                                                <input
                                                    type="text"
                                                    className="form-control    m-0"
                                                    name="lname"
                                                    id=""
                                                    aria-describedby="helpId"
                                                    placeholder="Enter Last Name"

                                                    onChange={onChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-6">

                                            <div className="form-group">
                                                <label className="form-label">Enter Email</label>
                                                <input
                                                    type="text"
                                                    className="form-control    m-0"
                                                    name="email"
                                                    id=""
                                                    aria-describedby="helpId"
                                                    placeholder="Enter Email"

                                                    onChange={onChange}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label className="form-label">Enter Phone</label>
                                                <input
                                                    type="text"
                                                    className="form-control    m-0"
                                                    name="Phone"
                                                    id=""
                                                    aria-describedby="helpId"
                                                    placeholder="Enter Phone"

                                                    onChange={onChange}
                                                /></div>
                                            <div className="form-group">
                                                <label className="form-label">Enter Password</label>
                                                <input
                                                    type="password"
                                                    className="form-control    m-0"
                                                    name="pass"
                                                    id=""
                                                    aria-describedby="helpId"
                                                    placeholder="Enter Password"

                                                    onChange={onChange}
                                                /></div>
                                            <div className="form-group">
                                                <label className="form-label">Enter Country</label>
                                                <input
                                                    type="text"
                                                    className="form-control    m-0"
                                                    name="country"
                                                    id=""
                                                    aria-describedby="helpId"
                                                    placeholder="Enter Country"

                                                    onChange={onChange}
                                                /></div>
                                            <div className="form-group">
                                                <label className="form-label">Enter Date</label>
                                                <input
                                                    type="date"
                                                    className="form-control    m-0"
                                                    name="date"
                                                    id=""
                                                    aria-describedby="helpId"
                                                    placeholder="Enter Date"

                                                    onChange={onChange}
                                                /></div>
                                            <div className="form-group">
                                                <label className="form-label">Enter NextReNewDate</label>
                                                <input
                                                    type="date"
                                                    className="form-control    m-0"
                                                    name="nextRenewDate"
                                                    id=""
                                                    aria-describedby="helpId"
                                                    placeholder="Enter NextReNewDate"

                                                    onChange={onChange}
                                                /></div>
                                            <div className="form-group">
                                                <label className="form-label">Enter Varification Code</label>
                                                <input
                                                    type="text"
                                                    className="form-control    m-0"
                                                    name="varificationcode"
                                                    id=""
                                                    aria-describedby="helpId"
                                                    placeholder="Enter Varification Code"

                                                    onChange={onChange}
                                                /></div>


                                            <div className="form-group">
                                                <label className="form-label"> Varified</label>
                                                <table width="100%">
                                                    <tr>
                                                        <td><div className="form-check">
                                                            <input type="radio" className="form-check-input" name="varified" value={false} onChange={onChange} />
                                                            <label className="form-label"> no</label>
                                                        </div></td>
                                                        <td><div className="form-check">
                                                            <input type="radio" className="form-check-input" name="varified" value={true} onChange={onChange} />
                                                            <label className="form-label"> yes</label>
                                                        </div></td>
                                                    </tr>
                                                </table>
                                            </div>

                                        </div>

                                        <div className="col-6">

                                            <div className="form-group">
                                                <label className="form-label"> Image Allow</label>
                                                <table width="100%">
                                                    <tr>
                                                        <td><div className="form-check">
                                                            <input type="radio" className="form-check-input" name="imgAllow" value={false} onChange={onChange} />
                                                            <label className="form-label"> no</label>
                                                        </div></td>
                                                        <td><div className="form-check">
                                                            <input type="radio" className="form-check-input" name="imgAllow" value={true} onChange={onChange} />
                                                            <label className="form-label"> yes</label>
                                                        </div></td>
                                                    </tr>
                                                </table>
                                            </div>
                                            <div className="form-group">
                                                <label className="form-label"> Newsletters</label>
                                                <table width="100%">
                                                    <tr>
                                                        <td><div className="form-check">
                                                            <input type="radio" className="form-check-input" name="newsLetters" value={false} onChange={onChange} />
                                                            <label className="form-label"> No</label>
                                                        </div></td>
                                                        <td><div className="form-check">
                                                            <input type="radio" className="form-check-input" name="newsLetters" value={true} onChange={onChange} />
                                                            <label className="form-label"> yes</label>
                                                        </div></td>
                                                    </tr>
                                                </table>
                                            </div>
                                            <div className="form-group">
                                                <div className="image_div_content"
                                                    onClick={openMedia}>
                                                    <i className="bx bxs-image-add"></i>
                                                    <p className="normalhyperlink">click to upload Avtar</p>
                                                </div>
                                                <div className="">
                                                    <img className="img-fluid" src={user.avtar} alt="writer_image" style={{ height: "100px", width: "100%" }} />
                                                </div></div>
                                            <div className="form-group">
                                                <label className="form-label">Enter Signature</label>
                                                <input
                                                    type="text"
                                                    className="form-control    m-0"
                                                    name="singnature"
                                                    id=""
                                                    aria-describedby="helpId"
                                                    placeholder="Enter singnature"

                                                    onChange={onChange}
                                                /></div>
                                            <div className="form-group">
                                                <label className="form-label"> Status</label>
                                                <table width="100%">
                                                    <tr>
                                                        <td><div className="form-check">
                                                            <input type="radio" className="form-check-input" name="status" value={false} onChange={onChange} />
                                                            <label className="form-label"> Draft</label>
                                                        </div></td>
                                                        <td><div className="form-check">
                                                            <input type="radio" className="form-check-input" name="status" value={true} onChange={onChange} />
                                                            <label className="form-label"> Publish</label>
                                                        </div></td>
                                                    </tr>
                                                </table>
                                            </div>
                                            <div className="form-group">
                                                <label className="form-label">Enter About Text</label>
                                                <input
                                                    type="text"
                                                    className="form-control    m-0"
                                                    name="abouttext"
                                                    id=""
                                                    aria-describedby="helpId"
                                                    placeholder="Enter About Text"

                                                    onChange={onChange}
                                                /></div>
                                            <div className="form-group">
                                                <label className="form-label"> Ads Allow</label>
                                                <table width="100%">
                                                    <tr>
                                                        <td><div className="form-check">
                                                            <input type="radio" className="form-check-input" name="adsAllow" value={false} onChange={onChange} />
                                                            <label className="form-label"> no</label>
                                                        </div></td>
                                                        <td><div className="form-check">
                                                            <input type="radio" className="form-check-input" name="adsAllow" value={true} onChange={onChange} />
                                                            <label className="form-label"> yes</label>
                                                        </div></td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    {/* category add section end */}

                                    <div className="mt-3">
                                        <button
                                            type="reset"
                                            className="btn btn-outline-primary me-2 my-btn"
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            type="submit"
                                            className="btn orange-btn btn-primary me-2"
                                            onClick={handleClick}
                                        >
                                            Add
                                        </button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div >

            )
            }



            <div className="content-wrapper">
                <div className="container-fluid flex-grow-1 container-p-y">
                    <div className="fixed-button">
                        <div
                            className="btn btn-sm fixed_button btn-outline-primary"
                            onClick={openData}
                        >
                            + Add User
                        </div>

                    </div>
                    <div className="row">
                        <h4 className="card-header c_heading">
                            <span className="headingcontent">
                                User ({user.length})

                            </span>


                        </h4>

                        <div className="col-lg-12 mb-4 ">
                            <div className="card">
                                <h5 className="card-header">
                                    <div className="row">
                                        <div className="col-lg-6 font-bold">
                                            <ul className="slider_button slider-white plain-orange">
                                                <li
                                                    className={`${statusChange === null ? "slider-active" : ""}`}
                                                    onClick={() => {
                                                        clickBlock(null);
                                                    }}
                                                >All</li>
                                                <li
                                                    className={`${(statusChange !== null && statusChange === false) ? "slider-active" : ""}`}
                                                    onClick={() => {
                                                        clickBlock(false);
                                                    }}
                                                >
                                                    Block
                                                </li>
                                                <li
                                                    className={`${(statusChange !== null && statusChange === true) ? "slider-active" : ""}`}
                                                    onClick={() => {
                                                        clickBlock(true);
                                                    }}
                                                >
                                                    Unblock
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="col-lg-6 text-right">
                                            <input
                                                type="text"
                                                className="form-control search"
                                                placeholder="Search User..."
                                            />
                                        </div>
                                    </div>
                                </h5>

                                <div className="table-responsive text-nowrap mb-1">
                                    <table className="table table-hover">
                                        <thead className="order_history_header">
                                            <tr>
                                                <th>First Name</th>
                                                <th>Last Name</th>
                                                <th>Email</th>
                                                {/*    <th>Phone</th> */}
                                                <th>UType</th>
                                                <th>Country</th>
                                                {/* <th>Date</th>
                                                <th>NextreNew date</th>
                                                <th>Varification Code</th> */}
                                                <th>varified</th>
                                                {/*  <th>Ads Allow</th> */}
                                                {/*  <th>Image Allow</th>
                                                <th>News Letters</th> */}
                                                {/*  <th>Avtar</th> */}
                                                {/*  <th>Signature</th> */}
                                                <th>Status</th>
                                                {/* <th>About Text</th> */}

                                                <th className="action">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody className="table-border-bottom-0 order_history_body">
                                            {user
                                                .filter((user) => {
                                                    return (
                                                        statusChange === null ||
                                                        (user.status === statusChange &&
                                                            (user.fname
                                                                ?.toLowerCase()
                                                                .includes(query?.toLowerCase()) ||
                                                                user.lname
                                                                    ?.toLowerCase()
                                                                    .includes(query?.toLowerCase())))
                                                    );
                                                })
                                                .map((user, index) => {
                                                    return (<tr key={index}>
                                                        <td>{user.fname}</td>
                                                        <td>{user.lname}</td>
                                                        <td>{user.email}</td>
                                                        {/*    <td>{user.phone}</td> */}
                                                        <td>{user.utype}</td>
                                                        <td>{user.country.country}</td>
                                                        {/*  <td>{user.date}</td>
                                                    <td>{user.nextRenewDate}</td>

                                                    <td>{user.varificationCode}</td> */}
                                                        <td>{user.varified ?
                                                            <i className='bx bx-check' onClick={() => {
                                                                changeStatus(user._id, false, "varified")
                                                            }}></i> :
                                                            <i className='bx bx-block' onClick={() => {
                                                                changeStatus(user._id, true, "varified")
                                                            }}></i>}</td>
                                                        {/* <td>{user.adsallow ?
                                                        <i className='bx bx-check' onClick={() => {
                                                            changeStatus(user._id, false, "user")
                                                        }}></i> :
                                                        <i className='bx bx-block' onClick={() => {
                                                            changeStatus(user._id, true, "user")
                                                        }}></i>}</td> */}
                                                        {/* <td>{user.imgallow ?
                                                        <i className='bx bx-check' onClick={() => {
                                                            changeStatus(user._id, false, "user")
                                                        }}></i> :
                                                        <i className='bx bx-block' onClick={() => {
                                                            changeStatus(user._id, true, "user")
                                                        }}></i>}</td> */}
                                                        {/*  <td>{user.newsletters ?
                                                        <i className='bx bx-check' onClick={() => {
                                                            changeStatus(user._id, false, "user")
                                                        }}></i> :
                                                        <i className='bx bx-block' onClick={() => {
                                                            changeStatus(user._id, true, "user")
                                                        }}></i>}</td> */}
                                                        {/*  <td><img className="img-fluid" style={{ height: "100px", width: "100px" }} src={user.avtar} alt="writer_image" /></td> */}
                                                        {/*    <td>{user.signature}</td> */}
                                                        <td>{user.status ?
                                                            <i className='bx bx-check' onClick={() => {
                                                                changeStatus(user._id, false, "status ")
                                                            }}></i> :
                                                            <i className='bx bx-block' onClick={() => {
                                                                changeStatus(user._id, true, "status")
                                                            }}></i>}</td>
                                                        {/* <td>{user.abouttext}</td>
 */}

                                                        <td className="action">

                                                            <div className="dropdown ">
                                                                <i className="bx bx-trash me-1"
                                                                    onClick={() => {
                                                                        deleteUser(user._id)
                                                                    }}></i>

                                                                <i className="bx bx-edit me-1"
                                                                    onClick={() => {
                                                                        openUserEdit(user)
                                                                    }}></i>


                                                            </div></td>
                                                    </tr>)
                                                })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
