import React, { useContext, useEffect, useRef, useState } from "react";
import Choosecategory from "../componant/Media/Choosecategory";
import ChooseMedia from "../componant/Media/ChooseMedia";
import Preloader from "../componant/Preloader";
import VedioCon from "../context/VedioBlog/VedioCon";
import JoditEditor from "jodit-react";
import parse from "html-react-parser";
import UrduKeyboard from "../componant/Urdu/UrduKeyboard";
import HindiKeyboard from "../componant/Hindi/HindiKeyboard";
export const BlogVedio = (props) => {
  const host = process.env.REACT_APP_URL;
  console.log(host);
  const token = localStorage.getItem("token");
  console.log(token);

  const [query, setquery] = useState("");

  const [preloader, setPreloader] = useState("");
  const [media_chooser_type, setmedia_chooser_type] = useState(false);

  const myContext = useContext(VedioCon);
  const {
    blog_vedio,
    addblog_vedio,
    deleteblog_vedio,
    getAllVedio,
    updateblog_vedio,
  } = myContext;

  useEffect(() => {
    getAllVedio();
  }, []);
  const style = {
    background: "#00000080",
    display: "block",
  };
  const [newblog_vedio, setnewblog_vedio] = useState({
    title: "",
    description: "",
    order: "",
    language: localStorage.getItem("lang"),
    status: false,
    media_type: media_chooser_type,
    yurl: "",
    url: "",
    show_on_home: false,
    date: "",
    update_on: "",
  });
  const [addblog_vediomodel, setAddblog_vediomodel] = useState(false);

  const openData = () => {
    setAddblog_vediomodel(!addblog_vediomodel);
  };
  /* jodit start */
  const editor = useRef(null);
  const [content, setContent] = useState("");
  console.log(query);
  /* jodit end */
  const delete_blog_vedio = async (id) => {
    setPreloader("preShow");
    const response = await deleteblog_vedio(id);
    console.log(id);
    if (response.status === "Success") {
      props.showAlert(response.message, "success");
    } else {
      props.showAlert(response.message, "danger");
    }
    setPreloader("");
  };
  const handleClick = async (e) => {
    e.preventDefault();
    newblog_vedio.description = content;
    newblog_vedio.address = address;
    if (
      localStorage.getItem("lang") === "2" ||
      localStorage.getItem("lang") === "1"
    ) {
      newblog_vedio.title = urdudata.title;
    }
    setPreloader("preShow");
    const response = await addblog_vedio(newblog_vedio);

    if (response.status === "success") {
      openData();
      props.showAlert(response.message, "success");
    } else {
      props.showAlert(response.message, "warning");
    }

    setPreloader("");
    console.log(newblog_vedio);
  };

  const onChange = (e) => {
    setnewblog_vedio({ ...newblog_vedio, [e.target.name]: e.target.value });
  };

  const [address, setAddress] = useState({});
  const onAddress = (e) => {
    setAddress({ ...address, [e.target.name]: e.target.value });
  };

  const changeStatus = async (id, status, type) => {
    setPreloader("preShow");
    let response = {};
    if (type === "Show On Home") {
      response = await updateblog_vedio(id, { show_on_home: status });
    } else {
      response = await updateblog_vedio(id, { status: status });
    }
    response.status === "Success"
      ? props.showAlert(`${type}  status is updated`, "success")
      : props.showAlert(response.message, "danger");
    setPreloader("");
  };

  /* update category */

  const onedit = (e) => {
    setupdateBVedio({ ...updateBVedio, [e.target.name]: e.target.value });
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    setPreloader("preShow");
    if (
      localStorage.getItem("lang") === "2" ||
      localStorage.getItem("lang") === "1"
    ) {
      updateBVedio.title = urdudata.title;
    }
    const response = await updateblog_vedio(updateBVedio._id, updateBVedio);

    if (response.status === "Success") {
      openBVeioEdit({});
      props.showAlert(response.message, "success");
    } else {
      props.showAlert(response.message, "warning");
    }
    setPreloader("");
  };
  const [editBVedioModel, seteditBVedioModel] = useState(false);
  const [updateBVedio, setupdateBVedio] = useState({});
  const openBVeioEdit = (cat) => {
    console.log(cat);
    seteditBVedioModel(!editBVedioModel);
    setupdateBVedio(cat);
  };
  const [statusChange, setstatusChange] = useState(null);
  const clickBlock = (status) => {
    setstatusChange(status);
    console.log(statusChange);
    const st = blog_vedio.filter((blog) => {
      return blog.status === statusChange;
    });
    console.log(st);
  };

  const [urdudata, seturdudata] = useState({
    title: "",
  });
  const inputUrdu = (key, value) => {
    urdudata[key] = value;
    console.log("key" + key + " value-" + value);
  };

  const onMediaChange = (e) => {
    e.preventDefault();
    // setmedia_chooser_type()

    let st = false;
    if (e.target.value === "true") {
      st = true;
    }
    setmedia_chooser_type(st);
  };

  //   media modal
  const [mediaModel, setmediaModel] = useState(false);

  const openMedia = async () => {
    setmediaModel(!mediaModel);
    if (!mediaModel) {
      setPreloader("preShow");

      setPreloader("");
    }
  };

  const selectImage = (url) => {
    openMedia();
    if (editBVedioModel) {
      setupdateBVedio({ ...updateBVedio, y_url: url });
    } else {
      setnewblog_vedio({ ...newblog_vedio, y_url: url });
    }
  };

  return (
    <>
      <Preloader show={preloader} />
      {editBVedioModel && (
        <div className="modal reviewModal" style={style}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Update Blog Video
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    openBVeioEdit({});
                  }}
                ></button>
              </div>
              <div className="modal-body">
                <div id="formAccountSettings">
                  <div className="row">
                    <div className="col-md-12  my-1">
                      <div className="form-group">
                        <label className="form-label">Enter Title</label>
                        {localStorage.getItem("lang") === "0" ? (
                          <input
                            type="text"
                            className={`form-control   m-0`}
                            name="title"
                            id=""
                            aria-describedby="helpId"
                            placeholder="Update Writer title"
                            defaultValue={updateBVedio.title}
                            onChange={onedit}
                          />
                        ) : localStorage.getItem("lang") === "1" ? (
                          <HindiKeyboard
                            inputvalue={updateBVedio.title}
                            keyinput="title"
                            onchange={inputUrdu}
                          />
                        ) : localStorage.getItem("lang") === "2" ? (
                          <UrduKeyboard
                            inputvalue={updateBVedio.title}
                            inputUrdu={inputUrdu}
                            inputkey="title"
                          />
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="form-group">
                        <label className="form-label">Enter Discription</label>
                        <JoditEditor
                          ref={editor}
                          value={content}
                          onChange={(newContent) => setContent(newContent)}
                        />
                      </div>
                      <div className="form-group">
                        <label className="form-label">Enter YouTube Url</label>
                        <input
                          type="text"
                          className="form-control    m-0"
                          name="yurl"
                          id=""
                          aria-describedby="helpId"
                          placeholder="Enter YouTube Url"
                          defaultValue={updateBVedio.yurl}
                          onChange={onedit}
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <label className="form-label">Enter Date</label>
                        <input
                          type="date"
                          className="form-control    m-0"
                          name="date"
                          id=""
                          aria-describedby="helpId"
                          placeholder="Enter Date"
                          defaultValue={updateBVedio.date}
                          onChange={onedit}
                        />
                      </div>
                      <div className="form-group">
                        <label className="form-label">Update On</label>
                        <input
                          type="date"
                          className="form-control    m-0"
                          name="update_on"
                          id=""
                          aria-describedby="helpId"
                          placeholder="Update On "
                          defaultValue={updateBVedio.update_on}
                          onChange={onedit}
                        />
                      </div>
                      <div className="form-group">
                        <label className="form-label">Enter Order</label>
                        <input
                          type="text"
                          className="form-control    m-0"
                          name="order"
                          id=""
                          aria-describedby="helpId"
                          placeholder="Enter Order"
                          defaultValue={updateBVedio.order}
                          onChange={onedit}
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <label className="form-label"> Status</label>
                        <table width="100%">
                          <tr>
                            <td>
                              <div className="form-check">
                                <input
                                  type="radio"
                                  className="form-check-input"
                                  name="status"
                                  value={false}
                                  defaultChecked={
                                    updateBVedio.status ? false : true
                                  }
                                  onChange={onChange}
                                />
                                <label className="form-label"> Draft</label>
                              </div>
                            </td>
                            <td>
                              <div className="form-check">
                                <input
                                  type="radio"
                                  className="form-check-input"
                                  name="status"
                                  value={true}
                                  defaultChecked={
                                    updateBVedio.status ? true : false
                                  }
                                  onChange={onChange}
                                />
                                <label className="form-label"> Publish</label>
                              </div>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="form-group">
                        <label className="form-label"> Select Language</label>
                        <table width="100%">
                          <tr>
                            <td>
                              <div className="form-check">
                                <input
                                  type="radio"
                                  className="form-check-input"
                                  name="language"
                                  value={1}
                                  defaultChecked={
                                    updateBVedio.status ? false : true
                                  }
                                  onChange={onedit}
                                />
                                <label className="form-label"> English</label>
                              </div>
                            </td>
                            <td>
                              <div className="form-check">
                                <input
                                  type="radio"
                                  className="form-check-input"
                                  name="language"
                                  value={2}
                                  defaultChecked={
                                    updateBVedio.status ? true : false
                                  }
                                  onChange={onedit}
                                />
                                <label className="form-label"> Urdu</label>
                              </div>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="form-group">
                        <label className="form-label">Enter URL</label>
                        <input
                          type="text"
                          className="form-control    m-0"
                          name="url"
                          id=""
                          aria-describedby="helpId"
                          placeholder="Enter URL"
                          defaultValue={updateBVedio.url}
                          onChange={onedit}
                        />
                      </div>
                      <div className="form-group">
                        <label className="form-label"> Show On Home</label>
                        <table width="100%">
                          <tr>
                            <td>
                              <div className="form-check">
                                <input
                                  type="radio"
                                  className="form-check-input"
                                  name="show_on_home"
                                  value={false}
                                  defaultChecked={
                                    updateBVedio.status ? false : true
                                  }
                                  onChange={onedit}
                                />
                                <label className="form-label"> Show</label>
                              </div>
                            </td>
                            <td>
                              <div className="form-check">
                                <input
                                  type="radio"
                                  className="form-check-input"
                                  name="show_on_home"
                                  value={true}
                                  defaultChecked={
                                    updateBVedio.status ? true : false
                                  }
                                  onChange={onedit}
                                />
                                <label className="form-label"> Hide</label>
                              </div>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                  {/* category add section end */}

                  <div className="mt-3">
                    <button
                      type="reset"
                      className="btn btn-outline-primary me-2 my-btn"
                      onClick={() => {
                        openBVeioEdit({});
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="btn orange-btn btn-primary me-2"
                      onClick={handleUpdate}
                    >
                      Update
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {addblog_vediomodel && (
        <div className="modal reviewModal" style={style}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Add New Blog Video
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={openData}
                ></button>
              </div>
              <div className="modal-body">
                <div
                  id="formAccountSettings"
                  method="POST"
                  onSubmit={handleClick}
                >
                  <div className="row">
                    <div className="col-md-12  my-1">
                      <div className="form-group">
                        <label className="form-label">Enter Title</label>
                        {localStorage.getItem("lang") === "0" ? (
                          <input
                            type="text"
                            className={`form-control   m-0`}
                            name="title"
                            id=""
                            aria-describedby="helpId"
                            placeholder="Enter Title"
                            onChange={onChange}
                          />
                        ) : localStorage.getItem("lang") === "1" ? (
                          <HindiKeyboard
                            keyinput="title"
                            onchange={inputUrdu}
                          />
                        ) : localStorage.getItem("lang") === "2" ? (
                          <UrduKeyboard
                            inputUrdu={inputUrdu}
                            inputkey="title"
                          />
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="form-group">
                        <label className="form-label">Enter Discription</label>
                        <JoditEditor
                          ref={editor}
                          value={content}
                          onChange={(newContent) => setContent(newContent)}
                        />
                      </div>

                      <div className="row mt-2">
                        <div className="col-6">
                          <div className="form-group">
                            <label className="form-label"> Media Type</label>
                            <table width="100%">
                              <tr>
                                <td>
                                  <div className="form-check">
                                    <input
                                      type="radio"
                                      className="form-check-input"
                                      name="media_type"
                                      value={true}
                                      onChange={onMediaChange}
                                    />
                                    <label className="form-label">
                                      {" "}
                                      Youtube
                                    </label>
                                  </div>
                                </td>
                                <td>
                                  <div className="form-check">
                                    <input
                                      type="radio"
                                      className="form-check-input"
                                      name="media_type"
                                      value={false}
                                      onChange={onMediaChange}
                                    />
                                    <label className="form-label"> Media</label>
                                  </div>
                                </td>
                              </tr>
                            </table>
                          </div>
                        </div>
                        <div className="col-6">
                        {media_chooser_type ? (
                        <div className="form-group">
                          <label className="form-label">
                            Enter YouTube Url
                          </label>
                          <input
                            type="text"
                            className="form-control    m-0"
                            name="yurl"
                            id=""
                            aria-describedby="helpId"
                            placeholder="Enter YouTube Url"
                            onChange={onChange}
                          />
                        </div>
                      ) : (
                        <>
                          <div className="form-group">
                            <label className="form-label">Choose Media</label>

                            <div className="" onClick={openMedia}>
                              <img
                                className="img-fluid"
                                src={
                                  newblog_vedio.yurl === "" ||
                                  newblog_vedio.yurl === undefined
                                    ? "./assets/img/illustrations/upload.png"
                                    : newblog_vedio.yurl
                                }
                                alt="selected_image"
                                width={100}
                              />
                            </div>
                          </div>
                        </>
                      )}
                        </div>
                      </div>

                     
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <label className="form-label">Enter Date</label>
                        <input
                          type="date"
                          className="form-control  mb-2  m-0"
                          name="date"
                          id=""
                          aria-describedby="helpId"
                          placeholder="Enter Date"
                          onChange={onChange}
                        />
                      </div>
                      <div className="form-group">
                        <label className="form-label">Update On</label>
                        <input
                          type="date"
                          className="form-control  mb-2  m-0"
                          name="update_on"
                          id=""
                          aria-describedby="helpId"
                          placeholder="Update On "
                          onChange={onChange}
                        />
                      </div>
                      <div className="form-group">
                        <label className="form-label">Enter Order</label>
                        <input
                          type="text"
                          className="form-control  mb-2  m-0"
                          name="order"
                          id=""
                          aria-describedby="helpId"
                          placeholder="Enter Order"
                          onChange={onChange}
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <label className="form-label"> Status</label>
                        <table width="100%" className="form-radio-table">
                          <tr>
                            <td>
                              <div className="form-check">
                                <input
                                  type="radio"
                                  className="form-check-input"
                                  name="status"
                                  value={false}
                                  onChange={onChange}
                                />
                                <label className="form-label"> Draft</label>
                              </div>
                            </td>
                            <td>
                              <div className="form-check">
                                <input
                                  type="radio"
                                  className="form-check-input"
                                  name="status"
                                  value={true}
                                  onChange={onChange}
                                />
                                <label className="form-label"> Publish</label>
                              </div>
                            </td>
                          </tr>
                        </table>
                      </div>
                      
                      <div className="form-group mt-2">
                        <label className="form-label">Enter URL</label>
                        <input
                          type="text"
                          className="form-control  mb-2  m-0"
                          name="url"
                          id=""
                          aria-describedby="helpId"
                          placeholder="Enter URL"
                          onChange={onChange}
                        />
                      </div>
                      <div className="form-group">
                        <label className="form-label"> Show On Home</label>
                        <table width="100%" className="form-radio-table">
                          <tr>
                            <td>
                              <div className="form-check">
                                <input
                                  type="radio"
                                  className="form-check-input"
                                  name="show_on_home"
                                  value={false}
                                  onChange={onChange}
                                />
                                <label className="form-label"> Show</label>
                              </div>
                            </td>
                            <td>
                              <div className="form-check">
                                <input
                                  type="radio"
                                  className="form-check-input"
                                  name="show_on_home"
                                  value={true}
                                  onChange={onChange}
                                />
                                <label className="form-label"> Hide</label>
                              </div>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                  {/* category add section end */}

                  <div className="mt-3">
                    <button
                      type="reset"
                      className="btn btn-outline-primary me-2 my-btn"
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="btn orange-btn btn-primary me-2"
                      onClick={handleClick}
                    >
                      Add
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {mediaModel && (
        <ChooseMedia
          open={openMedia}
          selectImage={selectImage}
          showAlert={props.showAlert}
        />
      )}
      <div className="content-wrapper">
        <div className="container-fluid flex-grow-1 container-p-y">
          <div className="fixed-button">
            <div
              className={`btn btn-sm fixed_button btn-outline-primary ${
                addblog_vediomodel ? "d-none" : "d-block"
              }`}
              onClick={openData}
            >
              + Add Blog Video
            </div>
          </div>
          <div className="row">
            <h4 className="card-header c_heading">
              <span className="headingcontent">
                Blog Video ({blog_vedio.length})
              </span>
            </h4>

            <div className="col-lg-12 mb-4 ">
              <div class="card">
                <h5 class="card-header">
                  <div className="row">
                    <div className="col-lg-6 font-bold">
                      <ul className="slider_button slider-white plain-orange">
                        <li
                          className={`${
                            statusChange === null ? "slider-active" : ""
                          }`}
                          onClick={() => {
                            clickBlock(null);
                          }}
                        >
                          All
                        </li>
                        <li
                          className={`${
                            statusChange !== null && statusChange === false
                              ? "slider-active"
                              : ""
                          }`}
                          onClick={() => {
                            clickBlock(false);
                          }}
                        >
                          Block
                        </li>
                        <li
                          className={`${
                            statusChange !== null && statusChange === true
                              ? "slider-active"
                              : ""
                          }`}
                          onClick={() => {
                            clickBlock(true);
                          }}
                        >
                          Unblock
                        </li>
                      </ul>
                    </div>
                    <div className="col-lg-6 text-right">
                      <input
                        type="text"
                        className="form-control search"
                        placeholder="Search Blog Video..."
                        onChange={(e) => {
                          setquery(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </h5>

                <div class="table-responsive text-nowrap mb-1">
                  <table class="table table-hover">
                    <thead className="order_history_header">
                      <tr>
                        <th>Title</th>
                        {/*   <th>Discription</th> */}
                        <th>Date</th>
                        {/*   <th>Update On</th> */}
                        <th>Order</th>
                        {/*   <th>Language</th> */}
                        <th>Status</th>
                        {/*  <th>YouTube Url</th>
                                                <th>URL</th>
                                                <th>Show on Home</th> */}

                        <th className="action">Action</th>
                      </tr>
                    </thead>
                    <tbody class="table-border-bottom-0 order_history_body">
                      {blog_vedio
                        .filter((blog_vedio) => {
                          return (
                            (statusChange === null ||
                              blog_vedio.status === statusChange) &&
                            blog_vedio.title
                              ?.toLowerCase()
                              .includes(query?.toLowerCase())
                          );
                        })
                        .map((blog_vedio, index) => {
                          return (
                            <tr key={index}>
                              <td>{blog_vedio.title}</td>
                              {/*    <td>{parse(blog_vedio.description)}</td> */}
                              <td>{blog_vedio.date}</td>
                              {/*    <td>{blog_vedio.update_on}</td> */}
                              <td>{blog_vedio.order}</td>
                              {/*   <td>{blog_vedio.language}</td> */}
                              <td>
                                {blog_vedio.status ? (
                                  <i
                                    className="bx bx-check"
                                    onClick={() => {
                                      changeStatus(
                                        blog_vedio._id,
                                        false,
                                        "blog_vedio"
                                      );
                                    }}
                                  ></i>
                                ) : (
                                  <i
                                    className="bx bx-block"
                                    onClick={() => {
                                      changeStatus(
                                        blog_vedio._id,
                                        true,
                                        "blog_vedio"
                                      );
                                    }}
                                  ></i>
                                )}
                              </td>
                              {/*   <td>{blog_vedio.yurl}</td>
                                                        <td>{blog_vedio.url}</td>
                                                        <td>{blog_vedio.language}</td>
                                                        <td>{blog_vedio.show_on_home ?
                                                            <i className='bx bx-check' onClick={() => {
                                                                changeStatus(blog_vedio._id, false, "Show On Home")
                                                            }}></i> :
                                                            <i className='bx bx-block' onClick={() => {
                                                                changeStatus(blog_vedio._id, true, "Show On Home")
                                                            }}></i>}</td>
 */}

                              <td className="action">
                                <div className="dropdown ">
                                  <i
                                    className="bx bx-trash me-1"
                                    onClick={() => {
                                      delete_blog_vedio(blog_vedio._id);
                                    }}
                                  ></i>

                                  <i
                                    className="bx bx-edit me-1"
                                    onClick={() => {
                                      openBVeioEdit(blog_vedio);
                                    }}
                                  ></i>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
