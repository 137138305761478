import React, { useState } from "react";
import EventContext from "./EventContext";
/* require("dotenv").config(); */
const EventState = (props) => {
  const host = process.env.REACT_APP_URL;
  console.log(host);
  const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7ImlkIjoiNjNkZjg4YWFhNDllYjE2NzFjYTdkZGNhIn0sImlhdCI6MTY3NTU5NTEzN30.xGYO_zIQPGR5YvcYhQFDlInCydDomn6Lrsqv6bZmrMQ";
  console.log(token);


  const [event, setEvent] = useState([]);

   const createEvent = async (body) => {
    try {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "auth-token": token
        }, body: JSON.stringify(body)
      };
      const response = await fetch(
        `${host}/api/event/createevent`,
        requestOptions
      );
      const dataresp = await response.json();
      console.log(dataresp);
      if (dataresp.status === "success") {
        setEvent(event.concat(dataresp.data))
       
      }
    
      return dataresp

    }
    catch (e) {

    }
  } 
  const deleteEvent = async (id) => {
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "auth-token": token
      },
    };
    const response = await fetch(
      `${host}/api/event/deleteevent/${id}`,
      requestOptions
    );
    const datares = await response.json();

    console.log(datares);
    setEvent(datares.data);
    if(datares.status=== "Success"){
      const updateData = event.filter((cat)=>{
        return cat._id!==id

      })
      setEvent(updateData)
    }
    return datares
  };
  const getAllEvent = async (id) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "auth-token": token
      },
    };
    const response = await fetch(
      `${host}/api/event/getEvent`,
      requestOptions
    );
    const datares = await response.json();

    console.log(datares);
    setEvent(datares.data);
  };
  const updateEvent = async (body, id) => {
    try {
      const requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "auth-token": token
        }, body: JSON.stringify(body)
      };
      const response = await fetch(
        `${host}/api/event/updateevent/${id}`,
        requestOptions
      );
      const dataresp = await response.json();
      console.log(dataresp);
      if (dataresp.status === "Success") {

        for(let i = 0; i < event.length; i++){
          let element = event[i];
          if(element._id.toString() === id){
            event[i] = dataresp.data;
          }
        }
        setEvent(event);
      }
      return dataresp;

    }
    catch (e) {

    }
  }

  return (
    <EventContext.Provider
      value={{
        event,
        getAllEvent,
        deleteEvent,
        createEvent,
        updateEvent
       
      }}

    >
      {props.children}
    </EventContext.Provider>
  );
}
export default EventState;