import React, { useState } from "react";
import BannerCon from "./BannerCon";

const BannerState = (props) => {
  const host = process.env.REACT_APP_URL;
  console.log(host);
  const token = localStorage.getItem("token");
  console.log(token);

  const [banner, setbanner] = useState([]);

  const addbanner = async (body) => {
    console.log(body);
    try {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "auth-token": token,
        },
        body: JSON.stringify(body),
      };
      const response = await fetch(
        `${host}/api/banner/addbanner`,
        requestOptions
      );
      const dataresp = await response.json();
      // console.log(dataresp);
      if (dataresp.status === "success") {
        setbanner(banner.concat(dataresp.data));
      }
      return dataresp;
    } catch (e) {
      console.log(e);
    }
  };
  const deletebanner = async (id) => {
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "auth-token": token,
      },
    };
    const response = await fetch(
      `${host}/api/banner/deletebanner/${id}`,
      requestOptions
    );
    const datares = await response.json();

    console.log(datares);

    if (datares.status === "Success") {
      const updateData = banner.filter((pol) => {
        return pol._id !== id;
      });
      setbanner(updateData);
    }
    return datares;
  };
  const getAllbanner = async (type) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "auth-token": token,
      },
    };
    const response = await fetch(
      `${host}/api/banner/getBanner`,
      requestOptions
    );
    const datares = await response.json();

    console.log(datares);
    if (datares.status === "Success") {
      setbanner(datares.data);
    }
  };

  const updateBanner = async (id, body) => {
    try {
      const requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "auth-token": token,
        },
        body: JSON.stringify(body),
      };
      const response = await fetch(
        `${host}/api/banner/updateBanner/${id}`,
        requestOptions
      );
      const dataresp = await response.json();
      if (dataresp.status === "Success") {
        for (let i = 0; i < banner.length; i++) {
          let element = banner[i];
          if (element._id.toString() === id) {
            banner[i] = dataresp.data;
          }
        }
        setbanner(banner);
      }
      return dataresp;
    } catch (e) {}
  };

  return (
    <BannerCon.Provider
      value={{
        banner,
        getAllbanner,
        addbanner,
        deletebanner,
        updateBanner,
      }}
    >
      {props.children}
    </BannerCon.Provider>
  );
};
export default BannerState;
