import React, { useState } from "react";
import notiCon from "./NotiCon";
/* require("dotenv").config(); */
const NotiState = (props) => {
  const host = process.env.REACT_APP_URL;
  console.log(host);
  const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7ImlkIjoiNjNkZjg4YWFhNDllYjE2NzFjYTdkZGNhIn0sImlhdCI6MTY3NTU5NTEzN30.xGYO_zIQPGR5YvcYhQFDlInCydDomn6Lrsqv6bZmrMQ";
  console.log(token);


  const [notification, setNotification] = useState([]);

   const createNotification = async (body) => {
    try {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "auth-token": token
        }, body: JSON.stringify(body)
      };
      const response = await fetch(
        `${host}/api/notification/addnotification`,
        requestOptions
      );
      const dataresp = await response.json();
      console.log(dataresp);
      if (dataresp.status === "success") {
        setNotification(notification.concat(dataresp.data))
        return true
      }
      else {
        return false
      }

    }
    catch (e) {

    }
  } 
  const deleteNotication = async (id) => {
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "auth-token": token
      },
    };
    const response = await fetch(
      `${host}/api/notification/deletenotification/${id}`,
      requestOptions
    );
    const datares = await response.json();

    console.log(datares);
    setNotification(datares.data);
    if(datares.status=== "Success"){
      const updateData = notification.filter((cat)=>{
        return notification._id!==id

      })
      setNotification(updateData)
    }
  };
  const getAllNotification = async (id) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "auth-token": token
      },
    };
    const response = await fetch(
      `${host}/api/notification/getnoti`,
      requestOptions
    );
    const datares = await response.json();

    console.log(datares);
    setNotification(datares.data);
  };


  return (
    <notiCon.Provider
      value={{
        notification,
        getAllNotification,
        deleteNotication,
        createNotification
       
      }}

    >
      {props.children}
    </notiCon.Provider>
  );
}
export default NotiState;