import React, { useContext, useEffect, useState } from "react";
import ChooseMedia from "../componant/Media/ChooseMedia";

import Preloader from "../componant/Preloader";
import WriterCon from "../context/Writer/WriterCon";
import UrduKeyboard from "../componant/Urdu/UrduKeyboard";
import HindiKeyboard from "../componant/Hindi/HindiKeyboard";

export const Writer_info = (props) => {
  const [preloader, setPreloader] = useState("");

  const myContext = useContext(WriterCon);
  const {
    writer_info,
    getAllWriter_info,
    addWriter_info,
    deleteWriter_info,
    updateWriter,
  } = myContext;

  useEffect(() => {
    getAllWriter_info();
  }, []);

  /* search */
  const [query, setquery] = useState("");
  /* search end */
  const style = {
    background: "#00000080",
    display: "block",
  };
  const [newWriter_info, setnewWriter_info] = useState({
    name: "",
    language: localStorage.getItem("lang"),
  });
  const [addWriter_infoModal, setAddWriter_infoModal] = useState(false);
  const openData = () => {
    setAddWriter_infoModal(!addWriter_infoModal);
  };

  const delete_writerinfo = async (id) => {
    setPreloader("preShow");
    const response = await deleteWriter_info(id);
    console.log(id);
    if (response.status === "Success") {
      props.showAlert(response.message, "success");
    } else {
      props.showAlert(response.message, "danger");
    }
    setPreloader("");
  };
  const handleClick = async (e) => {
    e.preventDefault();
    setPreloader("preShow");
    if (
      localStorage.getItem("lang") === "2" ||
      localStorage.getItem("lang") === "1"
    ) {
      newWriter_info.name = urdudata.name;
      newWriter_info.intro = urdudata.intro;
      newWriter_info.location = urdudata.location;
    }

    const response = await addWriter_info(newWriter_info);
    console.log(newWriter_info);
    if (response.status === "success") {
      openData();
      props.showAlert(response.message, "success");
    } else {
      props.showAlert(response.message, "warning");
    }
    setPreloader("");
  };

  const onChange = (e) => {
    setnewWriter_info({ ...newWriter_info, [e.target.name]: e.target.value });
  };
  const [mediaModel, setMediaModel] = useState(false);
  const openMedia = () => {
    setMediaModel(!mediaModel);
  };
  const selectImage = (url) => {
    openMedia();
    setnewWriter_info({ ...newWriter_info, image: url });
  };

  /* update section */
  const onedit = (e) => {
    setupdateWri({ ...updateWri, [e.target.name]: e.target.value });
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    setPreloader("preShow");
    if (
      localStorage.getItem("lang") === "2" ||
      localStorage.getItem("lang") === "1"
    ) {
      updateWri.name = urdudata.name;
      updateWri.intro = urdudata.intro;
      updateWri.location = urdudata.location;
    }
    const response = await updateWriter(updateWri, updateWri._id);

    if (response.status === "Success") {
      openWriterEdit({});
      props.showAlert(response.message, "success");
    } else {
      props.showAlert(response.message, "warning");
    }
    setPreloader("");
  };

  const [editWriterModel, seteditCatModel] = useState(false);
  const [updateWri, setupdateWri] = useState({});
  const openWriterEdit = (writer) => {
    seteditCatModel(!editWriterModel);
    setupdateWri(writer);
  };
  const [urdudata, seturdudata] = useState({
    name: "",
    intro: "",
    location: "",
  });
  const inputUrdu = (key, value) => {
    urdudata[key] = value;
  };

  return (
    <>
      <Preloader show={preloader} />

      {editWriterModel && (
        <div className="modal reviewModal" style={style}>
          <div className="modal-dialog modal-xl">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Edit Writer
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    openWriterEdit({});
                  }}
                ></button>
              </div>
              <div className="modal-body">
                <form id="formAccountSettings">
                  <div className="row">
                    {/* category add section */}
                    <div className="col-md-12  my-1">
                      <form method="post">
                        <div className="form-group">
                          <label className="form-label">
                            Enter Writer Name
                          </label>
                          {localStorage.getItem("lang") === "0" ? (
                            <input
                              type="text"
                              className={`form-control   m-0`}
                              name="name"
                              id=""
                              aria-describedby="helpId"
                              placeholder="Update Writer Name"
                              defaultValue={updateWri.name}
                              onChange={onedit}
                            />
                          ) : localStorage.getItem("lang") === "1" ? (
                            <HindiKeyboard
                              inputvalue={updateWri.name}
                              keyinput="name"
                              onchange={inputUrdu}
                            />
                          ) : localStorage.getItem("lang") === "2" ? (
                            <UrduKeyboard
                              inputvalue={updateWri.name}
                              inputUrdu={inputUrdu}
                              inputkey="name"
                            />
                          ) : (
                            ""
                          )}
                          <label className="form-label">Update Location</label>
                          {localStorage.getItem("lang") === "0" ? (
                            <input
                              type="text"
                              className={`form-control   m-0`}
                              name="location"
                              id=""
                              aria-describedby="helpId"
                              placeholder="Update Location"
                              defaultValue={updateWri.location}
                              onChange={onedit}
                            />
                          ) : localStorage.getItem("lang") === "1" ? (
                            <HindiKeyboard
                              inputvalue={updateWri.location}
                              keyinput="location"
                              onchange={inputUrdu}
                            />
                          ) : localStorage.getItem("lang") === "2" ? (
                            <UrduKeyboard
                              inputvalue={updateWri.location}
                              inputUrdu={inputUrdu}
                              inputkey="location"
                            />
                          ) : (
                            ""
                          )}
                          <label className="form-label">
                            Enter Introduction
                          </label>
                          {localStorage.getItem("lang") === "0" ? (
                            <input
                              type="text"
                              className={`form-control   m-0`}
                              name="intro"
                              id=""
                              aria-describedby="helpId"
                              placeholder="Update Introduction"
                              defaultValue={updateWri.intro}
                              onChange={onedit}
                            />
                          ) : localStorage.getItem("lang") === "1" ? (
                            <HindiKeyboard
                              inputvalue={updateWri.intro}
                              keyinput="intro"
                              onchange={inputUrdu}
                            />
                          ) : localStorage.getItem("lang") === "2" ? (
                            <UrduKeyboard
                              inputvalue={updateWri.intro}
                              inputUrdu={inputUrdu}
                              inputkey="intro"
                            />
                          ) : (
                            ""
                          )}
                          <div className="col-6">
                            <label className="form-label">Choose Media</label>
                            <div className="" onClick={openMedia}>
                              <img
                                className="img-fluid"
                                src={
                                  updateWri.image === undefined ||
                                  updateWri.image === ""
                                    ? "./assets/img/illustrations/upload.png"
                                    : updateWri.image
                                }
                                alt="writer_image"
                                width={150}
                              />
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                    {/* category add section end */}
                  </div>
                  <div className="mt-3">
                    <button
                      type="reset"
                      className="btn btn-outline-primary me-2 my-btn"
                      onClick={() => {
                        openWriterEdit({});
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="btn orange-btn btn-primary me-2"
                      onClick={handleUpdate}
                    >
                      Update
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
      {addWriter_infoModal && (
        <div className="modal " style={style}>
          <div className="modal-dialog" style={{ width: "40%" }}>
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Add New Writer
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={openData}
                ></button>
              </div>
              <div className="modal-body">
                <form
                  id="formAccountSettings"
                  method="POST"
                  onSubmit={handleClick}
                >
                  <div className="row">
                    {/* category add section */}
                    <div className="col-md-12  my-1">
                      <div className="form-group">
                        <label className="form-label">Enter Name</label>
                        {localStorage.getItem("lang") === "0" ? (
                          <input
                            type="text"
                            className={`form-control   m-0`}
                            name="name"
                            id=""
                            aria-describedby="helpId"
                            placeholder="Enter writer name"
                            onChange={onChange}
                          />
                        ) : localStorage.getItem("lang") === "1" ? (
                          <HindiKeyboard keyinput="name" onchange={inputUrdu} />
                        ) : localStorage.getItem("lang") === "2" ? (
                          <UrduKeyboard inputUrdu={inputUrdu} inputkey="name" />
                        ) : (
                          ""
                        )}
                        <label className="form-label">Enter location</label>
                        {localStorage.getItem("lang") === "0" ? (
                          <input
                            type="text"
                            className={`form-control   m-0`}
                            name="location"
                            id=""
                            aria-describedby="helpId"
                            placeholder="Enter location"
                            onChange={onChange}
                          />
                        ) : localStorage.getItem("lang") === "1" ? (
                          <HindiKeyboard
                            keyinput="location"
                            onchange={inputUrdu}
                          />
                        ) : localStorage.getItem("lang") === "2" ? (
                          <UrduKeyboard
                            inputUrdu={inputUrdu}
                            inputkey="location"
                          />
                        ) : (
                          ""
                        )}
                        <label className="form-label">Enter Introduction</label>
                        {localStorage.getItem("lang") === "0" ? (
                          <input
                            type="text"
                            className={`form-control   m-0`}
                            name="intro"
                            id=""
                            aria-describedby="helpId"
                            placeholder="Enter writer intro..."
                            onChange={onChange}
                          />
                        ) : localStorage.getItem("lang") === "1" ? (
                          <HindiKeyboard
                            keyinput="intro "
                            onchange={inputUrdu}
                          />
                        ) : localStorage.getItem("lang") === "2" ? (
                          <UrduKeyboard
                            inputUrdu={inputUrdu}
                            inputkey="intro"
                          />
                        ) : (
                          ""
                        )}
                        <div className="col-6">
                          <label className="form-label">Choose Media</label>
                          <div className="" onClick={openMedia}>
                            <img
                              className="img-fluid"
                              src={
                                newWriter_info.image === undefined ||
                                newWriter_info.image === ""
                                  ? "./assets/img/illustrations/upload.png"
                                  : newWriter_info.image
                              }
                              alt="writer_image"
                              width={150}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* category add section end */}
                  </div>
                  <div className="mt-3">
                    <button
                      type="reset"
                      className="btn btn-outline-primary me-2 my-btn"
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="btn orange-btn btn-primary me-2"
                      onClick={handleClick}
                    >
                      Add
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}

      {mediaModel && <ChooseMedia open={openMedia} selectImage={selectImage} />}

      <div className="content-wrapper">
        <div className="container-fluid flex-grow-1 container-p-y">
          <div className="fixed-button">
            <div
              className={`btn btn-sm fixed_button btn-outline-primary ${
                addWriter_infoModal ? "d-none" : "d-block"
              }`}
              onClick={openData}
            >
              + Add Writer
            </div>
          </div>
          <div className="row">
            <h4 className="card-header c_heading">
              <span className="headingcontent">
                Writers ({writer_info.length})
              </span>
            </h4>

            <div className="col-lg-12 mb-4 ">
              <div className="card">
                <h5 className="card-header">
                  <div className="row">
                    <div className="col-lg-6 font-bold">
                      <ul className="slider_button slider-white plain-orange">
                        <li className="slider-active">Writers</li>
                      </ul>
                    </div>
                    <div className="col-lg-6 text-right">
                      <input
                        type="text"
                        className="form-control search"
                        placeholder="Search Writer..."
                        onChange={(e) => {
                          setquery(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </h5>

                <div className="table-responsive text-nowrap mb-1">
                  <table className="table table-hover">
                    <thead className="order_history_header">
                      <tr>
                        <th className="action">Action</th>
                        <th>ID</th>
                        <th>Name</th>
                        <th>Location</th>

                        <th>Language</th>
                      </tr>
                    </thead>
                    <tbody className="table-border-bottom-0 order_history_body">
                      {writer_info
                        .filter((writer) => {
                          return writer.name
                            .toLowerCase()
                            .includes(query.toLowerCase());
                        })
                        .map((writer, index) => {
                          return (
                            <tr key={index}>
                              <td className="action">
                                <div className="dropdown ">
                                  <span className="badge bg-danger me-1">
                                    <i
                                      className="bx bx-trash "
                                      onClick={() => {
                                        delete_writerinfo(writer._id);
                                      }}
                                    ></i>
                                  </span>
                                  <span className="badge bg-secondary">
                                    <i
                                      className="bx bx-edit "
                                      onClick={() => {
                                        openWriterEdit(writer);
                                      }}
                                    ></i>
                                  </span>
                                </div>
                              </td>
                              <th>{writer.id}</th>
                              <td>{writer.name}</td>
                              <td>{writer.location}</td>

                              <td>{writer.language}</td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
