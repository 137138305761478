import React from "react";

const DataTableFooter = ({
  col = 1,
  total_item,
  optionChange,
  prePage,
  nextPage,
  range = "1-10",
}) => {
  return (
    <tfoot>
      <tr>
        <td colSpan={col}>
          <div className="row align-items-center">
            <div className="col-6">Showing {range} of {total_item} entries</div>
            <div className="col-6 text-right d-flex align-items-center justify-content-end">
              <div className="d-flex justify-content-center align-items-center me-4">
                <span className="me-2">Rows per page: </span>
                <select
                  className="form-control "
                  style={{ width: "fit-content" }}
                  onChange={optionChange}
                >
                  <option value={10}>10</option>
                  <option value={25}>25</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                </select>
              </div>
              <span>
                {range} of {total_item}
              </span>
              <div className="">
                <span
                  className="badge bg-secondary mx-3"
                  onClick={() => {
                    prePage();
                  }}
                >
                  <i className="fa fa-less-than"></i>
                </span>
                <span
                  className="badge bg-secondary"
                  onClick={() => {
                    nextPage();
                  }}
                >
                  <i className="fa fa-greater-than"></i>
                </span>
              </div>
            </div>
          </div>
        </td>
      </tr>
    </tfoot>
  );
};

export default DataTableFooter;
