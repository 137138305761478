import React, { useState } from "react";
import blogPostCon from "./Blog-PostCon";
/* require("dotenv").config(); */
const BlogPostState = (props) => {
  const host = process.env.REACT_APP_URL;
  console.log(host);
  const token = localStorage.getItem("token");
  console.log(token);


  const [blog_post, setBlog_post] = useState([]);



  const addBlogPost = async (body) => {
    try {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "auth-token": token
        }, body: JSON.stringify(body)
      };
      const response = await fetch(
        `${host}/api/blog_post/addBlogPost`,
        requestOptions
      );
      const dataresp = await response.json();
      console.log(dataresp);
      if (dataresp.status === "success") {
        setBlog_post(blog_post.concat(dataresp.data))
      }
      return dataresp;

    }
    catch (e) {

    }
  }
  const delete_BlogPost = async (id) => {
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "auth-token": token
      },
    };
    const response = await fetch(
      `${host}/api/blog_post/deleteBlogPost/${id}`,
      requestOptions
    );
    const datares = await response.json();

    console.log(datares);
    if (datares.status === "Success") {
      const updateData = blog_post.filter((bpost) => {
        return bpost._id !== id

      })
      setBlog_post(updateData)
    }
  };
  const get_BlogPost = async (type) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "auth-token": token
      },
    };
    const response = await fetch(
      `${host}/api/blog_post/getBlogPost`,
      requestOptions
    );
    const datares = await response.json();

    console.log(datares);
    if (datares.status === "Success") {


      setBlog_post(datares.data);
    }


  };

  const updateBlogOrder = async (id, type) => {
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "auth-token": token
      },
    };
    const response = await fetch(
      `${host}/api/blog_post/updateOrder/${id}/${type}`,
      requestOptions
    );
    const datares = await response.json();
    if (datares.status === "success") {

      for (let i = 0; i < blog_post.length; i++) {
        let element = blog_post[i];
        for (let j = 0; j < datares.data.length; j++) {
          let ule = datares.data[j]
          if (element._id.toString() === ule._id.toString()) {
            blog_post[i] = ule;
          }
        }

      }
      setBlog_post(blog_post);
      
    }
    return datares;


    console.log(datares);
  }
  const updateBlogPost = async (id, body) => {
    console.log(body);
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "auth-token": token
      }, body: JSON.stringify(body)
    };
    const response = await fetch(
      `${host}/api/blog_post/updateBlogPost/${id}`,
      requestOptions
    );
    const datares = await response.json();
    if (datares.status === "Success") {

      for (let i = 0; i < blog_post.length; i++) {
        let element = blog_post[i];
        if (element._id.toString() === id) {
          blog_post[i] = datares.data;
        }
      }
      setBlog_post(blog_post);
    }
    return datares;


    console.log(datares);
  }

  return (
    <blogPostCon.Provider
      value={{
        blog_post,
        get_BlogPost,
        addBlogPost,
        delete_BlogPost,
        updateBlogPost,
        updateBlogOrder

      }}

    >
      {props.children}
    </blogPostCon.Provider>
  );
}
export default BlogPostState;