import React, { useState } from "react";
import postCon from "./PostContext";
/* require("dotenv").config(); */
const PostState = (props) => {
  const host = process.env.REACT_APP_URL;
  console.log(host);
  const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7ImlkIjoiNjNkZjg4YWFhNDllYjE2NzFjYTdkZGNhIn0sImlhdCI6MTY3NTU5NTEzN30.xGYO_zIQPGR5YvcYhQFDlInCydDomn6Lrsqv6bZmrMQ";
  console.log(token);


  const [post, setPost] = useState([]);

  const createPost = async (body) => {
    try {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "auth-token": token
        }, body: JSON.stringify(body)
      };
      const response = await fetch(
        `${host}/api/post/createpost`,
        requestOptions
      );
      const dataresp = await response.json();
      console.log(dataresp);
      if (dataresp.status === "success") {
        setPost(post.concat(dataresp.data))
        return true
      }
      else {
       
        return dataresp;
      }
     
    }
    catch (e) {

    }
  }
  const deletePost = async (id) => {
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "auth-token": token
      },
    };
    const response = await fetch(
      `${host}/api/post/deletepost/${id}`,
      requestOptions
    );
    const datares = await response.json();

    console.log(datares);
    setPost(datares.data);
    if (datares.status === "Success") {
      const updateData = post.filter((cat) => {
        return cat._id !== id

      })
      setPost(updateData)
    }
    return datares
  };
  const getAllPost = async (id) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "auth-token": token
      },
    };
    const response = await fetch(
      `${host}/api/post/getPost`,
      requestOptions
    );
    const datares = await response.json();

    console.log(datares);
    setPost(datares.data);
  };
  const updatePost = async (id, body) => {
    console.log(body);
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "auth-token": token
      }, body: JSON.stringify(body)
    };
    const response = await fetch(
      `${host}/api/post/updatepost/${id}`,
      requestOptions
    );
    const datares = await response.json();
    if (datares.status === "Success") {
      const data = post.map((b => {
        return (b._id === id) ? datares.data : b

      }))
      console.log(data
      );
      setPost(data)
    }
    return (datares)

    console.log(datares);
  }


  return (
    <postCon.Provider
      value={{
        post,
        getAllPost,
        deletePost,
        createPost,
        updatePost

      }}

    >
      {props.children}
    </postCon.Provider>
  );
}
export default PostState;