import React from "react";


import { Link, NavLink, useNavigate } from "react-router-dom";

export default function NavbarData() {
  let location = useNavigate();



  return (
    <>
      <aside
        id="layout-menu"
        className="layout-menu menu-vertical menu bg-menu-theme"
      >
        <div className="app-brand demo">
          <Link to="/" className="app-brand-link">
            <span className="app-brand-logo demo">
              {/* <img src={logo} alt="logo" width="170" /> */}
            </span>
            <span className="app-brand-text demo menu-text fw-bolder ms-2">
              THE ASIANS
            </span>
          </Link>

          <Link
            to="/"
            className="layout-menu-toggle menu-link text-large ms-auto d-block d-xl-none"
          >
            <i className="bx bx-chevron-left bx-sm align-middle"></i>
          </Link>
        </div>

        <div className="menu-inner-shadow"></div>

        <ul className="menu-inner py-1">
          <NavLink
            to="/dashboard"
            className={`menu-item ${location.pathname === "/" ? "active" : ""}`}
          >
            <li className="menu-link">
              <i className="menu-icon tf-icons bx bx-home-circle"></i>
              <div data-i18n="Analytics">Dashboard</div>
            </li>
          </NavLink>
         
          <NavLink
            to="/newsletter"
            className={`menu-item ${location.pathname === "/newsletter" ? "active" : ""
              }`}
          >

            <li className="menu-link">
              <i className="menu-icon tf-icons bx bx-news"></i>
              <div data-i18n="Layouts">Newsletters</div>
            </li>
          </NavLink>

          <NavLink
            to="/story"
            className={`menu-item ${location.pathname === "/story" ? "active" : ""
              }`}
          >

            <li className="menu-link">
              <i className="menu-icon tf-icons bx bx-loader-circle"></i>
              <div data-i18n="Layouts">Story/ News</div>
            </li>
          </NavLink>


          <NavLink
            to="/page"
            className={`menu-item ${location.pathname === "/page" ? "active" : ""
              }`}
          >

            <li className="menu-link">
              <i className="menu-icon tf-icons bx bx-layout"></i>
              <div data-i18n="Layouts">page</div>
            </li>
          </NavLink>


          <NavLink
            to="/user"
            className={`menu-item ${location.pathname === "/user" ? "active" : ""
              }`}
          >

            <li className="menu-link">
              <i className="menu-icon tf-icons bx bx-user"></i>
              <div data-i18n="Layouts">users</div>
            </li>
          </NavLink>


          <NavLink
            to="/media"
            className={`menu-item ${location.pathname === "/media" ? "active" : ""
              }`}
          >

            <li className="menu-link">
              <i className="menu-icon tf-icons bx bx-file"></i>
              <div data-i18n="Layouts">Media</div>
            </li>
          </NavLink>


          <NavLink
            to="/category"
            className={`menu-item ${location.pathname === "/category" ? "active" : ""
              }`}
          >
            <li className="menu-header small text-uppercase">
              <span className="menu-header-text">Stories</span>
            </li>
            <li className="menu-link">
              <i className="menu-icon tf-icons bx bx-category"></i>
              <div data-i18n="Layouts">Category</div>
            </li>
            <NavLink
              to="/post"
              className={`menu-item ${location.pathname === "/Post" ? "active" : ""
                }`}
            >
              <li className="menu-link">
                <i className="menu-icon tf-icons bx bx-book-open"></i>
                <div data-i18n="Layouts">Posts</div>
              </li>
            </NavLink>
          </NavLink>


          <li className="menu-header small text-uppercase">
            <span className="menu-header-text">Blog</span>
          </li>

          <NavLink
            to="/blog-category"
            className={`menu-item ${location.pathname === "/blog-category" ? "active" : ""
              }`}
          >
            <li className="menu-link">
              <i className="menu-icon tf-icons bx bx-category"></i>
              <div data-i18n="blog-category">Blog Category</div>
            </li>
          </NavLink>


          <NavLink
            to="/blog-post"
            className={`menu-item ${location.pathname === "/blog-post" ? "active" : ""
              }`}
          >
            <li className="menu-link">
              <i className="menu-icon tf-icons bx bx-message-square-edit"></i>
              <div data-i18n="blog-category">Blog Post</div>
            </li>

          </NavLink>


          <NavLink
            to="/blog_vedio"
            className={`menu-item ${location.pathname === "/blog_vedio" ? "active" : ""
              }`}
          >
            <li className="menu-link">
              <i className="menu-icon tf-icons bx bxs-videos"></i>
              <div data-i18n="blog-category">Blog Vedio</div>
            </li>

          </NavLink>


          <NavLink
            to="/writer_info"
            className={`menu-item ${location.pathname === "/writer_info" ? "active" : ""
              }`}
          >
            <li className="menu-link">
              <i className="menu-icon tf-icons bx bx-user-plus"></i>
              <div data-i18n="blog-category">Writer Information</div>
            </li>
          </NavLink>


          {/* <NavLink
            to="/tag"
            className={`menu-item ${location.pathname === "/tag" ? "active" : ""
              }`}
          >
            <li className="menu-link">
              <i className="menu-icon tf-icons bx bx-purchase-tag-alt"></i>
              <div data-i18n="Layouts">Tags</div>
            </li>
          </NavLink> */}


          <NavLink
            to="/directory"
            className={`menu-item ${location.pathname === "/directory" ? "active" : ""
              }`}
          >
            <li className="menu-link">
              <i className="menu-icon tf-icons bx bxs-contact"></i>
              <div data-i18n="Layouts">Directory</div>
            </li>
          </NavLink>


          <NavLink
            to="/event"
            className={`menu-item ${location.pathname === "/event" ? "active" : ""
              }`}
          >
            <li className="menu-link">
              <i className="menu-icon tf-icons bx bxs-calendar-event"></i>
              <div data-i18n="Layouts">Events</div>
            </li>
          </NavLink>

          <NavLink
            to="/classified"
            className={`menu-item ${location.pathname === "/classified" ? "active" : ""
              }`}
          >
            <li className="menu-link">
              <i className="menu-icon tf-icons bx bxs-business"></i>
              <div data-i18n="Layouts">Classified</div>
            </li>
          </NavLink>


          <NavLink
            to="/banner"
            className={`menu-item ${location.pathname === "/banner" ? "active" : ""
              }`}
          >
            <li className="menu-link">
              <i className="menu-icon tf-icons bx bx-image"></i>
              <div data-i18n="Layouts">Banners</div>
            </li>
          </NavLink>

          <NavLink
            to="/poll"
            className={`menu-item ${location.pathname === "/poll" ? "active" : ""
              }`}
          >
            <li className="menu-link">
              <i className="menu-icon tf-icons bx bx-poll"></i>
              <div data-i18n="Layouts"> Manage polls</div>
            </li>
          </NavLink>

          {/*   <NavLink
            to="/roles-permission"
            className={`menu-item ${location.pathname === "/roles-permission" ? "active" : ""
              }`}
          >
            <li className="menu-link">
              <i className="menu-icon tf-icons bx bx-shield"></i>
              <div data-i18n="Layouts">Roles & Permission</div>
            </li>
          </NavLink> */}



          <li className="menu-header small text-uppercase">
            <span className="menu-header-text">Setting</span>
          </li>

          <li className="menu-item">
            <Link tp="#" className="menu-link menu-toggle">
              <i className="menu-icon tf-icons bx bx-detail"></i>
              <div data-i18n="Form Elements">Settings</div>
            </Link>
            <ul className="menu-sub">
              <li className="menu-item">
                <a href="forms-basic-inputs.html" className="menu-link">
                  <div data-i18n="Basic Inputs">Basic Inputs</div>
                </a>
              </li>
            </ul>
          </li>

        
         

         
        </ul>
      </aside>
      {/* <div className="layout-overlay layout-menu-toggle"></div> */}
    </>
  );
}
