import React, { useState } from "react";
import NewsCon from "./NewsCon";
/* require("dotenv").config(); */
const NewsState = (props) => {
    const host = process.env.REACT_APP_URL;
    console.log(host);
    const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7ImlkIjoiNjNkZjg4YWFhNDllYjE2NzFjYTdkZGNhIn0sImlhdCI6MTY3NTU5NTEzN30.xGYO_zIQPGR5YvcYhQFDlInCydDomn6Lrsqv6bZmrMQ";
    console.log(token);


    const [newsletter, setnewsletter] = useState([]);

    const addNews = async (body) => {
        try {
            const requestOptions = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "auth-token": token
                }, body: JSON.stringify(body)
            };
            const response = await fetch(
                `${host}/api/newsletter/addNews`,
                requestOptions
            );
            const dataresp = await response.json();
            console.log(dataresp);
            if (dataresp.status === "success") {
                setnewsletter(newsletter.concat(dataresp.data))

            }

            return dataresp

        }
        catch (e) {

        }
    }
    const deleteNews = async (id) => {
        const requestOptions = {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                "auth-token": token
            },
        };
        const response = await fetch(
            `${host}/api/newsletter/deleteNews/${id}`,
            requestOptions
        );
        const datares = await response.json();

        console.log(datares);
        setnewsletter(datares.data);
        if (datares.status === "Success") {
            const updateData = newsletter.filter((cat) => {
                return cat._id !== id

            })
            setnewsletter(updateData)
        }
        return datares
    };
    const getNews = async (id) => {
        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "auth-token": token
            },
        };
        const response = await fetch(
            `${host}/api/newsletter/getNews`,
            requestOptions
        );
        const datares = await response.json();

        console.log(datares);
        setnewsletter(datares.data);
    };
    const updateNews = async ( id,body) => {
        try {
            const requestOptions = {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    "auth-token": token
                }, body: JSON.stringify(body)
            };
            const response = await fetch(
                `${host}/api/newsletter/updateNews/${id}`,
                requestOptions
            );
            const dataresp = await response.json();
            console.log(dataresp);
            if (dataresp.status === "Success") {

                for (let i = 0; i < newsletter.length; i++) {
                    let element = newsletter[i];
                    if (element._id.toString() === id) {
                        newsletter[i] = dataresp.data;
                    }
                }
                setnewsletter(newsletter);
            }
            return dataresp;

        }
        catch (e) {

        }
    }

    return (
        <NewsCon.Provider
            value={{
                newsletter,
                getNews,
                updateNews,
                addNews,
                deleteNews
            }}

        >
            {props.children}
        </NewsCon.Provider>
    );
}
export default NewsState;