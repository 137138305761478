import React, { useContext, useEffect, useRef, useState } from "react";
import Choosecategory from "../componant/Media/Choosecategory";
import ChooseMedia from "../componant/Media/ChooseMedia";
import Preloader from "../componant/Preloader";
import categoryCon from "../context/Category/CategoryContext";
import ClassifiedCon from "../context/Classified/ClassifiedCon";
import MediaCon from "../context/Media/MediaCon";
import UserCon from "../context/User/UserCon";
import JoditEditor from "jodit-react";
import parse from "html-react-parser";
import { Link } from "react-router-dom";
export const Classified = (props) => {
  const host = process.env.REACT_APP_URL;
  console.log(host);
  const token = localStorage.getItem("token");
  console.log(token);

  /* search */
  const [query, setquery] = useState("");
  /* search end */

  const [preloader, setPreloader] = useState("");

  const myContext = useContext(ClassifiedCon);
  const {
    classified,
    addClassified,
    deleteClassified,
    getAllClassified,
    updateClassified,
  } = myContext;

  const myCont = useContext(categoryCon);
  const { category, getAllCatagory } = myCont;

  useEffect(() => {
    getAllClassified();
    getAllCatagory("classified");
    getallUser("user");
  }, []);
  const userCon = useContext(UserCon);
  const { user, getallUser } = userCon;

  const style = {
    background: "#00000080",
    display: "block",
  };
  const [newclassified, setnewClassified] = useState({
    b_name: "",
    image: "",
    b_category: "",
    user_id: "63df88aaa49eb1671ca7ddca",
    description: "",
    address: {},
    url: "",
    spec1: "",
    spec2: "",
    features: false,
    status: true,
    b_type: "",
    type: "",
    b_phone: "",
    b_price: "",
    language: localStorage.getItem("lang"),
  });
  const [addClassifiedmodel, setAddClassifiedmodel] = useState(false);

  const openData = () => {
    setAddClassifiedmodel(!addClassifiedmodel);
  };

  const delete_classified = async (id) => {
    setPreloader("preShow");
    const response = await deleteClassified(id);
    console.log(id);
    if (response.status === "Success") {
      props.showAlert(response.message, "success");
    } else {
      props.showAlert(response.message, "danger");
    }
    setPreloader("");
  };
  const handleClick = async (e) => {
    e.preventDefault();
    newclassified.description = content;
    newclassified.address = address;

    setPreloader("preShow");
    const response = await addClassified(newclassified);

    if (response.status === "success") {
      openData();
      props.showAlert(response.message, "success");
    } else {
      props.showAlert(response.message, "warning");
    }

    setPreloader("");
    console.log(newclassified);
  };

  const onChange = (e) => {
    setnewClassified({ ...newclassified, [e.target.name]: e.target.value });
  };

  const [address, setAddress] = useState({});
  const onAddress = (e) => {
    setAddress({ ...address, [e.target.name]: e.target.value });
  };
  const [mediaModel, setmediaModel] = useState(false);

  const openMedia = async () => {
    setmediaModel(!mediaModel);
    if (!mediaModel) {
      setPreloader("preShow");

      setPreloader("");
    }
  };

  const selectImage = (url) => {
    openMedia();
    setnewClassified({ ...newclassified, image: url });
  };
  const changeStatus = async (id, status, type) => {
    setPreloader("preShow");
    let response = {};
    if (type === "status") {
      response = await updateClassified(id, { status: status });
    } else {
      response = await updateClassified(id, { status: status });
    }
    response.status === "Success"
      ? props.showAlert(`${type}  status is updated`, "success")
      : props.showAlert(response.message, "danger");
    setPreloader("");
  };
  /*  */
  /* update section */
  const onedit = (e) => {
    setupdateClas({ ...updateClas, [e.target.name]: e.target.value });
  };
  const handleUpdate = async (e) => {
    e.preventDefault();
    setPreloader("preShow");
    const response = await updateClassified(updateClas._id, updateClas);

    if (response.status === "Success") {
      openClasiEdit({});
      props.showAlert(response.message, "success");
    } else {
      props.showAlert(response.message, "warning");
    }
    setPreloader("");
  };

  const [editClassiModel, seteditClassiModel] = useState(false);
  const [updateClas, setupdateClas] = useState({});
  const openClasiEdit = (clasi) => {
    seteditClassiModel(!editClassiModel);
    setupdateClas(clasi);
  };
  /* jodit start */
  const editor = useRef(null);
  const [content, setContent] = useState("");
  /* jodit end */

  /* slider start */
  const [statusChange, setstatusChange] = useState(true);
  const clickBlock = (status) => {
    setstatusChange(status);
    console.log(statusChange);
    const st = classified.filter((classified) => {
      return classified.status === statusChange;
    });
    console.log(st);
  };
  /* slider ends */
  return (
    <>
      <Preloader show={preloader} />

      {editClassiModel && (
        <div className="modal reviewModal" style={style}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Update Classified
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={openClasiEdit}
                ></button>
              </div>
              <div className="modal-body">
                <div
                  id="formAccountSettings"
                  method="POST"
                  onSubmit={handleUpdate}
                >
                  <div className="row">
                    <div className="col-md-12  my-1">
                      <div className="form-group">
                        <label className="form-label">
                          Enter Business Name
                        </label>
                        <input
                          type="text"
                          className="form-control    m-0"
                          name="b_name"
                          id=""
                          aria-describedby="helpId"
                          value={updateClas.b_name}
                          placeholder="Enter Business Name"
                          onChange={onedit}
                        />
                      </div>
                      <Choosecategory onChange={onChange} type={"blog"} />
                      <div className="form-group">
                        <label className="form-label">Enter User Id</label>
                        <select
                          className="form-control"
                          name="user_id"
                          onChange={onChange}
                        >
                          <option value={0}>Select user</option>
                          {user.map((user, i) => {
                            return (
                              <option value={user._id}>{user.name}</option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <label className="form-label">Enter Discription</label>
                        <input
                          type="text"
                          className="form-control    m-0"
                          name="description"
                          id=""
                          aria-describedby="helpId"
                          value={updateClas.description}
                          placeholder="Enter Discription"
                          onChange={onedit}
                        />
                      </div>
                      <div className="form-group">
                        <label className="form-label">Enter City</label>
                        <input
                          type="text"
                          className="form-control    m-0"
                          name="city"
                          id=""
                          aria-describedby="helpId"
                          value={updateClas.address.city}
                          placeholder="Enter City"
                          onChange={onAddress}
                        />
                      </div>
                      <div className="form-group">
                        <label className="form-label">Enter State</label>
                        <input
                          type="text"
                          className="form-control    m-0"
                          name="state"
                          id=""
                          aria-describedby="helpId"
                          value={updateClas.address.state}
                          placeholder="Enter State"
                          onChange={onAddress}
                        />
                      </div>
                      <div className="form-group">
                        <label className="form-label">Enter Country</label>
                        <input
                          type="text"
                          className="form-control    m-0"
                          name="country"
                          id=""
                          aria-describedby="helpId"
                          value={updateClas.address.country}
                          placeholder="Enter Country"
                          onChange={onAddress}
                        />
                      </div>
                      <div className="form-group">
                        <label className="form-label">Enter Pincode</label>
                        <input
                          type="text"
                          className="form-control    m-0"
                          name="pincode"
                          id=""
                          aria-describedby="helpId"
                          value={updateClas.address.pincode}
                          placeholder="Enter Pincode"
                          onChange={onAddress}
                        />
                      </div>
                      <div className="form-group">
                        <label className="form-label">Enter URL</label>
                        <input
                          type="text"
                          className="form-control    m-0"
                          name="url"
                          id=""
                          aria-describedby="helpId"
                          value={updateClas.url}
                          placeholder="Enter URL"
                          onChange={onedit}
                        />
                      </div>

                      <div className="form-group">
                        <label className="form-label">
                          Enter Specification 1
                        </label>
                        <input
                          type="text"
                          className="form-control    m-0"
                          name="spec1"
                          id=""
                          aria-describedby="helpId"
                          value={updateClas.spec1}
                          placeholder="Enter Specification 1"
                          onChange={onedit}
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <label className="form-label">
                          Enter Specification 2
                        </label>
                        <input
                          type="text"
                          className="form-control    m-0"
                          name="spec2"
                          id=""
                          aria-describedby="helpId"
                          value={updateClas.spec2}
                          placeholder="Enter Specification 2"
                          onChange={onedit}
                        />
                      </div>
                      <div className="form-group">
                        <label className="form-label"> Status</label>
                        <table width="100%">
                          <tr>
                            <td>
                              <div className="form-check">
                                <input
                                  type="radio"
                                  className="form-check-input"
                                  name="status"
                                  value={false}
                                  defaultChecked={
                                    updateClas.status ? false : true
                                  }
                                  onChange={onedit}
                                />
                                <label className="form-label"> Draft</label>
                              </div>
                            </td>
                            <td>
                              <div className="form-check">
                                <input
                                  type="radio"
                                  className="form-check-input"
                                  name="status"
                                  value={true}
                                  defaultChecked={
                                    updateClas.status ? true : false
                                  }
                                  onChange={onedit}
                                />
                                <label className="form-label"> Publish</label>
                              </div>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="form-group">
                        <label className="form-label">
                          Enter Business Type
                        </label>
                        <input
                          type="text"
                          className="form-control    m-0"
                          name="b_type"
                          id=""
                          aria-describedby="helpId"
                          value={updateClas.b_type}
                          placeholder="Enter Business Type"
                          onChange={onedit}
                        />
                      </div>
                      <div className="form-group">
                        <label className="form-label">Enter Phone Number</label>
                        <input
                          type="text"
                          className="form-control    m-0"
                          name="b_phone"
                          id=""
                          aria-describedby="helpId"
                          value={updateClas.b_phone}
                          placeholder="Enter Phone"
                          onChange={onedit}
                        />
                      </div>
                      <div className="form-group">
                        <label className="form-label">Enter Price</label>
                        <input
                          type="text"
                          className="form-control    m-0"
                          name="b_price"
                          id=""
                          aria-describedby="helpId"
                          value={updateClas.b_price}
                          placeholder="Enter Price"
                          onChange={onedit}
                        />
                      </div>

                      <div className="form-group">
                        <label className="form-label">Choose Image</label>
                        <div className="image_div_content" onClick={openMedia}>
                          <i className="bx bxs-image-add"></i>
                          <p className="normalhyperlink">click to upload</p>
                        </div>
                        <div className="">
                          <img
                            className="img-fluid"
                            src={newclassified.image}
                            alt="selected_image"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* category add section end */}

                  <div className="mt-3">
                    <button
                      type="reset"
                      className="btn btn-outline-primary me-2 my-btn"
                      onClick={() => {
                        openClasiEdit({});
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="btn orange-btn btn-primary me-2"
                      onClick={handleUpdate}
                    >
                      Update
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {addClassifiedmodel && (
        <div className="modal reviewModal" style={style}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Add New Classified
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={openData}
                ></button>
              </div>
              <div className="modal-body">
                <div
                  id="formAccountSettings"
                  method="POST"
                  onSubmit={handleClick}
                >
                  <div className="row">
                    <div className="col-md-12  my-1">
                      <div className="form-group">
                        <label className="form-label">Enter Title</label>
                        <input
                          type="text"
                          className="form-control    m-0"
                          name="b_name"
                          id=""
                          aria-describedby="helpId"
                          placeholder="Enter Business Name"
                          onChange={onChange}
                        />
                      </div>
                      <div className="form-group">
                        <label className="form-label">
                          Enter Event Details
                        </label>
                        <JoditEditor
                          ref={editor}
                          value={content}
                          onChange={(newContent) => setContent(newContent)}
                        />
                      </div>
                      <div className="form-group">
                        <label className="form-label">Choose Category</label>
                        <select
                          className="form-control"
                          name="cat_id"
                          onChange={onChange}
                        >
                          <option value={0}>Choose Category</option>
                          {category.map((cat, i) => {
                            return (
                              <option value={cat._id}>{cat.cat_name}</option>
                            );
                          })}
                        </select>
                      </div>

                      <div className="form-group">
                        <label className="form-label">Enter User Id</label>
                        <select
                          className="form-control"
                          name="user_id"
                          onChange={onChange}
                        >
                          <option value={0}>Select user</option>
                          {user.map((user, i) => {
                            return (
                              <option value={user._id}>{user.fname}</option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <div className="form-group">
                          <div className="form-group">
                            <label className="form-label">TYPE </label>
                            <select
                              className="form-control"
                              name="type"
                              onChange={onChange}
                            >
                              <option>FOR SALE</option>
                              <option>WANTED</option>
                              <option>FOR SWAPS</option>
                            </select>
                          </div>

                          <div className="form-group">
                            <label className="form-label">
                              Enter Discription
                            </label>
                            <input
                              type="text"
                              className="form-control    m-0"
                              name="description"
                              id=""
                              aria-describedby="helpId"
                              placeholder="Enter Discription"
                              onChange={onChange}
                            />
                          </div>
                          <div className="form-group">
                            <label className="form-label">Enter City</label>
                            <input
                              type="text"
                              className="form-control    m-0"
                              name="city"
                              id=""
                              aria-describedby="helpId"
                              placeholder="Enter City"
                              onChange={onAddress}
                            />
                          </div>
                          <div className="form-group">
                            <label className="form-label">Enter State</label>
                            <input
                              type="text"
                              className="form-control    m-0"
                              name="state"
                              id=""
                              aria-describedby="helpId"
                              placeholder="Enter State"
                              onChange={onAddress}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="form-label">Enter City</label>
                        <input
                          type="text"
                          className="form-control    m-0"
                          name="city"
                          id=""
                          aria-describedby="helpId"
                          placeholder="Enter City"
                          onChange={onAddress}
                        />
                      </div>
                      <div className="form-group">
                        <label className="form-label">Enter State</label>
                        <input
                          type="text"
                          className="form-control    m-0"
                          name="state"
                          id=""
                          aria-describedby="helpId"
                          placeholder="Enter State"
                          onChange={onAddress}
                        />
                      </div>
                      <div className="form-group">
                        <label className="form-label">Enter Country</label>
                        <input
                          type="text"
                          className="form-control    m-0"
                          name="country"
                          id=""
                          aria-describedby="helpId"
                          placeholder="Enter Country"
                          onChange={onAddress}
                        />
                      </div>
                      <div className="form-group">
                        <label className="form-label">Enter Pincode</label>
                        <input
                          type="text"
                          className="form-control    m-0"
                          name="pincode"
                          id=""
                          aria-describedby="helpId"
                          placeholder="Enter Pincode"
                          onChange={onAddress}
                        />
                      </div>
                      <div className="form-group">
                        <label className="form-label">Enter URL</label>
                        <input
                          type="text"
                          className="form-control    m-0"
                          name="url"
                          id=""
                          aria-describedby="helpId"
                          placeholder="Enter URL"
                          onChange={onChange}
                        />
                      </div>

                      <div className="form-group">
                        <label className="form-label">
                          Enter Specification 1
                        </label>
                        <input
                          type="text"
                          className="form-control    m-0"
                          name="spec1"
                          id=""
                          aria-describedby="helpId"
                          placeholder="Enter Specification 1"
                          onChange={onChange}
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <label className="form-label">Enter Country</label>
                        <input
                          type="text"
                          className="form-control    m-0"
                          name="country"
                          id=""
                          aria-describedby="helpId"
                          placeholder="Enter Country"
                          onChange={onAddress}
                        />
                      </div>
                      <div className="form-group">
                        <label className="form-label">Enter Pincode</label>
                        <input
                          type="text"
                          className="form-control    m-0"
                          name="pincode"
                          id=""
                          aria-describedby="helpId"
                          placeholder="Enter Pincode"
                          onChange={onAddress}
                        />
                      </div>
                      <div className="form-group">
                        <label className="form-label">Enter URL</label>
                        <input
                          type="text"
                          className="form-control    m-0"
                          name="url"
                          id=""
                          aria-describedby="helpId"
                          placeholder="Enter URL"
                          onChange={onChange}
                        />
                      </div>

                      <div className="form-group">
                        <label className="form-label">
                          Enter Specification 1
                        </label>
                        <input
                          type="text"
                          className="form-control    m-0"
                          name="spec1"
                          id=""
                          aria-describedby="helpId"
                          placeholder="Enter Specification 1"
                          onChange={onChange}
                        />
                      </div>

                      <div className="form-group">
                        <label className="form-label">
                          Enter Specification 2
                        </label>
                        <input
                          type="text"
                          className="form-control    m-0"
                          name="spec2"
                          id=""
                          aria-describedby="helpId"
                          placeholder="Enter Specification 2"
                          onChange={onChange}
                        />
                      </div>
                      <div className="form-group">
                        <label className="form-label"> Status</label>
                        <table width="100%">
                          <tr>
                            <td>
                              <div className="form-check">
                                <input
                                  type="radio"
                                  className="form-check-input"
                                  name="status"
                                  value={false}
                                  onChange={onChange}
                                />
                                <label className="form-label"> Draft</label>
                              </div>
                            </td>
                            <td>
                              <div className="form-check">
                                <input
                                  type="radio"
                                  className="form-check-input"
                                  name="status"
                                  value={true}
                                  onChange={onChange}
                                />
                                <label className="form-label"> Publish</label>
                              </div>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="form-group">
                        <label className="form-label">
                          Enter Business Type
                        </label>
                        <input
                          type="text"
                          className="form-control    m-0"
                          name="b_type"
                          id=""
                          aria-describedby="helpId"
                          placeholder="Enter Business Type"
                          onChange={onChange}
                        />
                      </div>
                      <div className="form-group">
                        <label className="form-label">Enter Phone Number</label>
                        <input
                          type="text"
                          className="form-control    m-0"
                          name="b_phone"
                          id=""
                          aria-describedby="helpId"
                          placeholder="Enter Phone"
                          onChange={onChange}
                        />
                      </div>
                      <div className="form-group">
                        <label className="form-label">Enter Price</label>
                        <input
                          type="text"
                          className="form-control    m-0"
                          name="b_price"
                          id=""
                          aria-describedby="helpId"
                          placeholder="Enter Price"
                          onChange={onChange}
                        />
                      </div>

                      <div className="" onClick={openMedia}>
                        {newclassified.image?.includes("mp4") ? (
                          <video
                            width="300"
                            height="200"
                            autoPlay={true}
                            controls={true}
                          >
                            <source src={newclassified.image} type="" />
                          </video>
                        ) : (
                          <img
                            src={
                              newclassified.image === undefined ||
                              newclassified.image === ""
                                ? "assets/img/illustrations/upload.png"
                                : newclassified.image
                            }
                            className="img-fluid"
                            width={150}
                          />
                        )}

                        <span className="m-3">Click to upload</span>
                      </div>
                    </div>
                    {/* category add section end */}

                    <div className="mt-3">
                      <button
                        type="reset"
                        className="btn btn-outline-primary me-2 my-btn"
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="btn orange-btn btn-primary me-2"
                        onClick={handleClick}
                      >
                        Add
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {mediaModel && <ChooseMedia open={openMedia} selectImage={selectImage} />}
      <div className="content-wrapper">
        <div className="container-fluid flex-grow-1 container-p-y">
          <div className="fixed-button">
            <div
              className={`btn btn-sm fixed_button btn-outline-primary ${
                addClassifiedmodel ? "d-none" : "d-block"
              }`}
              onClick={openData}
            >
              + Add classified
            </div>
          </div>
          <div className="row">
            <h4 className="card-header c_heading">
              <span className="headingcontent">
                CLassified ({classified.length})
              </span>
            </h4>

            <div className="col-lg-12 mb-4 ">
              <div class="card">
                <h5 class="card-header">
                  <div className="row">
                    <div className="col-lg-6 font-bold">
                      <ul className="slider_button slider-white plain-orange">
                        <li
                          className={`${
                            statusChange === null ? "slider-active" : ""
                          }`}
                          onClick={() => {
                            clickBlock(null);
                          }}
                        >
                          All
                        </li>
                        <li
                          className={`${
                            statusChange !== null && statusChange === false
                              ? "slider-active"
                              : ""
                          }`}
                          onClick={() => {
                            clickBlock(false);
                          }}
                        >
                          Block
                        </li>
                        <li
                          className={`${
                            statusChange !== null && statusChange === true
                              ? "slider-active"
                              : ""
                          }`}
                          onClick={() => {
                            clickBlock(true);
                          }}
                        >
                          Unblock
                        </li>
                      </ul>
                    </div>
                    <div className="col-lg-6 text-right">
                      <input
                        type="text"
                        className="form-control search"
                        placeholder="Search Classified..."
                        onChange={(e) => {
                          setquery(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </h5>

                <div class="table-responsive text-nowrap mb-1">
                  <table class="table table-hover">
                    <thead className="order_history_header">
                      <tr>
                        <th>S.No.</th>
                        <th>Title</th>
                        <th>Category</th>
                        <th>User Id</th>
                        {/*   <th>Discription</th> */}
                        {/*  <th>Address</th>
                        <th>URL</th> */}
                        {/*  <th>Specification 1</th>
                        <th>Specification 2</th> */}

                        <th>Status</th>
                        {/*   <th>Business Type</th> */}
                        {/*  <th>Phone</th> */}
                        <th>Price</th>
                        {/*   <th>Language</th> */}

                        <th className="action">Action</th>
                      </tr>
                    </thead>
                    <tbody class="table-border-bottom-0 order_history_body">
                      {classified
                        .filter((classified) => {
                          return (
                            classified.status === statusChange &&
                            classified.b_name
                              .toLowerCase()
                              .includes(query.toLowerCase())
                          );
                        })
                        .map((classified, index) => {
                          return (
                            <tr key={index}>
                              <th>{index + 1}</th>
                              <td>
                                <Link
                                  to={`${process.env.REACT_APP_URL}/media/classifieds/${classified.image}`}
                                  target="_blank"
                                >
                                  <img
                                    src={`${process.env.REACT_APP_URL}/media/classifieds/${classified.image}`}
                                    alt={`${classified.image}`}
                                    className="img-fluid w-50"
                                  />
                                </Link>
                                <br />
                                {classified.b_name}
                              </td>
                              <td>
                                {classified.cat_id === null
                                  ? "..."
                                  : classified.cat_id.cat_name}
                              </td>
                              <td>
                                {classified.user_id === null
                                  ? "..."
                                  : classified.user_id.fname}
                              </td>
                              {/*  <td>{classified.description}</td> */}
                              {/*   <td>{classified.address.city}, {classified.address.state}, {classified.address.country}, {classified.address.pincode}</td> */}
                              {/*    <td>{classified.url}</td> */}
                              {/*  <td>{classified.spec1}</td>
                            <td>{classified.spec2}</td> */}
                              {/* <td>{classified.features}</td> */}
                              <td>
                                {classified.status ? (
                                  <i
                                    className="bx bx-check"
                                    onClick={() => {
                                      changeStatus(
                                        classified._id,
                                        false,
                                        "classified"
                                      );
                                    }}
                                  ></i>
                                ) : (
                                  <i
                                    className="bx bx-block"
                                    onClick={() => {
                                      changeStatus(
                                        classified._id,
                                        true,
                                        "classified"
                                      );
                                    }}
                                  ></i>
                                )}
                              </td>

                              {/*   <td>{classified.b_type}</td> */}
                              {/* <td>{classified.b_phone}</td> */}
                              <td>{classified.b_price}</td>
                              {/*   <td>{classified.language}</td> */}

                              <td className="action">
                                <div className="dropdown ">
                                  <i
                                    className="bx bx-trash me-1"
                                    onClick={() => {
                                      delete_classified(classified._id);
                                    }}
                                  ></i>

                                  <i
                                    className="bx bx-edit me-1"
                                    onClick={() => {
                                      openClasiEdit(classified);
                                    }}
                                  ></i>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
