import React, { useContext, useEffect, useState } from "react";
import Alert from "../componant/Alert";
import Preloader from "../componant/Preloader";
import notiCon from "../context/Notification/NotiCon";

export const Notification = () => {

  const myContext = useContext(notiCon)
  const { event,
    getAllNotification, createNotification, deleteNotification } = myContext

  useEffect(() => {
    getAllNotification()
  }, [])
  const style = {
    background: "#00000080",
    display: "block",
  };
  const [newNotification, setnewNotification] = useState({
   name: ""
  })
  const [addNotificationModal, setAddNotificationModal] = useState(false);
  //const [addsubCategoryModal, setAddsubCategoryModal] = useState(false);
  const openData = () => {
    setAddNotificationModal(!addNotificationModal);
  };

  /* const delete_Notification = async (id) => {

    const response = await deleteNotification(id)
    console.log(id);
    if (response === true) {
      openData();
    }
  }
  const handleClick = async (e) => {
    e.preventDefault()
    const response = await createNotification(newNotification)
    console.log(newNotification);
    if (response === true) {
      openData();
    }

  } */


  const onChange = (e) => {
    setnewNotification({ ...newNotification, [e.target.name]: e.target.value })
  }
 

  return (
    <>
      
      
    </>
  );
};
