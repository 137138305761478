import React, { useContext, useRef, useEffect, useState } from "react";
import Preloader from "../componant/Preloader";
import NewsCon from "../context/Newsletter/NewsCon";
import JoditEditor from "jodit-react";
import parse from "html-react-parser";

export const Newsletter = (props) => {
  const [preloader, setPreloader] = useState("");

  const myContext = useContext(NewsCon);
  const { newsletter, getNews, updateNews, addNews, deleteNews } = myContext;

  const [query, setquery] = useState("");
  useEffect(() => {
    getNews();
  }, []);
  const style = {
    background: "#00000080",
    display: "block",
  };
  const [newNews, setnewNews] = useState({
    title: "",
    description: "",
    status: false,
    language: localStorage.getItem("lang"),
  });
  /* jodit start */
  const editor = useRef(null);
  const [content, setContent] = useState("");
  console.log(content);
  /* jodit end */

  const [addnewsletter_Model, setAddnewsletter_Model] = useState(false);

  const openData = () => {
    setAddnewsletter_Model(!addnewsletter_Model);
  };

  const delete_newsletter = async (id) => {
    setPreloader("preShow");
    const response = await deleteNews(id);
    console.log(id);
    if (response.status === "Success") {
      props.showAlert(response.message, "success");
    } else {
      props.showAlert(response.message, "warning");
    }
    setPreloader("");
  };
  const handleClick = async (e) => {
    e.preventDefault();
    newNews.description = content;
    setPreloader("preShow");
    const response = await addNews(newNews);
    console.log(newNews);
    if (response.status === "success") {
      openData();
      props.showAlert(response.message, "success");
    } else {
      props.showAlert(response.message, "warning");
    }
    setPreloader("");
  };

  const onChange = (e) => {
    setnewNews({ ...newNews, [e.target.name]: e.target.value });
  };

  /*   status update */
  const changeStatus = async (id, status, type) => {
    setPreloader("preShow");
    let response = {};
    if (type === "Newsletter ") {
      response = await updateNews(id, { status: status });
    } else {
      response = await updateNews(id, { status: status });
    }
    response.status === "Success"
      ? props.showAlert(`${type}  status is updated`, "success")
      : props.showAlert(response.message, "danger");
    setPreloader("");
  };

  /* edit section */
  const onedit = (e) => {
    setupdateNewslet({ ...updateNewslet, [e.target.name]: e.target.value });
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    setPreloader("preShow");
    const response = await updateNews(updateNewslet._id, updateNewslet);

    if (response.status === "Success") {
      openNewsEdit({});
      props.showAlert(response.message, "success");
    } else {
      props.showAlert(response.message, "warning");
    }
    setPreloader("");
  };
  const [editNewsModel, seteditNewsModel] = useState(false);
  const [updateNewslet, setupdateNewslet] = useState({});
  const openNewsEdit = (cat) => {
    seteditNewsModel(!editNewsModel);
    setupdateNewslet(cat);
  };

  /* slider start */
  const [statusChange, setstatusChange] = useState(null);
  const clickBlock = (status) => {
    setstatusChange(status);
    console.log(statusChange);
    const st = newsletter.filter((newsletter) => {
      return newsletter.status === statusChange;
    });
    console.log(st);
  };
  /* slider ends */

  return (
    <>
      <Preloader show={preloader} />

      {editNewsModel && (
        <div className="modal reviewModal" style={style}>
          <div className="modal-dialog" style={{ width: "80%" }}>
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Update Newsletter
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    openNewsEdit({});
                  }}
                ></button>
              </div>
              <div className="modal-body">
                <form id="formAccountSettings">
                  <div className="row">
                    {/* category add section */}
                    <div className="col-md-12  my-1">
                      {/*   <form method="post"> */}

                      <div className="form-group">
                        <label className="form-label">Enter Title</label>
                        <input
                          type="text"
                          className="form-control    m-0"
                          name="title"
                          id=""
                          aria-describedby="helpId"
                          placeholder="Title"
                          defaultValue={updateNewslet.title}
                          onChange={onedit}
                        />
                      </div>
                      <div className="form-group">
                        <label className="form-label">Enter Discription</label>
                        <JoditEditor
                          ref={editor}
                          value={content}
                          onChange={(newContent) => setContent(newContent)}
                        />
                      </div>
                    </div>

                    <div className="col-6">
                      <div className="form-group">
                        <label className="form-label"> Status</label>
                        <table width="100%">
                          <tr>
                            <td>
                              <div className="form-check">
                                <input
                                  type="radio"
                                  className="form-check-input"
                                  name="status"
                                  value={false}
                                  defaultChecked={
                                    updateNews.status ? false : true
                                  }
                                  onChange={onedit}
                                />
                                <label className="form-label"> Draft</label>
                              </div>
                            </td>
                            <td>
                              <div className="form-check">
                                <input
                                  type="radio"
                                  className="form-check-input"
                                  name="status"
                                  value={true}
                                  defaultChecked={
                                    updateNews.status ? true : false
                                  }
                                  onChange={onedit}
                                />
                                <label className="form-label"> Publish</label>
                              </div>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                  {/* category add section end */}

                  <div className="mt-3">
                    <button
                      type="reset"
                      className="btn btn-outline-primary me-2 my-btn"
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="btn orange-btn btn-primary me-2"
                      onClick={handleUpdate}
                    >
                      Update
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}

      {addnewsletter_Model && (
        <div className="modal reviewModal" style={style}>
          <div className="modal-dialog" style={{ width: "60%" }}>
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Add Newsletter
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={openData}
                ></button>
              </div>
              <div className="modal-body">
                <form
                  id="formAccountSettings"
                  method="POST"
                  onSubmit={handleClick}
                >
                  <div className="row">
                    {/* category add section */}
                    <div className="col-md-12  my-1">
                      {/*   <form method="post"> */}

                      <div className="form-group">
                        <label className="form-label">Enter Title</label>
                        <input
                          type="text"
                          className="form-control m-0 mb-2"
                          name="title"
                          id=""
                          aria-describedby="helpId"
                          placeholder="Title"
                          onChange={onChange}
                        />
                      </div>
                      <div className="form-group">
                        <label className="form-label">Enter Discription</label>
                        <JoditEditor
                          ref={editor}
                          value={content}
                          onChange={(newContent) => setContent(newContent)}
                        />
                      </div>
                    </div>

                    <div className="col-6">
                      <div className="form-group">
                        <label className="form-label mt-2"> Status</label>
                        
                        <table width="100%">
                          <tr>
                            <td>
                              <div className="form-check">
                                <input
                                  type="radio"
                                  className="form-check-input"
                                  name="status"
                                  value={false}
                                  onChange={onChange}
                                />
                                <label className="form-label"> Draft</label>
                              </div>
                            </td>
                            <td>
                              <div className="form-check">
                                <input
                                  type="radio"
                                  className="form-check-input"
                                  name="status"
                                  value={true}
                                  onChange={onChange}
                                />
                                <label className="form-label"> Publish</label>
                              </div>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                  {/* category add section end */}

                  <div className="mt-3">
                    <button
                      type="reset"
                      className="btn btn-outline-primary me-2 my-btn"
                      onClick={openData}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="btn orange-btn btn-primary me-2"
                      onClick={handleClick}
                    >
                      Add
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="content-wrapper">
        <div className="container-fluid flex-grow-1 container-p-y">
          <div className="fixed-button">
            <div
              className={`btn btn-sm fixed_button btn-outline-primary ${
                addnewsletter_Model ? "d-none" : "d-block"
              }`}
              onClick={openData}
            >
              + Add NewsLetter
            </div>
          </div>
          <div className="row">
            <h4 className="card-header c_heading">
              <span className="headingcontent">
                My Newsletters({newsletter.length})
              </span>
            </h4>

            <div className="col-lg-12 mb-4 ">
              <div class="card">
                <h5 class="card-header">
                  <div className="row">
                    <div className="col-lg-6 font-bold">
                      <ul className="slider_button slider-white plain-orange">
                        <li
                          className={`${
                            statusChange === null ? "slider-active" : ""
                          }`}
                          onClick={() => {
                            clickBlock(null);
                          }}
                        >
                          All
                        </li>
                        <li
                          className={`${
                            statusChange !== null && statusChange === false
                              ? "slider-active"
                              : ""
                          }`}
                          onClick={() => {
                            clickBlock(false);
                          }}
                        >
                          Block
                        </li>
                        <li
                          className={`${
                            statusChange !== null && statusChange === true
                              ? "slider-active"
                              : ""
                          }`}
                          onClick={() => {
                            clickBlock(true);
                          }}
                        >
                          Unblock
                        </li>
                      </ul>
                    </div>
                    <div className="col-lg-6 text-right">
                      <input
                        type="text"
                        className="form-control search"
                        placeholder="Search NewsLetters..."
                        onChange={(e) => {
                          setquery(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </h5>

                <div class="table-responsive text-nowrap mb-1">
                  <table class="table table-hover">
                    <thead className="order_history_header">
                      <tr>
                        <th> Title</th>

                        <th>Status</th>

                        <th className="action">Action</th>
                      </tr>
                    </thead>
                    <tbody class="table-border-bottom-0 order_history_body">
                      {newsletter
                        .filter((newsletter) => {
                          return (
                            statusChange === null ||
                            (newsletter.status === statusChange &&
                              newsletter.title
                                .toLowerCase()
                                .includes(query.toLowerCase()))
                          );
                        })
                        .map((newsletter, index) => {
                          return (
                            <tr key={index}>
                              <td>{newsletter.title}</td>

                              <td>
                                {newsletter.status ? (
                                  <i
                                    className="bx bx-check"
                                    onClick={() => {
                                      changeStatus(
                                        newsletter._id,
                                        false,
                                        "Status "
                                      );
                                    }}
                                  ></i>
                                ) : (
                                  <i
                                    className="bx bx-block"
                                    onClick={() => {
                                      changeStatus(
                                        newsletter._id,
                                        true,
                                        "Status"
                                      );
                                    }}
                                  ></i>
                                )}
                              </td>
                              <td>{newsletter.url}</td>

                              <td className="action">
                                <div className="dropdown ">
                                  <i
                                    className="bx bx-trash me-1"
                                    onClick={() => {
                                      delete_newsletter(newsletter._id);
                                    }}
                                  ></i>

                                  <i
                                    className="bx bx-edit me-1"
                                    onClick={() => {
                                      openNewsEdit(newsletter);
                                    }}
                                  ></i>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
