import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Alert from "../componant/Alert";
import Preloader from "../componant/Preloader";
import postCon from "../context/Post/PostContext";

export const Post = (props) => {
  const [preloader, setPreloader] = useState("");

  const myContext = useContext(postCon)
  const { post,
    getAllPost, createPost, deletePost, updatePost } = myContext

  useEffect(() => {
    getAllPost()
  }, [])
  const style = {
    background: "#00000080",
    display: "block",
  };
  const [newPost, setnewPost] = useState({
    post_status: ""
  })
  const [addPostModal, setAddPostModal] = useState(false);
  //const [addsubCategoryModal, setAddsubCategoryModal] = useState(false);
  const openData = () => {
    setAddPostModal(!addPostModal);
  };

  const delete_Post = async (id) => {
    setPreloader("preShow")
    const response = await deletePost(id)
    console.log(id);
    if (response.status === "Success") {
      props.showAlert(response.message, "success")
    }
    else {
      props.showAlert(response.message, "danger")
    }
    setPreloader("")
  }
  const handleClick = async (e) => {
    e.preventDefault()
    setPreloader("preShow");
    const response = await createPost(newPost)
    console.log(newPost);
    if (response.status === "Success") {
      openData();
      props.showAlert(response.message, "success")
    } else {
      props.showAlert(response.message, "warning")
    }
    setPreloader("");
  }


  const onChange = (e) => {
    setnewPost({ ...newPost, [e.target.name]: e.target.value })
  }
  const changeStatus =async (id, status, type) => {
    console.log("cliked");
    let response={}
    if (type === "Author") {
     response= await updatePost(id, { author_status: status })

    }
    else {
     response= await updatePost(id, { post_status: status })
    }
    (response.status==="Success")?
    props.showAlert(`${type}  status is updated`,"success"):props.showAlert(response.message,"danger")
    setPreloader("")
  }

  return (
    <>
      <Preloader show={preloader} />
     
      <div className="content-wrapper">
        <div className="container-fluid flex-grow-1 container-p-y">
          <div className="fixed-button">
            <div
              className="btn btn-sm fixed_button btn-outline-primary"

            >
              <Link to={'addnewpost'}>+ add post</Link>
            </div>

          </div>
          <div className="row">
            <h4 className="card-header c_heading">
              <span className="headingcontent">
                My Posts({post.length})

              </span>

              <div className="heading_buttons">
                <input
                  className="form-control  search  mb-4"
                  placeholder="Search Posts..."
                />
              </div>
            </h4>

            <div className="col-lg-12 mb-4 ">
              <div className="card">
                <h5 className="card-header">
                  <div className="row">
                    <div className="col-lg-6 font-bold">
                      <ul className="slider_button slider-white plain-orange">
                        <li className="slider-active">Post</li>
                      </ul>
                    </div>
                    <div className="col-lg-6 text-right">
                      <input
                        type="text"
                        className="form-control search"
                        placeholder="Search Post..."
                      />
                    </div>
                  </div>
                </h5>

                <div className="table-responsive text-nowrap mb-1">
                  <table className="table table-hover" id="dataTables">
                    <thead className="order_history_header">
                      <tr>
                        <th>Image</th>

                        <th>Post Title</th>
                        <th>Content</th>
                        <th>User id</th>
                        <th>Category id</th>
                        <th>Tag</th>
                        <th>Post type</th>

                        <th>Author</th>
                        <th>Author Status</th>
                        <th>Post Status</th>
                        <th className="action">Action</th>
                      </tr>
                    </thead>
                    <tbody className="table-border-bottom-0 order_history_body">
                      {post.map((post, index) => {

                        return (<tr key={index}>
                          <td><img className="" style={{ height: "100px", width: "100px" }} src={post.f_image} alt="post_data" /></td>
                          <td>{post.post_title}</td>
                          <td>{post.content}</td>
                          <td>{post.cat_id === null ? "..." : post.cate_id.cat_id}</td>
                          <td>{post.user_id === null ? "..." : post.user_id}</td>
                          <td>{post.tag}</td>
                          <td>{post.p_type}</td>
                          <td>{post.author}</td>
                          <td>{post.author_status ?
                            <i className='bx bx-check' onClick={() => {
                              changeStatus(post._id, false, "Author")
                            }}></i> :
                            <i className='bx bx-block' onClick={() => {
                              changeStatus(post._id, true, "Author")
                            }}></i>}</td>
                          <td>{post.post_status ?
                            <i className='bx bx-check' onClick={() => {
                              changeStatus(post._id, false, "post")
                            }}></i> :
                            <i className='bx bx-block' onClick={() => {
                              changeStatus(post._id, true, "post")
                            }}></i>}</td>

                          <td className="action"><div className="dropdown ">
                            <button
                              type="button"
                              className="btn p-0 dropdown-toggle hide-arrow"
                              data-bs-toggle="dropdown"
                            >
                              <i className="bx bx-dots-vertical-rounded"></i>

                            </button>
                            <div className="dropdown-menu">
                              <p
                                className="dropdown-item"

                              >
                                <i className="bx bx-edit me-1"></i>
                                Edit
                              </p>
                              <p
                                className="dropdown-item"
                                onClick={() => {
                                  delete_Post(post._id)
                                }}
                              >
                                <i className="bx bx-trash me-1"></i>
                                Delete
                              </p>
                            </div>
                          </div></td>
                        </tr>)
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
