import React, { useContext, useEffect, useState } from "react";
import Alert from "../componant/Alert";
import Preloader from "../componant/Preloader";
import directoryCon from "../context/Directory/DirectoryContext";
import UserCon from "../context/User/UserCon";
import UrduKeyboard from "../componant/Urdu/UrduKeyboard";
import HindiKeyboard from "../componant/Hindi/HindiKeyboard";
import DataTableFooter from "../componant/DataTableFooter";

export const Directory = (props) => {
  const [preloader, setPreloader] = useState("");

  const myContext = useContext(directoryCon);
  const {
    directory,
    totalDire,
    getAllDirectory,
    createDirectory,
    deleteDirectory,
    updateDirectory,
  } = myContext;

  useEffect(() => {
    getAllDirectory(1, 10, "page");
    getallUser("user");
  }, []);

  const userCon = useContext(UserCon);
  const { user, getallUser } = userCon;

  const [query, setquery] = useState("");
  const style = {
    background: "#00000080",
    display: "block",
  };
  const [newDirectory, setnewDirectory] = useState({
    name: "",
    com_name: "",
    phone: "",
    email: "",
    address: {},
    language: localStorage.getItem("lang"),
    mobile: "",
    fax: "",
    web: false,
    status: true,
    is_supportLink: "",
  });
  const [addDirectoryModal, setAddDirectoryModal] = useState(false);
  //const [addsubCategoryModal, setAddsubCategoryModal] = useState(false);
  const openData = () => {
    setAddDirectoryModal(!addDirectoryModal);
  };

  const delete_Directory = async (id) => {
    setPreloader("preShow");
    const response = await deleteDirectory(id);
    console.log(id);
    if (response.status === "Success") {
      props.showAlert(response.message, "success");
    } else {
      props.showAlert(response.message, "danger");
    }
    setPreloader("");
  };
  const handleClick = async (e) => {
    e.preventDefault();
    setPreloader("preShow");
    if (
      localStorage.getItem("lang") === "2" ||
      localStorage.getItem("lang") === "1"
    ) {
      newDirectory.name = urdudata.name;
      address.city = urdudata.city;
      address.state = urdudata.state;
      newDirectory.com_name = urdudata.com_name;
    }
    newDirectory.address = address;
    const response = await createDirectory(newDirectory);
    console.log(newDirectory);
    if (response.status === "success") {
      openData();
      props.showAlert(response.message, "success");
    } else {
      props.showAlert(response.message, "warning");
    }
    setPreloader("");
  };

  const onChange = (e) => {
    setnewDirectory({ ...newDirectory, [e.target.name]: e.target.value });
  };
  const [address, setAddress] = useState({});
  const onAddress = (e) => {
    setAddress({ ...address, [e.target.name]: e.target.value });
  };

  const changeStatus = async (id, status, type) => {
    setPreloader("preShow");
    let response = {};
    if (type === "status") {
      response = await updateDirectory(id, { status: status });
    } else {
      response = await updateDirectory(id, { status: status });
    }
    response.status === "Success"
      ? props.showAlert(`${type}  status is updated`, "success")
      : props.showAlert(response.message, "danger");
    setPreloader("");
  };
  const onedit = (e) => {
    setupdateDirec({ ...updateDirec, [e.target.name]: e.target.value });
  };
  const onupdateAddress = (e) => {
    updateDirec.address[e.target.name] = e.target.value;
  };
  const handleUpdate = async (e) => {
    e.preventDefault();
    setPreloader("preShow");
    if (
      localStorage.getItem("lang") === "2" ||
      localStorage.getItem("lang") === "1"
    ) {
      updateDirec.name = urdudata.name;
      updateDirec.address.city = urdudata.city;
      updateDirec.address.state = urdudata.state;
      updateDirec.com_name = urdudata.com_name;
    } else {
    }

    const response = await updateDirectory(updateDirec._id, updateDirec);

    if (response.status === "Success") {
      openDirEdit({});
      props.showAlert(response.message, "success");
    } else {
      props.showAlert(response.message, "warning");
    }
    setPreloader("");
  };

  const [editDirModel, seteditDirModel] = useState(false);
  const [updateDirec, setupdateDirec] = useState({});
  const openDirEdit = (dir) => {
    seteditDirModel(!editDirModel);
    setupdateDirec(dir);
  };
  console.log(user);

  /* slider start */
  const [statusChange, setstatusChange] = useState(null);
  const clickBlock = (status) => {
    setstatusChange(status);
    console.log(statusChange);
    const st = directory.filter((directory) => {
      return directory.status === statusChange;
    });
    console.log(st);
  };
  const [urdudata, seturdudata] = useState({
    name: "",
    com_name: "",
    state: "",
    city: "",
  });
  const inputUrdu = (key, value) => {
    urdudata[key] = value;
  };

  // pagination
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  useEffect(() => {
    getAllDirectory(page, limit, "page");
    // getCatagory(page, limit, "page");
  }, [page, limit]);

  useEffect(() => {
    setPage(1);
    getAllDirectory(1, limit, "page");
    // getCatagory(1, limit, "page");
  }, [limit]);

  return (
    <>
      <Preloader show={preloader} />

      {editDirModel && (
        <div className="modal reviewModal" style={style}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Update Directory
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    openDirEdit({});
                  }}
                ></button>
              </div>
              <div className="modal-body">
                <form id="formAccountSettings">
                  <div className="row">
                    {/* category add section */}
                    <div className="col-md-12  my-1">
                      {/*   <form method="post"> */}
                      <div className="form-group">
                        <label className="form-label">Enter User Id</label>
                        <select
                          className="form-control"
                          name="user_id"
                          onChange={onedit}
                        >
                          <option value={0}>Select user</option>
                          {user.map((u, i) => {
                            return <option value={u._id}>{u.fname}</option>;
                          })}
                        </select>
                      </div>
                      <div className="form-group">
                        <label className="form-label">Enter Name</label>
                        {localStorage.getItem("lang") === "0" ? (
                          <input
                            type="text"
                            className={`form-control   m-0`}
                            name="name"
                            id=""
                            aria-describedby="helpId"
                            placeholder="Enter Name"
                            defaultValue={updateDirec.name}
                            onChange={onedit}
                          />
                        ) : localStorage.getItem("lang") === "1" ? (
                          <HindiKeyboard
                            inputvalue={updateDirec.name}
                            keyinput="name"
                            onchange={inputUrdu}
                          />
                        ) : localStorage.getItem("lang") === "2" ? (
                          <UrduKeyboard
                            inputvalue={updateDirec.name}
                            inputUrdu={inputUrdu}
                            inputkey="name"
                          />
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="form-group">
                        <label className="form-label">Enter Company Name</label>
                        {localStorage.getItem("lang") === "0" ? (
                          <input
                            type="text"
                            className={`form-control   m-0`}
                            name="com_name"
                            id=""
                            aria-describedby="helpId"
                            placeholder="Enter Company Name"
                            defaultValue={updateDirec.com_name}
                            onChange={onedit}
                          />
                        ) : localStorage.getItem("lang") === "1" ? (
                          <HindiKeyboard
                            inputvalue={updateDirec.com_name}
                            keyinput="com_name"
                            onchange={inputUrdu}
                          />
                        ) : localStorage.getItem("lang") === "2" ? (
                          <UrduKeyboard
                            inputvalue={updateDirec.com_name}
                            inputUrdu={inputUrdu}
                            inputkey="com_name"
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <label className="form-label">Enter Phone Number</label>
                        <input
                          type="text"
                          className="form-control    m-0"
                          name="phone"
                          id=""
                          aria-describedby="helpId"
                          value={updateDirec.phone}
                          placeholder="Enter Phone Number"
                          onChange={onedit}
                        />
                      </div>

                      <div className="form-group">
                        <label className="form-label">Enter Email</label>
                        <input
                          type="text"
                          className="form-control    m-0"
                          name="email"
                          id=""
                          aria-describedby="helpId"
                          value={updateDirec.email}
                          placeholder="Enter Email"
                          onChange={onedit}
                        />
                      </div>
                      <div className="form-group">
                        <label className="form-label">Enter City</label>
                        {localStorage.getItem("lang") === "0" ? (
                          <input
                            type="text"
                            className={`form-control   m-0`}
                            name="city"
                            id=""
                            aria-describedby="helpId"
                            placeholder="Enter City"
                            defaultValue={updateDirec.city}
                            onChange={onupdateAddress}
                          />
                        ) : localStorage.getItem("lang") === "1" ? (
                          <HindiKeyboard
                            inputvalue={updateDirec.city}
                            keyinput="city"
                            onchange={inputUrdu}
                          />
                        ) : localStorage.getItem("lang") === "2" ? (
                          <UrduKeyboard
                            inputvalue={updateDirec.city}
                            inputUrdu={inputUrdu}
                            inputkey="city"
                          />
                        ) : (
                          ""
                        )}
                      </div>

                      <div className="form-group">
                        <label className="form-label">Enter State</label>
                        {localStorage.getItem("lang") === "0" ? (
                          <input
                            type="text"
                            className={`form-control   m-0`}
                            name="state"
                            id=""
                            aria-describedby="helpId"
                            placeholder="Enter state"
                            defaultValue={updateDirec.state}
                            onChange={onupdateAddress}
                          />
                        ) : localStorage.getItem("lang") === "1" ? (
                          <HindiKeyboard
                            inputvalue={updateDirec.state}
                            keyinput="state"
                            onchange={inputUrdu}
                          />
                        ) : localStorage.getItem("lang") === "2" ? (
                          <UrduKeyboard
                            inputvalue={updateDirec.state}
                            inputUrdu={inputUrdu}
                            inputkey="state"
                          />
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="form-group">
                        <label className="form-label">Enter Country</label>
                        <input
                          type="text"
                          className="form-control    m-0"
                          name="country"
                          id=""
                          aria-describedby="helpId"
                          defaultValue={updateDirec.address.country}
                          placeholder="Enter Country"
                          onChange={onedit}
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <label className="form-label">Enter Pincode</label>
                        <input
                          type="text"
                          className="form-control    m-0"
                          name="pincode"
                          id=""
                          aria-describedby="helpId"
                          defaultValue={updateDirec.address.pincode}
                          placeholder="Enter Pincode"
                          onChange={onedit}
                        />
                      </div>
                      <div className="form-group">
                        <label className="form-label">
                          Enter Mobile Number
                        </label>
                        <input
                          type="text"
                          className="form-control    m-0"
                          name="mobile"
                          id=""
                          aria-describedby="helpId"
                          value={updateDirec.mobile}
                          placeholder="Enter Mobile Number"
                          onChange={onedit}
                        />
                      </div>
                      <div className="form-group">
                        <label className="form-label">Enter Fax</label>
                        <input
                          type="text"
                          className="form-control    m-0"
                          name="fax"
                          id=""
                          aria-describedby="helpId"
                          value={updateDirec.fax}
                          placeholder="Enter Fax"
                          onChange={onedit}
                        />
                      </div>
                      <div className="form-group">
                        <label className="form-label">Enter Web</label>
                        <input
                          type="text"
                          className="form-control    m-0"
                          name="web"
                          id=""
                          aria-describedby="helpId"
                          value={updateDirec.web}
                          placeholder="Enter Web"
                          onChange={onedit}
                        />
                      </div>
                      <div className="form-group">
                        <label className="form-label"> Status</label>
                        <table width="100%">
                          <tr>
                            <td>
                              <div className="form-check">
                                <input
                                  type="radio"
                                  className="form-check-input"
                                  name="status"
                                  value={false}
                                  defaultChecked={
                                    updateDirec.status ? false : true
                                  }
                                  onChange={onedit}
                                />
                                <label className="form-label"> Draft</label>
                              </div>
                            </td>
                            <td>
                              <div className="form-check">
                                <input
                                  type="radio"
                                  className="form-check-input"
                                  name="status"
                                  value={true}
                                  defaultChecked={
                                    updateDirec.status ? true : false
                                  }
                                  onChange={onedit}
                                />
                                <label className="form-label"> Publish</label>
                              </div>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                  {/* category add section end */}

                  <div className="mt-3">
                    <button
                      type="reset"
                      className="btn btn-outline-primary me-2 my-btn"
                      onClick={() => {
                        openDirEdit({});
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="btn orange-btn btn-primary me-2"
                      onClick={handleUpdate}
                    >
                      Update
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
      {addDirectoryModal && (
        <div className="modal reviewModal" style={style}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Add New Directory
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={openData}
                ></button>
              </div>
              <div className="modal-body">
                <form
                  id="formAccountSettings"
                  method="POST"
                  onSubmit={handleClick}
                >
                  <div className="row">
                    {/* category add section */}
                    <div className="col-md-12  my-1">
                      {/*   <form method="post"> */}
                      <div className="form-group">
                        <label className="form-label">Enter User Id</label>
                        <select
                          className="form-control"
                          name="user_id"
                          onChange={onChange}
                        >
                          <option value={0}>Select user</option>
                          {user.map((user, i) => {
                            return (
                              <option value={user._id}>{user.fname}</option>
                            );
                          })}
                        </select>
                      </div>
                      <div className="form-group">
                        <label className="form-label">Enter Name</label>
                        {localStorage.getItem("lang") === "0" ? (
                          <input
                            type="text"
                            className={`form-control   m-0`}
                            name="name"
                            id=""
                            aria-describedby="helpId"
                            placeholder="Name"
                            onChange={onChange}
                          />
                        ) : localStorage.getItem("lang") === "1" ? (
                          <HindiKeyboard keyinput="name" onchange={inputUrdu} />
                        ) : localStorage.getItem("lang") === "2" ? (
                          <UrduKeyboard inputUrdu={inputUrdu} inputkey="name" />
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="form-group">
                        <label className="form-label">Enter Company Name</label>
                        {localStorage.getItem("lang") === "0" ? (
                          <input
                            type="text"
                            className={`form-control   m-0`}
                            name="com_name"
                            id=""
                            aria-describedby="helpId"
                            placeholder="Name"
                            onChange={onChange}
                          />
                        ) : localStorage.getItem("lang") === "1" ? (
                          <HindiKeyboard
                            keyinput="com_name"
                            onchange={inputUrdu}
                          />
                        ) : localStorage.getItem("lang") === "2" ? (
                          <UrduKeyboard
                            inputUrdu={inputUrdu}
                            inputkey="com_name"
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <label className="form-label">Enter Phone Number</label>
                        <input
                          type="text"
                          className="form-control    m-0"
                          name="phone"
                          id=""
                          aria-describedby="helpId"
                          placeholder="Enter Phone Number"
                          onChange={onChange}
                        />
                      </div>

                      <div className="form-group">
                        <label className="form-label">Enter Email</label>
                        <input
                          type="text"
                          className="form-control    m-0"
                          name="email"
                          id=""
                          aria-describedby="helpId"
                          placeholder="Enter Email"
                          onChange={onChange}
                        />
                      </div>
                      <div className="form-group">
                        <label className="form-label">Enter City</label>
                        {localStorage.getItem("lang") === "0" ? (
                          <input
                            type="text"
                            className={`form-control   m-0`}
                            name="city"
                            id=""
                            aria-describedby="helpId"
                            placeholder="Name"
                            onChange={onAddress}
                          />
                        ) : localStorage.getItem("lang") === "1" ? (
                          <HindiKeyboard keyinput="city" onchange={inputUrdu} />
                        ) : localStorage.getItem("lang") === "2" ? (
                          <UrduKeyboard inputUrdu={inputUrdu} inputkey="city" />
                        ) : (
                          ""
                        )}
                      </div>

                      <div className="form-group">
                        <label className="form-label">Enter State</label>
                        {localStorage.getItem("lang") === "0" ? (
                          <input
                            type="text"
                            className={`form-control   m-0`}
                            name="state"
                            id=""
                            aria-describedby="helpId"
                            placeholder="Name"
                            onChange={onAddress}
                          />
                        ) : localStorage.getItem("lang") === "1" ? (
                          <HindiKeyboard
                            keyinput="state"
                            onchange={inputUrdu}
                          />
                        ) : localStorage.getItem("lang") === "2" ? (
                          <UrduKeyboard
                            inputUrdu={inputUrdu}
                            inputkey="state 
                                "
                          />
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="form-group">
                        <label className="form-label">Enter Country</label>
                        <input
                          type="text"
                          className="form-control    m-0"
                          name="country"
                          id=""
                          aria-describedby="helpId"
                          placeholder="Enter Country"
                          onChange={onAddress}
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <label className="form-label">Enter Pincode</label>
                        <input
                          type="text"
                          className="form-control    m-0"
                          name="pincode"
                          id=""
                          aria-describedby="helpId"
                          placeholder="Enter Pincode"
                          onChange={onAddress}
                        />
                      </div>
                      <div className="form-group">
                        <label className="form-label">
                          Enter Mobile Number
                        </label>
                        <input
                          type="text"
                          className="form-control    m-0"
                          name="mobile"
                          id=""
                          aria-describedby="helpId"
                          placeholder="Enter Mobile Number"
                          onChange={onChange}
                        />
                      </div>
                      <div className="form-group">
                        <label className="form-label">Enter Fax</label>
                        <input
                          type="text"
                          className="form-control    m-0"
                          name="fax"
                          id=""
                          aria-describedby="helpId"
                          placeholder="Enter Fax"
                          onChange={onChange}
                        />
                      </div>
                      <div className="form-group">
                        <label className="form-label">Enter Web</label>
                        <input
                          type="text"
                          className="form-control    m-0"
                          name="web"
                          id=""
                          aria-describedby="helpId"
                          placeholder="Enter Web"
                          onChange={onChange}
                        />
                      </div>
                      <div className="form-group">
                        <label className="form-label"> Status</label>
                        <table width="100%">
                          <tr>
                            <td>
                              <div className="form-check">
                                <input
                                  type="radio"
                                  className="form-check-input"
                                  name="status"
                                  value={0}
                                  onChange={onChange}
                                />
                                <label className="form-label"> Draft</label>
                              </div>
                            </td>
                            <td>
                              <div className="form-check">
                                <input
                                  type="radio"
                                  className="form-check-input"
                                  name="status"
                                  value={1}
                                  onChange={onChange}
                                />
                                <label className="form-label"> Publish</label>
                              </div>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                  {/* category add section end */}

                  <div className="mt-3">
                    <button
                      type="reset"
                      className="btn btn-outline-primary me-2 my-btn"
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="btn orange-btn btn-primary me-2"
                      onClick={handleClick}
                    >
                      Add
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="content-wrapper">
        <div className="container-fluid flex-grow-1 container-p-y">
          <div className="fixed-button">
            <div
              className={`btn btn-sm fixed_button btn-outline-primary ${
                addDirectoryModal ? "d-none" : "d-block"
              }`}
              onClick={openData}
            >
              + Add New Directory
            </div>
          </div>
          <div className="row">
            <h4 className="card-header c_heading">
              <span className="headingcontent">My Directory({totalDire})</span>
            </h4>

            <div className="col-lg-12 mb-5 pb-5 ">
              <div className="card">
                <h5 className="card-header">
                  <div className="row">
                    <div className="col-lg-6 font-bold">
                      <ul className="slider_button slider-white plain-orange">
                        <li
                          className={`${
                            statusChange === null ? "slider-active" : ""
                          }`}
                          onClick={() => {
                            clickBlock(null);
                          }}
                        >
                          All
                        </li>
                        <li
                          className={`${
                            statusChange !== null && statusChange === false
                              ? "slider-active"
                              : ""
                          }`}
                          onClick={() => {
                            clickBlock(false);
                          }}
                        >
                          Block
                        </li>
                        <li
                          className={`${
                            statusChange !== null && statusChange === true
                              ? "slider-active"
                              : ""
                          }`}
                          onClick={() => {
                            clickBlock(true);
                          }}
                        >
                          Unblock
                        </li>
                      </ul>
                    </div>
                    <div className="col-lg-6 text-right">
                      <input
                        type="text"
                        className="form-control search"
                        placeholder="Search Directory..."
                        onChange={(e) => {
                          setquery(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </h5>

                <div className="table-responsive text-nowrap mb-1">
                  <table className="table table-hover">
                    <thead className="order_history_header">
                      <tr>
                        <th className="action">Action</th>
                        <th>Old ID</th>
                        <th>Name</th>
                        <th>Company Name</th>
                        <th>Phone</th>
                        <th>Email</th>
                        <th>Address</th>
                        {/* <th>Mobile</th> */}
                        {/*  <th>Fax</th> */}
                        {/* <th>Web</th> */}
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody className="table-border-bottom-0 order_history_body">
                      {directory
                        .filter((directory) => {
                          return (
                            (statusChange === null ||
                              directory.status === statusChange) &&
                            (directory.name
                              ?.toLowerCase()
                              .includes(query?.toLowerCase()) ||
                              directory.phone
                                ?.toString()
                                .toLowerCase()
                                .includes(query?.toLowerCase()) ||
                              directory.email
                                ?.toLowerCase()
                                .includes(query?.toLowerCase()) ||
                              directory.com_name
                                ?.toLowerCase()
                                .includes(query?.toLowerCase()))
                          );
                        })
                        .map((directory, index) => {
                          return (
                            <tr key={index}>
                              <td className="">
                                <div className="dropdown d-flex">
                                  <span className="badge bg-danger me-1">
                                    <i
                                      className="bx bx-trash"
                                      onClick={() => {
                                        delete_Directory(directory._id);
                                      }}
                                    ></i>
                                  </span>
                                  <span className="badge bg-secondary">
                                    <i
                                      className="bx bx-edit"
                                      onClick={() => {
                                        openDirEdit(directory);
                                      }}
                                    ></i>
                                  </span>
                                </div>
                              </td>
                              <td>{directory.id}</td>
                              {/*  <td>{directory.user_id === null ? "..." : directory.user_id.fname}</td> */}
                              <td>{directory.name}</td>
                              <td>
                                {directory.com_name === ""
                                  ? "-"
                                  : directory.com_name}
                              </td>
                              <td>{directory.phone}</td>
                              <td>{directory.email}</td>
                              <td>
                                {directory.address}, {directory.city},{" "}
                                {directory.state}, {directory.country} (
                                {directory.pincode})
                              </td>
                              {/* <td>{directory.mobile}</td> */}
                              {/*    <td>{directory.fax}</td> */}
                              {/* <td>{directory.web}</td> */}
                              <td>
                                {directory.status ? (
                                  <span className="badge bg-success">
                                    <i
                                      className="bx bx-check"
                                      onClick={() => {
                                        changeStatus(
                                          directory._id,
                                          false,
                                          "directory"
                                        );
                                      }}
                                    ></i>
                                  </span>
                                ) : (
                                  <span className="badge bg-danger">
                                    <i
                                      className="bx bx-block"
                                      onClick={() => {
                                        changeStatus(
                                          directory._id,
                                          true,
                                          "directory"
                                        );
                                      }}
                                    ></i>
                                  </span>
                                )}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                    <DataTableFooter
                      col={7}
                      range={
                        (page === 1 ? "1" : (page - 1) * limit) +
                        "-" +
                        (page === 1 ? limit : page * limit)
                      }
                      total_item={totalDire}
                      optionChange={(e) => {
                        setLimit(e.target.value);
                      }}
                      prePage={() => {
                        if (page > 1) {
                          setPage(page - 1);
                        }
                      }}
                      nextPage={() => {
                        setPage(page + 1);
                      }}
                    />
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
