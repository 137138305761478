import React, { useState } from "react";
import PageCon from "./PageCon";
/* require("dotenv").config(); */
const PageState = (props) => {
  const host = process.env.REACT_APP_URL;
  console.log(host);
  const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7ImlkIjoiNjNkZjg4YWFhNDllYjE2NzFjYTdkZGNhIn0sImlhdCI6MTY3NTU5NTEzN30.xGYO_zIQPGR5YvcYhQFDlInCydDomn6Lrsqv6bZmrMQ";
  console.log(token);


  const [page, setPage] = useState([]);

   const createPage = async (body) => {
    try {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "auth-token": token
        }, body: JSON.stringify(body)
      };
      const response = await fetch(
        `${host}/api/page/addPage`,
        requestOptions
      );
      const dataresp = await response.json();
      console.log(dataresp);
      if (dataresp.status === "success") {
        setPage(page.concat(dataresp.data))
       
      }
    
      return dataresp

    }
    catch (e) {

    }
  } 
  const deletePage = async (id) => {
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "auth-token": token
      },
    };
    const response = await fetch(
      `${host}/api/page/deletePage/${id}`,
      requestOptions
    );
    const datares = await response.json();

    console.log(datares);
    setPage(datares.data);
    if(datares.status=== "Success"){
      const updateData = page.filter((cat)=>{
        return cat._id!==id

      })
      setPage(updateData)
    }
    return datares
  };
  const getAllPage = async (id) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "auth-token": token
      },
    };
    const response = await fetch(
      `${host}/api/page/getPage`,
      requestOptions
    );
    const datares = await response.json();

    console.log(datares);
    setPage(datares.data);
  };
  const updateAllPage = async (id,body) => {
    try {
      const requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "auth-token": token
        }, body: JSON.stringify(body)
      };
      const response = await fetch(
        `${host}/api/page/updatePage/${id}`,
        requestOptions
      );
      const dataresp = await response.json();
      console.log(dataresp);
      if (dataresp.status === "Success") {

        for(let i = 0; i < page.length; i++){
          let element = page[i];
          if(element._id.toString() === id){
            page[i] = dataresp.data;
          }
        }
        setPage(page);
      }
      return dataresp;

    }
    catch (e) {

    }
  }

  return (
    <PageCon.Provider
      value={{
        page,
        getAllPage,
        deletePage,
        createPage,
        updateAllPage
       
      }}

    >
      {props.children}
    </PageCon.Provider>
  );
}
export default PageState;