import React, { useState } from "react";
import WriterCon from "./WriterCon";
/* require("dotenv").config(); */
const WriterState = (props) => {
  const host = process.env.REACT_APP_URL;
  console.log(host);
  const token = localStorage.getItem("token");
  console.log(token);


  const [writer_info, setWriter_info] = useState([]);

   const addWriter_info  = async (body) => {
    try {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "auth-token": token
        }, body: JSON.stringify(body)
      };
      const response = await fetch(
        `${host}/api/writer_info/addwriter_info`,
        requestOptions
      );
      const datares = await response.json();
      console.log(datares);
      if (datares.status === "success") {
        setWriter_info(writer_info.concat(datares.data))
        
      }
     return datares

    }
    catch (e) {

    }
  } 
  const deleteWriter_info = async (id) => {
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "auth-token": token
      },
    };
    const response = await fetch(
      `${host}/api/writer_info/deleteWriter_info/${id}`,
      requestOptions
    );
    const datares = await response.json();

    console.log(datares);
    
    if(datares.status=== "Success"){
      const updateData = writer_info.filter((cat)=>{
        return cat._id!==id

      })

      setWriter_info(updateData)
    }
    return datares
  };
  const getAllWriter_info  = async (id) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "auth-token": token
      },
    };
    const response = await fetch(
      `${host}/api/writer_info/getWriter_info`,
      requestOptions
    );
    const datares = await response.json();

    console.log(datares);
    setWriter_info(datares.data);
  };

  const updateWriter = async (body,id) => {
    try {
      const requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "auth-token": token
        }, body: JSON.stringify(body)
      };
      const response = await fetch(
        `${host}/api/writer_info/updateWriter_info/${id}`,
        requestOptions
      );
      const dataresp = await response.json();
      console.log(dataresp);
      
      if (dataresp.status === "Success") {
        
        const updatewriter = writer_info.map((wri, i)=>{
          return (wri._id.toString() === id) ? dataresp.data:wri;
        })
        
        setWriter_info(updatewriter);
      }
      return dataresp;

    }
    catch (e) {

    }
  }
  return (
    <WriterCon.Provider
      value={{
        writer_info,
        getAllWriter_info,
        deleteWriter_info,
        updateWriter,
        addWriter_info
       
      }}

    >
      {props.children}
    </WriterCon.Provider>
  );
}
export default WriterState;