import React, { useContext, useEffect, useState } from "react";
import Preloader from "../componant/Preloader";
import BannerCon from "../context/Banner/BannerCon";
import ChooseMedia from "../componant/Media/ChooseMedia";

export const Banner = (props) => {
  const [preloader, setPreloader] = useState("");

  const myContext = useContext(BannerCon);
  const { banner, getAllbanner, addbanner, deletebanner, updateBanner } =
    myContext;

  useEffect(() => {
    getAllbanner();
  }, []);
  const style = {
    background: "#00000080",
    display: "block",
  };
  const [newbanner, setnewbanner] = useState({
    title: "",
    last_ip: "",
    status: false,
    type: false,
    live_banner: true,
    language: localStorage.getItem("lang"),
  });
  const [addbannerModal, setAddbannerModal] = useState(false);
  const openData = () => {
    setAddbannerModal(!addbannerModal);
    newbanner.file = "";
  };

  const delete_banner = async (id) => {
    setPreloader("preShow");
    const response = await deletebanner(id);
    console.log(id);
    if (response.status === "Success") {
      props.showAlert(response.message, "success");
    } else {
      props.showAlert(response.message, "danger");
    }
    setPreloader("");
  };
  const handleClick = async (e) => {
    e.preventDefault();
    setPreloader("preShow");
    const response = await addbanner(newbanner);
    console.log(newbanner);
    if (response.status === "success") {
      openData();
      props.showAlert(response.message, "success");
    } else {
      props.showAlert(response.message, "warning");
    }
    setPreloader("");
  };
  const selectImage = (url) => {
    openMedia();
    if (editBanModel) {
      setupdateban({ ...updateban, file: url });
    } else {
      setnewbanner({ ...newbanner, file: url });
    }
  };
  const [mediaModel, setmediaModel] = useState(false);

  const openMedia = async () => {
    setmediaModel(!mediaModel);
    if (!mediaModel) {
      setPreloader("preShow");

      setPreloader("");
    }
  };

  const onChange = (e) => {
    setnewbanner({ ...newbanner, [e.target.name]: e.target.value });
    console.log(newbanner);
  };
  const changeStatus = async (id, status, type) => {
    setPreloader("preShow");
    let response = {};
    if (type === "lightWindow") {
      response = await updateBanner(id, { lightWindow: status });
    } else {
      response = await updateBanner(id, { status: status });
    }
    response.status === "Success"
      ? props.showAlert(`${type}  status is updated`, "success")
      : props.showAlert(response.message, "danger");
    setPreloader("");
  };
  const [query, setquery] = useState("");

  /* edit section */
  const onedit = (e) => {
    setupdateban({ ...updateban, [e.target.name]: e.target.value });
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    setPreloader("preShow");
    const response = await updateBanner(updateban._id, updateban);

    if (response.status === "Success") {
      openBanEdit({});
      props.showAlert(response.message, "success");
    } else {
      props.showAlert(response.message, "warning");
    }
    setPreloader("");
  };
  const [editBanModel, seteditBanModel] = useState(false);
  const [updateban, setupdateban] = useState({});
  const openBanEdit = (cat) => {
    seteditBanModel(!editBanModel);
    if (cat) {
      setupdateban(cat);
    }
  };

  const change_input_f = (e) => {
    let st = false;
    if (e.target.value === "true") {
      st = true;
    }
    setnewbanner({ ...newbanner, is_video: st });

    console.log(newbanner);
  };

  /* slider start */
  const [statusChange, setstatusChange] = useState(null);
  const clickBlock = (status) => {
    setstatusChange(status);
    console.log(statusChange);
    const st = banner.filter((banner) => {
      return banner.status === statusChange;
    });
    console.log(st);
  };
  /* slider ends */

  return (
    <>
      <Preloader show={preloader} />
      {editBanModel && (
        <div className="modal reviewModal" style={style}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Update banner
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    openBanEdit();
                  }}
                ></button>
              </div>
              <div className="modal-body">
                <form id="formAccountSettings">
                  <div className="row">
                    {/* category add section */}
                    <div className="col-md-12  my-1">
                      {/* <div className="form-group">
                        <label className="form-label"> Select Ad Type</label>
                        <table width="100%">
                          <tr>
                            <td>
                              <div className="form-check">
                                <input
                                  type="radio"
                                  className="form-check-input"
                                  name="type"
                                  value={true}
                                  onChange={onedit}
                                />
                                <label className="form-label"> Image</label>
                              </div>
                            </td>
                            <td>
                              <div className="form-check">
                                <input
                                  type="radio"
                                  className="form-check-input"
                                  name="type"
                                  value={false}
                                  defaultChecked={
                                    updateban.status ? false : true
                                  }
                                  onChange={onedit}
                                />
                                <label className="form-label"> Vedio</label>
                              </div>
                            </td>
                          </tr>
                        </table>
                      </div> */}

                      <div className="form-group">
                        <label className="form-label">Enter Title</label>
                        <input
                          type="text"
                          className="form-control    m-0"
                          name="title"
                          id=""
                          aria-describedby="helpId"
                          defaultValue={updateban.title}
                          placeholder="Enter Title"
                          onChange={onedit}
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      {updateban.is_video === true ? (
                        <>
                          <div className="form-group">
                            <label className="form-label">Enter URL</label>
                            <input
                              type="text"
                              className="form-control    m-0"
                              name="URL"
                              id=""
                              aria-describedby="helpId"
                              defaultValue={updateban.url}
                              placeholder="Enter URL"
                              onChange={onedit}
                            />
                          </div>

                          <div className="form-group">
                            <label className="form-label">Choose Image</label>

                            <div className="" onClick={openMedia}>
                              <img
                                className="img-fluid"
                                src={
                                  updateban.file === "" ||
                                  updateban.file === undefined
                                    ? "./assets/img/illustrations/upload.png"
                                    : updateban.file
                                }
                                alt="selected_image"
                                width={150}
                              />
                            </div>
                          </div>

                          {/* <div className="form-group">
                            <label className="form-label">Choose Image</label>
                            <div
                              className="image_div_content"
                              onClick={openMedia}
                            >
                              <i className="bx bxs-image-add"></i>
                              <p className="normalhyperlink">click to upload</p>
                            </div>
                            <div className="">
                              <img
                                className="img-fluid"
                                src={newbanner.file}
                                alt="selected_image"
                              />
                            </div>
                          </div> */}

                          <div className="form-group">
                            <label className="form-label">Enter Location</label>
                            <input
                              type="text"
                              className="form-control    m-0"
                              name="location"
                              id=""
                              aria-describedby="helpId"
                              defaultValue={updateban.location}
                              placeholder="Enter Location"
                              onChange={onedit}
                            />
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="form-group">
                            <label className="form-label">
                              Enter Embed Code
                            </label>
                            <input
                              type="text"
                              className="form-control    m-0"
                              name="embed_code"
                              id=""
                              aria-describedby="helpId"
                              defaultValue={updateban.embed_code}
                              placeholder="Enter Embed Code"
                              onChange={onedit}
                            />
                          </div>
                        </>
                      )}
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <label className="form-label">Enter Expiry Date</label>
                        <input
                          type="date"
                          className="form-control    m-0"
                          name="expiry_date"
                          id=""
                          aria-describedby="helpId"
                          defaultValue={updateban.expiry_date}
                          placeholder="Enter Expiry Date"
                          onChange={onedit}
                        />
                      </div>
                      <div className="form-group">
                        <label className="form-label"> Status</label>
                        <table width="100%">
                          <tr>
                            <td>
                              <div className="form-check">
                                <input
                                  type="radio"
                                  className="form-check-input"
                                  name="status"
                                  value={false}
                                  defaultChecked={
                                    updateban.status ? false : true
                                  }
                                  onChange={onedit}
                                />
                                <label className="form-label"> Draft</label>
                              </div>
                            </td>
                            <td>
                              <div className="form-check">
                                <input
                                  type="radio"
                                  className="form-check-input"
                                  name="status"
                                  value={true}
                                  defaultChecked={
                                    updateban.status ? true : false
                                  }
                                  onChange={onedit}
                                />
                                <label className="form-label"> Publish</label>
                              </div>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                  {/* category add section end */}

                  <div className="mt-3">
                    <button
                      type="reset"
                      className="btn btn-outline-primary me-2 my-btn"
                      onClick={() => {
                        openBanEdit({});
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="btn orange-btn btn-primary me-2"
                      onClick={handleUpdate}
                    >
                      Update
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
      {addbannerModal && (
        <div className="modal reviewModal" style={style}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Add New banner
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={openData}
                ></button>
              </div>
              <div className="modal-body">
                <form
                  id="formAccountSettings"
                  method="POST"
                  onSubmit={handleClick}
                >
                  <div className="row">
                    {/* category add section */}
                    <div className="col-md-12  my-1">
                      <div className="form-group">
                        <label className="form-label"> Select Ad Type</label>
                        <table width="100%">
                          <tr>
                            <td>
                              <div className="form-check">
                                <input
                                  type="radio"
                                  className="form-check-input"
                                  name="is_video"
                                  defaultChecked={false}
                                  value={true}
                                  onChange={change_input_f}
                                />
                                <label className="form-label"> Image</label>
                              </div>
                            </td>
                            <td>
                              <div className="form-check">
                                <input
                                  type="radio"
                                  className="form-check-input"
                                  name="is_video"
                                  defaultChecked={true}
                                  value={false}
                                  onChange={change_input_f}
                                />
                                <label className="form-label"> Vedio</label>
                              </div>
                            </td>
                          </tr>
                        </table>
                      </div>

                      <div className="form-group">
                        <label className="form-label">Enter Title</label>
                        <input
                          type="text"
                          className="form-control    m-0"
                          name="title"
                          id=""
                          aria-describedby="helpId"
                          placeholder="Enter Title"
                          onChange={onChange}
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      {newbanner.is_video === true ? (
                        <>
                          <div className="form-group">
                            <label className="form-label">Enter URL</label>
                            <input
                              type="text"
                              className="form-control    m-0"
                              name="URL"
                              id=""
                              aria-describedby="helpId"
                              placeholder="Enter URL"
                              onChange={onChange}
                            />
                          </div>

                          <div className="form-group">
                            <label className="form-label">Choose Image</label>

                            <div className="" onClick={openMedia}>
                              <img
                                className="img-fluid"
                                src={
                                  newbanner.file === "" ||
                                  newbanner.file === undefined
                                    ? "./assets/img/illustrations/upload.png"
                                    : newbanner.file
                                }
                                alt="selected_image"
                                width={150}
                              />
                            </div>
                          </div>

                          <div className="form-group">
                            <label className="form-label">Enter Location</label>
                            <input
                              type="text"
                              className="form-control    m-0"
                              name="location"
                              id=""
                              aria-describedby="helpId"
                              placeholder="Enter Location"
                              onChange={onChange}
                            />
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="form-group">
                            <label className="form-label">
                              Enter Embed Code
                            </label>
                            <input
                              type="text"
                              className="form-control    m-0"
                              name="embed_code"
                              id=""
                              aria-describedby="helpId"
                              placeholder="Enter Embed Code"
                              onChange={onChange}
                            />
                          </div>
                        </>
                      )}
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <label className="form-label">Enter Expiry Date</label>
                        <input
                          type="date"
                          className="form-control    m-0"
                          name="expiry_date"
                          id=""
                          aria-describedby="helpId"
                          placeholder="Enter Expiry Date"
                          onChange={onChange}
                        />
                      </div>
                    </div>
                  </div>
                  {/* category add section end */}

                  <div className="mt-3">
                    <button
                      type="reset"
                      className="btn btn-outline-primary me-2 my-btn"
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="btn orange-btn btn-primary me-2"
                      onClick={handleClick}
                    >
                      Add
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
      {mediaModel && (
        <ChooseMedia
          open={openMedia}
          selectImage={selectImage}
          showAlert={props.showAlert}
        />
      )}
      <div className="content-wrapper">
        <div className="container-fluid flex-grow-1 container-p-y">
          <div className="fixed-button">
            <div
              className={`btn btn-sm fixed_button btn-outline-primary ${
                addbannerModal ? "d-none" : "d-block"
              }`}
              onClick={openData}
            >
              + Add New banner
            </div>
          </div>
          <div className="row">
            <h4 className="card-header c_heading">
              <span className="headingcontent">My banner({banner.length})</span>
            </h4>

            <div className="col-lg-12 mb-4 ">
              <div className="card">
                <h5 className="card-header">
                  <div className="row">
                    <div className="col-lg-6 font-bold">
                      <ul className="slider_button slider-white plain-orange">
                        <li
                          className={`${
                            statusChange === null ? "slider-active" : ""
                          }`}
                          onClick={() => {
                            clickBlock(null);
                          }}
                        >
                          All
                        </li>
                        <li
                          className={`${
                            statusChange !== null && statusChange === false
                              ? "slider-active"
                              : ""
                          }`}
                          onClick={() => {
                            clickBlock(false);
                          }}
                        >
                          Block
                        </li>
                        <li
                          className={`${
                            statusChange !== null && statusChange === true
                              ? "slider-active"
                              : ""
                          }`}
                          onClick={() => {
                            clickBlock(true);
                          }}
                        >
                          Unblock
                        </li>
                      </ul>
                    </div>
                    <div className="col-lg-6 text-right">
                      <input
                        type="text"
                        className="form-control search"
                        placeholder="Search Banners..."
                        onChange={(e) => {
                          setquery(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </h5>

                <div className="table-responsive text-nowrap mb-1">
                  <table className="table table-hover">
                    <thead className="order_history_header">
                      <tr>
                        <th className="action">Action</th>
                        <th>File</th>
                        <th>Title</th>
                        <th> URL</th>
                        <th>Location</th>

                        <th>Embed Code</th>
                        <th>Expiry Date</th>
                        <th> Status</th>
                        <th>Views</th>
                        <th>Light Window</th>
                        <th>Uploaded Date</th>
                      </tr>
                    </thead>
                    <tbody className="table-border-bottom-0 order_history_body">
                      {banner
                        .filter((banner) => {
                          return (
                            (statusChange === null ||
                              banner.status === statusChange) &&
                            banner.title
                              .toLowerCase()
                              .includes(query.toLowerCase())
                          );
                        })
                        .map((banner, index) => {
                          const date = banner.date.split("T");
                          const e_date = banner.expiry_date.split("T");
                          return (
                            <tr key={index}>
                              <td className="action">
                                <div className="dropdown d-flex">
                                  <span className="badge bg-danger me-1 ">
                                    <i
                                      className="bx bx-trash  "
                                      onClick={() => {
                                        delete_banner(banner._id);
                                      }}
                                    ></i>
                                  </span>
                                  <span className="badge bg-secondary ">
                                    <i
                                      className="bx bx-edit"
                                      onClick={() => {
                                        openBanEdit(banner);
                                      }}
                                    ></i>
                                  </span>
                                </div>
                              </td>
                              <td
                              className="text-center"
                                onClick={() => {
                                  const link = `${process.env.REACT_APP_URL}/media/banners/${banner.file}`;
                                  window.open(link);
                                }}
                              >
                                {banner.file ? (
                                  <img
                                    className="img-fluid"
                                    style={{ height: "100px", width: "100%" }}
                                    src={`${process.env.REACT_APP_URL}/media/banners/${banner.file}`}
                                    alt={`banner_image_${index}`}
                                  />
                                ) : (
                                  "-"
                                )}
                              </td>
                              <td>{banner.title}</td>
                              <td>{banner.url}</td>
                              <td>{banner.location}</td>

                              <td>{banner.embed_code}</td>
                              <td>{e_date[0]}</td>
                              <td>
                                {banner.status === 1 ? (
                                  <i
                                    className="bx bx-check"
                                    onClick={() => {
                                      changeStatus(banner._id, false, "Banner");
                                    }}
                                  ></i>
                                ) : (
                                  <i
                                    className="bx bx-block"
                                    onClick={() => {
                                      changeStatus(banner._id, true, "Banner");
                                    }}
                                  ></i>
                                )}
                              </td>
                              <td>{banner.views}</td>
                              <td>
                                {banner.lightWindow === 1 ? (
                                  <i
                                    className="bx bx-check"
                                    onClick={() => {
                                      changeStatus(
                                        banner._id,
                                        false,
                                        "lightWindow"
                                      );
                                    }}
                                  ></i>
                                ) : (
                                  <i
                                    className="bx bx-block"
                                    onClick={() => {
                                      changeStatus(
                                        banner._id,
                                        true,
                                        "lightWindow"
                                      );
                                    }}
                                  ></i>
                                )}
                              </td>
                              <td>{date[0]}</td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
