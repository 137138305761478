import React, { useRef, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Preloader from "../componant/Preloader";
import LoginCon from "../context/Login/LoginCon";

import "../css/login.css";


export default function Login(props) {
  const host = process.env.REACT_APP_URL;
  let history = useNavigate();

  const [modal, setModal] = useState(true);
  

  // showVerity
  const login = useContext(LoginCon);
  const { createUser, UserLogin } = login

  const changePanel = () => {
    setModal(!modal);
  };


  const [userRegistration, setUserRegistration] = useState({
    name: "",
    phone: 9999999999,
    email: "",
    password: "",
    utype: "visitor",
  });

  const handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setUserRegistration({ ...userRegistration, [name]: value });
  };

  
  // alertDialog
  const [alertMessage, setAlertMessage] = useState(false);

  // handler login

  const [loginUsers, setLoginUsers] = useState({
    phone: "",
    pass: "",
  });

  const loginOnChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setLoginUsers({ ...loginUsers, [name]: value });
  };

  const loginUser = async (e) => {
    e.preventDefault();

    // API CAll
    const res = await UserLogin(loginUsers);
    console.log(res);
    if (res.status === "Success") {
      props.showAlert(res.msg, "success");
      localStorage.setItem("token", res.token);
      history("/");
    } else {
      props.showAlert(res.msg, "danger");
    }
  };

  // handler crete

  const crateUser = async (e) => {
    e.preventDefault();
    setAlertMessage(!alertMessage);
    const newRecords = { ...userRegistration };

    try {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(newRecords),
      };
      const response = await fetch(
        `${host}/api/user/createuser`,
        requestOptions
      );
      const data = await response.json();
      if (data.status === "Failed") {
        props.showAlert(data.error, "danger");
      } else {
        props.showAlert(data.msg, "success");
        changePanel();
      }
      console.log(data);
      setAlertMessage(alertMessage);
    } catch (error) {
      alert("Something Went wrong!");
    }
  };

  // otp login data









  if (localStorage.getItem("token")) {
    props.showAlert("You're Already Logged In", "success");
    history("/");
  }
  return (
    <>
      <div className="login-page">
        {alertMessage && <Preloader />}

        <div
          className={modal ? "container " : "container  right-panel-active"}
          id="container"
        >
          {/* ###################################################################
          Create account form secction is here
          ################################################################### */}
          <div className="form-container sign-up-container ">
            <form action="#" method="POST" onSubmit={crateUser}>
              <h1>Create Account</h1>

              <input
                type="text"
                className="form-control login-input search mb-3"
                autoComplete="off"
                name="fullname"
                value={userRegistration.fullname}
                onChange={handleInput}
                placeholder="Full Name"
              />
              <input
                type="phone"
                className="form-control login-input search mb-3"
                name="phone"
                value={userRegistration.phone}
                onChange={handleInput}
                autoComplete="off"
                placeholder="Phone"
              />

              <input
                type="password"
                className="form-control login-input search mb-3"
                name="password"
                value={userRegistration.pass}
                onChange={handleInput}
                autoComplete="off"
                placeholder="Create Password"
              />
              <button type="submit">Create Account</button>
            </form>
          </div>

          {/* ###################################################################
login with password section here
################################################################### */}

          <div className="form-container sign-in-container">
            <form
              action="#"
              className={"login_form"}
              method="POST"
              onSubmit={loginUser}
            >
              <h1>Log in</h1>
              <div className="social-container">

              </div>

              <input
                type="phone"
                className="form-control login-input search mb-3"
                placeholder="Phone"
                name="phone"
                autoComplete="off"
                onChange={loginOnChange}
              />
              <input
                type="password"
                className="form-control search login-input"
                placeholder="Password"
                name="pass"
                onChange={loginOnChange}
              />
              <div className="forgetpass">Forgot your password?</div>
              <button disabled={loginUsers.pass.length < 3} type="submit">
                Log In
              </button>
            </form>

            {/* ###################################################################
          login with otp section is here
          ################################################################### */}

          </div>
          <div className="overlay-container">
            <div className="overlay">
              <div className="overlay-panel overlay-left">
                <h1>Welcome Back!</h1>
                <p>To keep connected with us please login with your info</p>
                <button className="ghost" id="signIn" onClick={changePanel}>
                  Sign In
                </button>
              </div>
              <div className="overlay-panel overlay-right">
                <h1>Hello,</h1>
                <p>Enter your details and start journey with us</p>
                <button className="ghost" id="signUp" onClick={changePanel}>
                  Create Account
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
