import React, { useContext, useEffect, useState } from "react";
import Preloader from "../componant/Preloader";
import categoryCon from "../context/Category/CategoryContext";
import Alert from "../componant/Alert";
import DataTableFooter from "../componant/DataTableFooter";

function B_Cat(props) {
  const mycontext = useContext(categoryCon);
  const {
    category,
    total_category,
    getAllCatagory,
    createCategory,
    deleteCategory,
    updateCategory,
  } = mycontext;

  useEffect(() => {
    getAllCatagory("blog");
  }, []);

  const [query, setquery] = useState("");

  const style = {
    background: "#00000080",
    display: "block",
  };
  const [addCategoryModal, setAddCategoryModal] = useState(false);
  const [preloader, setPreloader] = useState("");
  const [newCategory, setnewCategory] = useState({
    cat_name: "",
    cat_type: "blog",
  });
  const openData = () => {
    setAddCategoryModal(!addCategoryModal);
  };

  const onChange = (e) => {
    setnewCategory({ ...newCategory, [e.target.name]: e.target.value });
  };

  const handleClick = async (e) => {
    e.preventDefault();
    setPreloader("preShow");
    const response = await createCategory(newCategory);
    console.log(response);
    if (response.status === "success") {
      openData();
      props.showAlert(response.message, "success");
    } else {
      props.showAlert(response.message, "warning");
    }
    setPreloader("");
  };
  const delete_category = async (id) => {
    setPreloader("preShow");

    const response = await deleteCategory(id);
    console.log(id);
    if (response.status === "Success") {
      props.showAlert(response.message, "success");
    } else {
      props.showAlert(response.message, "warning");
    }
    setPreloader("");
  };

  /* update category */

  const onedit = (e) => {
    setupdateCat({ ...updateCat, [e.target.name]: e.target.value });
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    setPreloader("preShow");
    const response = await updateCategory(updateCat, updateCat._id);

    if (response.status === "Success") {
      openBCatEdit({});
      props.showAlert(response.message, "success");
    } else {
      props.showAlert(response.message, "warning");
    }
    setPreloader("");
  };
  const [editBCatModel, seteditBCatModel] = useState(false);
  const [updateCat, setupdateCat] = useState({});
  const openBCatEdit = (cat) => {
    console.log(cat);
    seteditBCatModel(!editBCatModel);
    setupdateCat(cat);
  };

  // pagination
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  useEffect(() => {
    getAllCatagory("blog", page, limit);
  }, [page, limit]);

  useEffect(() => {
    setPage(1);
    getAllCatagory("blog", 1, limit);
  }, [limit]);

  return (
    <>
      <Preloader show={preloader} />
      {editBCatModel && (
        <div className="modal reviewModal" style={style}>
          <div className="modal-dialog" style={{ width: "40%" }}>
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Edit Blog Category
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    openBCatEdit({});
                  }}
                ></button>
              </div>
              <div className="modal-body">
                <form
                  id="formAccountSettings"
                  method="POST"
                  onSubmit={handleUpdate}
                >
                  <div className="row">
                    {/* category add section */}
                    <div className="col-md-12  my-1">
                      <form method="post">
                        <div className="form-group">
                          <label className="form-label">
                            Enter Category Name
                          </label>
                          <input
                            type="text"
                            className="form-control    m-0"
                            name="cat_name"
                            id=""
                            aria-describedby="helpId"
                            value={updateCat.cat_name}
                            placeholder="Blog Category Name"
                            onChange={onedit}
                          />
                        </div>
                        <div className="form-group">
                          <label className="form-label">
                            Enter Meta Title{" "}
                          </label>
                          <input
                            type="text"
                            className="form-control    m-0"
                            name="meta_title"
                            id=""
                            aria-describedby="helpId"
                            value={updateCat.meta_title}
                            placeholder="Blog Category Name"
                            onChange={onedit}
                          />
                        </div>
                        <div className="form-group">
                          <label className="form-label">
                            Enter Meta Description{" "}
                          </label>
                          <input
                            type="text"
                            className="form-control    m-0"
                            name="meta_discription"
                            id=""
                            aria-describedby="helpId"
                            value={updateCat.meta_description}
                            placeholder="Enter Meta Description "
                            onChange={onedit}
                          />
                        </div>
                        <div className="form-group">
                          <label className="form-label">
                            Enter Meta Keywords{" "}
                          </label>
                          <input
                            type="text"
                            className="form-control    m-0"
                            name="meta_keywords"
                            id=""
                            aria-describedby="helpId"
                            value={updateCat.meta_keywords}
                            placeholder="Enter Meta Keywords "
                            onChange={onedit}
                          />
                        </div>
                        <div className="form-group">
                          <label className="form-label">
                            Enter Meta Others{" "}
                          </label>
                          <input
                            type="text"
                            className="form-control    m-0"
                            name="meta_others"
                            id=""
                            aria-describedby="helpId"
                            value={updateCat.meta_others}
                            placeholder="Enter Meta Others "
                            onChange={onedit}
                          />
                        </div>
                      </form>
                    </div>
                    {/* category add section end */}
                  </div>
                  <div className="mt-3">
                    <button
                      type="reset"
                      className="btn btn-outline-primary me-2 my-btn"
                      onClick={() => {
                        openBCatEdit({});
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="btn orange-btn btn-primary me-2"
                      onClick={handleUpdate}
                    >
                      Update
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
      {addCategoryModal && (
        <div className="modal reviewModal" style={style}>
          <div className="modal-dialog" style={{ width: "40%" }}>
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Add Blog Category
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={openData}
                ></button>
              </div>
              <div className="modal-body">
                <form
                  id="formAccountSettings"
                  method="POST"
                  onSubmit={handleClick}
                >
                  <div className="row">
                    {/* category add section */}
                    <div className="col-md-12  my-1">
                      <form method="post">
                        <div className="form-group">
                          <label className="form-label">
                            Enter Category Name
                          </label>
                          <input
                            type="text"
                            className="form-control    m-0"
                            name="cat_name"
                            id=""
                            aria-describedby="helpId"
                            placeholder="Blog Category Name"
                            onChange={onChange}
                          />
                        </div>
                      </form>
                    </div>

                    <div className="col-6">
                      <div className="form-group">
                        <label className="form-label">Enter Meta Title</label>
                        <input
                          type="text"
                          className="form-control    m-0"
                          name="meta_title"
                          id=""
                          aria-describedby="helpId"
                          placeholder="Enter Meta Title"
                          onChange={onChange}
                        />
                      </div>
                      <div className="form-group">
                        <label className="form-label">Enter URL</label>
                        <input
                          type="text"
                          className="form-control    m-0"
                          name="url"
                          id=""
                          aria-describedby="helpId"
                          placeholder="Enter URL"
                          onChange={onChange}
                        />
                      </div>
                      <div className="form-group">
                        <label className="form-label">
                          Enter Meta Discription
                        </label>
                        <input
                          type="text"
                          className="form-control    m-0"
                          name="meta_description"
                          id=""
                          aria-describedby="helpId"
                          placeholder="Enter Meta Discription"
                          onChange={onChange}
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <label className="form-label">
                          Enter Meta Keywords
                        </label>
                        <input
                          type="text"
                          className="form-control    m-0"
                          name="meta_keywords"
                          id=""
                          aria-describedby="helpId"
                          placeholder="Enter Meta Keywords"
                          onChange={onChange}
                        />
                      </div>
                      <div className="form-group">
                        <label className="form-label">Enter Meta Others</label>
                        <input
                          type="text"
                          className="form-control    m-0"
                          name="meta_others"
                          id=""
                          aria-describedby="helpId"
                          placeholder="Enter Meta Others"
                          onChange={onChange}
                        />
                      </div>
                    </div>

                    {/* category add section end */}
                  </div>
                  <div className="mt-3">
                    <button
                      type="reset"
                      className="btn btn-outline-primary me-2 my-btn"
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="btn orange-btn btn-primary me-2"
                      onClick={handleClick}
                    >
                      Add
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="content-wrapper">
        <div className="container-fluid flex-grow-1 container-p-y">
          <div className="fixed-button">
            <div
              className={`btn btn-sm fixed_button btn-outline-primary ${
                addCategoryModal ? "d-none" : "d-block"
              }`}
              onClick={openData}
            >
              + Add Blog Category
            </div>
          </div>
          <div className="row">
            <h4 className="card-header c_heading">
              <span className="headingcontent">
                My Blog Categories ({category.length})
              </span>
            </h4>

            <div className="col-lg-12 mb-4 ">
              <div className="card">
                <h5 className="card-header">
                  <div className="row">
                    <div className="col-lg-6 font-bold">
                      <ul className="slider_button slider-white plain-orange">
                        <li className="slider-active">Categories</li>
                      </ul>
                    </div>
                    <div className="col-lg-6 text-right">
                      <input
                        type="text"
                        className="form-control search"
                        placeholder="Search Category..."
                        onChange={(e) => {
                          setquery(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </h5>

                <div className="table-responsive text-nowrap mb-1">
                  <table className="table table-hover">
                    <thead className="order_history_header">
                      <tr>
                        <th className="action">Action</th>
                        <th>Sr.No.</th>
                        <th>Old ID</th>
                        <th>Primary Category</th>
                        <th>Meta Title</th>
                        {/*  <th>Meta Description</th>
                                                <th>Meta Keywords</th>
                                                <th>Meta Others</th>
 */}
                      </tr>
                    </thead>
                    <tbody className="table-border-bottom-0 order_history_body">
                      {category
                        .filter((cat) => {
                          return cat.cat_name
                            .toLowerCase()
                            .includes(query.toLowerCase());
                        })
                        .map((cat, index) => {
                          return (
                            <tr key={index}>
                              <td className="action">
                                <div className="dropdown ">
                                  <span className="badge bg-danger me-1">
                                    <i
                                      className="bx bx-trash "
                                      onClick={() => {
                                        delete_category(cat._id);
                                      }}
                                    ></i>
                                  </span>
                                  <span className="badge bg-secondary">
                                    <i
                                      className="bx bx-edit"
                                      onClick={() => {
                                        openBCatEdit(cat);
                                      }}
                                    ></i>
                                  </span>
                                </div>
                              </td>
                              <td>{index + 1}</td>
                              <td>{cat.cat_id}</td>
                              <th>{cat.cat_name}</th>
                              <td>{cat.meta_title}</td>

                              {/*  <td>{cat.meta_description}</td>
                                                        <td>{cat.meta_keywords}</td>
                                                        <td>{cat.meta_others}</td> */}
                            </tr>
                          );
                        })}
                    </tbody>
                    <DataTableFooter
                      col={5}
                      range={
                        (page === 1 ? "1" : (page - 1) * limit) +
                        "-" +
                        (page === 1 ? limit : page * limit)
                      }
                      total_item={total_category}
                      optionChange={(e) => {
                        setLimit(e.target.value);
                      }}
                      prePage={() => {
                        if (page > 1) {
                          setPage(page - 1);
                        }
                      }}
                      nextPage={() => {
                        setPage(page + 1);
                      }}
                    />
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default B_Cat;
