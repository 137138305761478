import React from "react";

function Alert(props) {
  const capitalize = (word) => {
    /* const lower = word.toLowerCase();
    return lower.charAt(0).toUpperCase() + lower.slice(1); */
    return word;
  };
  const style = {
    zIndex: 999999999,
    display: "block!important",
  };
  return (
    <>
      {props.alert && (
        <div
          className={`alert alert-${props.alert.type}  ${props.alert.active} alert-dismissible fade show`}
          style={style}
          role="alert"
        >
          <strong>{capitalize(props.alert.type)}</strong>: {props.alert.msg}
        </div>
      )}
    </>
  );
}

export default Alert;
