import React, { useState } from "react";
import UserCon from './UserCon'
/* require("dotenv").config(); */
const UserState = (props) => {
  const host = process.env.REACT_APP_URL;
  
  const token = localStorage.getItem("token");
  


  const [user, setuser] = useState([]);

  const createuser = async (body) => {
    try {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "auth-token": token
        }, body: JSON.stringify(body)
      };
      const response = await fetch(
        `${host}/api/user/createuser`,
        requestOptions
      );
      const dataresp = await response.json();
      console.log(dataresp);
      if (dataresp.status === "success") {
        setuser(user.concat(dataresp.data))
      }
      return dataresp;

    }
    catch (e) {

    }
  }
  const deleteuser = async (id) => {
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "auth-token": token
      },
    };
    const response = await fetch(
      `${host}/api/user/deleteuser/${id}`,
      requestOptions
    );
    const datares = await response.json();
    console.log(datares);
    if(datares.status=== "Success"){
      const updateData = user.filter((us)=>{
        return us._id!==id

      })

      setuser(updateData)
    }
    return datares
  };
  const getallUser = async ( type) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "auth-token": token
      },
    };
    const response = await fetch(
      `${host}/api/user/getUser`,
      requestOptions
    );
    const datares = await response.json();

 
    if(datares.status === "Success"){
      const upateData = datares.data.filter((user)=>{
        return user.utype === type;
      })
      setuser(upateData);
    }

    
  };
  const updateUser = async (id,body) => {
    try {
      const requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "auth-token": token
        }, body: JSON.stringify(body)
      };
      const response = await fetch(
        `${host}/api/user/updateuser/${id}`,
        requestOptions
      );
      const dataresp = await response.json();
      console.log(dataresp);
      if (dataresp.status === "Success") {

        for(let i = 0; i < user.length; i++){
          let element = user[i];
          if(element._id.toString() === id){
            user[i] = dataresp.data;
          }
        }
        setuser(user);
      }
      return dataresp;

    }
    catch (e) {

    }
  }



  
 
  return (
    <UserCon.Provider
      value={{
        user,
        getallUser,
        createuser,
        deleteuser,
        updateUser
       
      }}

    >
      {props.children}
    </UserCon.Provider>
  );
}
export default UserState;