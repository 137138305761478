import React, { useState } from "react";
import ClassifiedCon from "./ClassifiedCon";
/* require("dotenv").config(); */
const ClasssifiedState = (props) => {
  const host = process.env.REACT_APP_URL;
  console.log(host);
  const token = localStorage.getItem("token");
  console.log(token);


  const [classified, setClassified] = useState([]);

  const addClassified = async (body) => {
    try {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "auth-token": token
        }, body: JSON.stringify(body)
      };
      const response = await fetch(
        `${host}/api/classified/addclassified`,
        requestOptions
      );
      const dataresp = await response.json();
      console.log(dataresp);
      if (dataresp.status === "success") {
        setClassified(classified.concat(dataresp.data))
      }
      return dataresp;

    }
    catch (e) {

    }
  }
  const deleteClassified = async (id) => {
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "auth-token": token
      },
    };
    const response = await fetch(
      `${host}/api/classified/deleteclassified/${id}`,
      requestOptions
    );
    const datares = await response.json();

    console.log(datares);
    /* setCategory(datares.data); */
    if(datares.status=== "Success"){
      const updateData = classified.filter((classi)=>{
        return classi._id!==id

      })
      setClassified(updateData)
    }
    return datares
  };
  const getAllClassified = async ( type) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "auth-token": token
      },
    };
    const response = await fetch(
      `${host}/api/classified/getClassified`,
      requestOptions
    );
    const datares = await response.json();

    console.log(datares);
    if(datares.status === "Success"){
     
      setClassified(datares.data);
    }

    
  };
  const updateClassified = async ( id,body) => {
    try {
      const requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "auth-token": token
        }, body: JSON.stringify(body)
      };
      const response = await fetch(
        `${host}/api/classified/updateclassified/${id}`,
        requestOptions
      );
      const dataresp = await response.json();
      console.log(dataresp);
      if (dataresp.status === "Success") {

        for(let i = 0; i < classified.length; i++){
          let element = classified[i];
          if(element._id.toString() === id){
            classified[i] = dataresp.data;
          }
        }
        setClassified(classified);
      }
      return dataresp;

    }
    catch (e) {

    }
  }



  
  
 
  return (
    <ClassifiedCon.Provider
      value={{
        classified,
        getAllClassified,
        addClassified,
        deleteClassified,
        updateClassified
      }}

    >
      {props.children}
    </ClassifiedCon.Provider>
  );
}
export default ClasssifiedState;