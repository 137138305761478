import React, { useContext, useEffect, useState } from "react";
import Alert from "../componant/Alert";
import Preloader from "../componant/Preloader";
import tagCon from "../context/Tag/TagContext";

export const Tag = (props) => {
  const [preloader, setPreloader] = useState("");

  const myContext = useContext(tagCon)
  const { tag,
    getAllTag, createTag, deleteTag } = myContext

  useEffect(() => {
    getAllTag()
  }, [])
  const style = {
    background: "#00000080",
    display: "block",
  };
  const [newTag, setnewTag] = useState({
    tag_name: "",
    total_post: "",
    language: localStorage.getItem("lang")

  })
  const [addTagModal, setAddTagModal] = useState(false);
  //const [addsubCategoryModal, setAddsubCategoryModal] = useState(false);
  const openData = () => {
    setAddTagModal(!addTagModal);
  };

  const delete_tag = async (id) => {
    const response = await deleteTag(id)
    console.log(id);
    if (response.status === "Success") {
      props.showAlert(response.message, "success")
    }
    else {
      props.showAlert(response.message, "danger")
    }
  }

  const handleClick = async (e) => {
    e.preventDefault()
    const response = await createTag(newTag)
    console.log(response)
    console.log(newTag);
    if (response.status === "Success") {

      openData();
      props.showAlert(response.message, "success");
    } else {
      props.showAlert(response.message, "warning");
    }
    setPreloader("");
  };



  const onChange = (e) => {
    setnewTag({ ...newTag, [e.target.name]: e.target.value })
  }
  const [query, setquery] = useState("")
  /* slider start */
  const [statusChange, setstatusChange] = useState(null)
  const clickBlock = (status) => {

    setstatusChange(status)
    console.log(statusChange)
    const st = tag.filter((tag) => {
      return tag.status === statusChange
    })
    console.log(st)
  }
  /* slider ends */

  return (
    <>
      {addTagModal && (
        <div className="modal reviewModal" style={style}>
          <div className="modal-dialog" >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Add New Tag
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={openData}
                ></button>
              </div>
              <div className="modal-body">
                <form
                  id="formAccountSettings"
                  method="POST"
                  onSubmit={handleClick}
                >
                  <div className="row">
                    {/* category add section */}
                    <div className="col-md-12  my-1">
                      <form method="post">
                        <div className="form-group">
                          <label className="form-label">Enter Tag Name</label>
                          <input
                            type="text"
                            className="form-control    m-0"
                            name="tag_name"
                            id=""
                            aria-describedby="helpId"
                            placeholder="Tag Name"

                            onChange={onChange}
                          />
                          <label className="form-label">Enter Total Post</label>
                          <input
                            type="text"
                            className="form-control    m-0"
                            name="total_post"
                            id=""
                            aria-describedby="helpId"
                            placeholder="Total Post"

                            onChange={onChange}
                          />
                        </div>

                      </form>
                    </div>
                    {/* category add section end */}
                  </div>
                  <div className="mt-3">
                    <button
                      type="reset"
                      className="btn btn-outline-primary me-2 my-btn"
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="btn orange-btn btn-primary me-2"
                      onClick={handleClick}
                    >
                      Add
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="content-wrapper">
        <div className="container-fluid flex-grow-1 container-p-y">
          <div className="fixed-button">
            <div
              className={`btn btn-sm fixed_button btn-outline-primary ${addTagModal?"d-none":"d-block"}`}
              onClick={openData}
            >
              + Add Tag
            </div>

          </div>
          <div className="row">
            <h4 className="card-header c_heading">
              <span className="headingcontent">
                My Tags ({tag.length})

              </span>

              <div className="heading_buttons">
                <input
                  className="form-control  search  mb-4"
                  placeholder="Search tags..."
                />
              </div>
            </h4>

            <div className="col-lg-12 mb-4 ">
              <div className="card">
                <h5 className="card-header">
                  <div className="row">
                    <div className="col-lg-6 font-bold">
                      <ul className="slider_button slider-white plain-orange">
                        <li className="slider-active">Tags</li>
                      </ul>
                    </div>
                    <div className="col-lg-6 text-right">
                      <input
                        type="text"
                        className="form-control search"
                        placeholder="Search Tag..."
                      />
                    </div>
                  </div>
                </h5>

                <div className="table-responsive text-nowrap mb-1">
                  <table className="table table-hover">
                    <thead className="order_history_header">
                      <tr>

                        <th>Tag Name</th>

                        <th>Total Posts</th>
                        <th className="action">Action</th>
                      </tr>
                    </thead>
                    <tbody className="table-border-bottom-0 order_history_body">
                      {tag.filter((tag) => {
                        return (tag.tag_name.toLowerCase().includes(query?.toLowerCase()))
                      })
                        .map((tag, index) => {
                          return (<tr key={index}>

                            <td>{tag.tag_name}</td>

                            <td>{tag.total_post}</td>
                            <td className="action">

                              <div className="dropdown ">
                                <i className="bx bx-trash me-1"
                                  onClick={() => {
                                    delete_tag(tag._id)
                                  }}></i>

                                <i className="bx bx-edit me-1"
                                ></i>


                              </div></td>
                          </tr>)
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
