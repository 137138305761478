import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./section/Navbar";
import Login from "./pages/Login";
import LoginState from "./context/Login/LoginState";
import ContentWrapper from "./section/contentWraper";
import { useState } from "react";
import Alert from "./componant/Alert";
import { Category } from "./pages/Category";
import CatalogState from "./context/Category/CategoryState";
import { Tag } from "./pages/Tag";
import TagEState from "./context/Tag/TagState";
import { Post } from "./pages/Post";
import PostState from "./context/Post/PostState";
import { Directory } from "./pages/Directory";
import DirectoryState from "./context/Directory/DirectoryState";
import { Event } from "./pages/Event";
import EventState from "./context/Event/EventState";
import { Notification } from "./pages/Notification";
import NotiState from "./context/Notification/NotiState";
import { Media } from "./pages/Media";
import MediaState from "./context/Media/MediaState";
import { AddNewPost } from "./pages/AddNewPost";
import B_Cat from "./pages/B_Cat";
import BlogPostState from "./context/Blog-Post/BlogPost_State";
import { Blog_Post } from "./pages/Blog_Post";
import WriterState from "./context/Writer/WriterState";
import { Writer_info } from "./pages/Writer_Info";
import ClasssifiedState from "./context//Classified/ClasssifiedState";
import { Classified } from "./pages/Classified";
import PollState from "./context/Polls/PollState";
import { Poll } from "./pages/Poll";
import BannerState from "./context/Banner/BannerState";
import { Banner } from "./pages/Banner";
import TopBar from "./section/TopBar";
import BlogVedioState from "./context/VedioBlog/BlodVedioState";
import { BlogVedio } from "./pages/BlogVedio";
import UserState from "./context/User/UserState";
import { User } from "./pages/User";
import PageState from "./context/Pages/PageState";
import Page from "./pages/Page";
import StoryState from "./context/Stories/StoryState";
import Stories from "./pages/Stories";
import NewsState from "./context/Newsletter/NewsState";
import { Newsletter } from "./pages/Newsletter";

function App() {
  const [alert, setAlert] = useState(null);
  const showAlert = (message, type) => {
    setAlert({
      msg: message,
      type: type,
      active: "alert-active",
    });

    setTimeout(() => {
      setAlert(null);
    }, 3000);
  };

  return (
    <>
      <NewsState>
        <StoryState>
          <PageState>
            <UserState>
              <LoginState>
                <BlogVedioState>
                  <BannerState>
                    <PollState>
                      <ClasssifiedState>
                        <WriterState>
                          <BlogPostState>
                            <MediaState>
                              <NotiState>
                                <EventState>
                                  <DirectoryState>
                                    <PostState>
                                      <TagEState>
                                        <CatalogState>
                                          <Router>
                                            <Alert alert={alert} />

                                            {localStorage.getItem("token") ===
                                            null ? (
                                              <>
                                                <Routes>
                                                  <Route
                                                    path="/"
                                                    element={
                                                      <Login
                                                        showAlert={showAlert}
                                                      />
                                                    }
                                                  />
                                                </Routes>
                                              </>
                                            ) : (
                                              <>
                                                <div className="layout-wrapper layout-content-navbar">
                                                  <div className="layout-container">
                                                    <Navbar />
                                                    <div className="layout-page">
                                                      <TopBar />
                                                      <Routes>
                                                        <Route
                                                          path="/newsletter"
                                                          element={
                                                            <Newsletter
                                                              showAlert={
                                                                showAlert
                                                              }
                                                            />
                                                          }
                                                        />
                                                        <Route
                                                          path="/story"
                                                          element={
                                                            <Stories
                                                              showAlert={
                                                                showAlert
                                                              }
                                                            />
                                                          }
                                                        />
                                                        <Route
                                                          path="/page"
                                                          element={
                                                            <Page
                                                              showAlert={
                                                                showAlert
                                                              }
                                                            />
                                                          }
                                                        />
                                                        <Route
                                                          path="/user"
                                                          element={
                                                            <User
                                                              showAlert={
                                                                showAlert
                                                              }
                                                            />
                                                          }
                                                        />
                                                        <Route
                                                          path="/blog_vedio"
                                                          element={
                                                            <BlogVedio
                                                              showAlert={
                                                                showAlert
                                                              }
                                                            />
                                                          }
                                                        />
                                                        <Route
                                                          path="/banner"
                                                          element={
                                                            <Banner
                                                              showAlert={
                                                                showAlert
                                                              }
                                                            />
                                                          }
                                                        />
                                                        <Route
                                                          path="/poll"
                                                          element={
                                                            <Poll
                                                              showAlert={
                                                                showAlert
                                                              }
                                                            />
                                                          }
                                                        />
                                                        <Route
                                                          path="/classified"
                                                          element={
                                                            <Classified
                                                              showAlert={
                                                                showAlert
                                                              }
                                                            />
                                                          }
                                                        />
                                                        <Route
                                                          path="/writer_info"
                                                          element={
                                                            <Writer_info
                                                              showAlert={
                                                                showAlert
                                                              }
                                                            />
                                                          }
                                                        />
                                                        <Route
                                                          path="/blog-post"
                                                          element={
                                                            <Blog_Post
                                                              showAlert={
                                                                showAlert
                                                              }
                                                            />
                                                          }
                                                        />
                                                        <Route
                                                          path="/blog-category"
                                                          element={
                                                            <B_Cat
                                                              showAlert={
                                                                showAlert
                                                              }
                                                            />
                                                          }
                                                        />
                                                        <Route
                                                          path="/media"
                                                          element={
                                                            <Media
                                                              showAlert={
                                                                showAlert
                                                              }
                                                            />
                                                          }
                                                        />
                                                        <Route
                                                          path="/"
                                                          element={
                                                            <ContentWrapper
                                                              showAlert={
                                                                showAlert
                                                              }
                                                            />
                                                          }
                                                        />
                                                        <Route
                                                          path="/notification"
                                                          element={
                                                            <Notification
                                                              showAlert={
                                                                showAlert
                                                              }
                                                            />
                                                          }
                                                        />

                                                        <Route
                                                          path="/event"
                                                          element={
                                                            <Event
                                                              showAlert={
                                                                showAlert
                                                              }
                                                            />
                                                          }
                                                        />

                                                        <Route
                                                          path="/directory"
                                                          element={
                                                            <Directory
                                                              showAlert={
                                                                showAlert
                                                              }
                                                            />
                                                          }
                                                        />

                                                        <Route
                                                          path="/post"
                                                          element={
                                                            <Post
                                                              showAlert={
                                                                showAlert
                                                              }
                                                            />
                                                          }
                                                        />
                                                        <Route
                                                          path="post/addnewpost"
                                                          element={
                                                            <AddNewPost
                                                              showAlert={
                                                                showAlert
                                                              }
                                                            />
                                                          }
                                                        />

                                                        <Route
                                                          path="/tag"
                                                          element={
                                                            <Tag
                                                              showAlert={
                                                                showAlert
                                                              }
                                                            />
                                                          }
                                                        />

                                                        <Route
                                                          path="/dashboard"
                                                          element={
                                                            <ContentWrapper
                                                              showAlert={
                                                                showAlert
                                                              }
                                                            />
                                                          }
                                                        />
                                                        <Route
                                                          path="/category"
                                                          element={
                                                            <Category
                                                              showAlert={
                                                                showAlert
                                                              }
                                                            />
                                                          }
                                                        />
                                                      </Routes>
                                                    </div>
                                                  </div>
                                                </div>
                                              </>
                                            )}
                                          </Router>
                                        </CatalogState>
                                        {/* second */}
                                      </TagEState>
                                    </PostState>
                                  </DirectoryState>
                                </EventState>
                              </NotiState>
                            </MediaState>
                          </BlogPostState>
                        </WriterState>
                      </ClasssifiedState>
                    </PollState>
                  </BannerState>
                </BlogVedioState>
              </LoginState>
            </UserState>
          </PageState>
        </StoryState>
      </NewsState>
    </>
  );
}

export default App;
