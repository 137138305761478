import React, { useContext, useEffect, useRef, useState } from "react";
import Alert from "../componant/Alert";
import Preloader from "../componant/Preloader";
import EventContext from "../context/Event/EventContext";
import UserCon from "../context/User/UserCon";
import JoditEditor from "jodit-react";
import parse from "html-react-parser";
import { Link } from "react-router-dom";
import UrduKeyboard from "../componant/Urdu/UrduKeyboard";
import HindiKeyboard from "../componant/Hindi/HindiKeyboard";

export const Event = (props) => {
  const [preloader, setPreloader] = useState("");

  const myContext = useContext(EventContext);
  const { event, getAllEvent, createEvent, deleteEvent, updateEvent } =
    myContext;

  useEffect(() => {
    getAllEvent();
    getallUser("user");
  }, []);

  const userCon = useContext(UserCon);
  const { user, getallUser } = userCon;

  const style = {
    background: "#00000080",
    display: "block",
  };
  const [newEvent, setnewEvent] = useState({
    title: "",
    event_details: "",
    language: localStorage.getItem("lang"),
  });
  /* jodit start */
  const editor = useRef(null);
  const [content, setContent] = useState("");
  /* jodit end */

  const [addEventModal, setAddEventModal] = useState(false);

  const openData = () => {
    setAddEventModal(!addEventModal);
  };

  const delete_Event = async (id) => {
    setPreloader("preShow");
    const response = await deleteEvent(id);
    console.log(id);
    if (response.status === "Success") {
      openData();
      props.showAlert(response.message, "success");
    } else {
      props.showAlert(response.message, "warning");
    }
    setPreloader("");
  };
  const handleClick = async (e) => {
    e.preventDefault();
    if (
      localStorage.getItem("lang") === "2" ||
      localStorage.getItem("lang") === "1"
    ) {
      newEvent.title = urdudata.title;
      newEvent.speakers = urdudata.speakers;
      newEvent.chief_guest = urdudata.chief_guest;
      newEvent.address = urdudata.address;
      newEvent.event_details = content;
    }

    setPreloader("preShow");
    const response = await createEvent(newEvent);
    console.log(newEvent);
    if (response.status === "success") {
      openData();
      props.showAlert(response.message, "success");
    } else {
      props.showAlert(response.message, "warning");
    }
    setPreloader("");
  };

  /* edit section */
  const onedit = (e) => {
    setupdateEve({ ...updateEve, [e.target.name]: e.target.value });
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    setPreloader("preShow");
    if (
      localStorage.getItem("lang") === "2" ||
      localStorage.getItem("lang") === "1"
    ) {
      updateEve.title = urdudata.title;
      updateEve.chief_guest = urdudata.chief_guest;
      updateEve.speakers = urdudata.speakers;
      updateEve.address = urdudata.address;
    }
    const response = await updateEvent(updateEve, updateEve._id);

    if (response.status === "Success") {
      openEveEdit({});
      props.showAlert(response.message, "success");
    } else {
      props.showAlert(response.message, "warning");
    }
    setPreloader("");
  };
  const [editEventModel, seteditEventModel] = useState(false);
  const [updateEve, setupdateEve] = useState({});
  const openEveEdit = (cat) => {
    console.log(cat);
    seteditEventModel(!editEventModel);
    setupdateEve(cat);
  };

  const onChange = (e) => {
    setnewEvent({ ...newEvent, [e.target.name]: e.target.value });
  };

  const [urdudata, seturdudata] = useState({
    title: "",
    address: "",
    chief_guest: "",
    speakers: "",
  });
  const inputUrdu = (key, value) => {
    urdudata[key] = value;
    console.log("key" + key + " value-" + value);
  };
  const [query, setquery] = useState("");
  return (
    <>
      <Preloader show={preloader} />
      {editEventModel && (
        <div className="modal reviewModal" style={style}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Add New Event
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    openEveEdit({});
                  }}
                ></button>
              </div>
              <div className="modal-body">
                <form id="formAccountSettings">
                  <div className="row">
                    {/* category add section */}
                    <div className="col-md-12  my-1">
                      <form method="post">
                        <div className="col-6">
                          <div className="form-group">
                            <label className="form-label">Enter User Id</label>
                            <select
                              className="form-control"
                              name="user_id"
                              onChange={onedit}
                            >
                              <option value={0}>Select user</option>
                              {user.map((u, i) => {
                                return <option value={u._id}>{u.fname}</option>;
                              })}
                            </select>
                          </div>
                          <label className="form-label">Enter Title</label>
                          {localStorage.getItem("lang") === "0" ? (
                            <input
                              type="text"
                              className={`form-control   m-0`}
                              name="title"
                              id=""
                              aria-describedby="helpId"
                              placeholder="Enter state"
                              defaultValue={updateEve.title}
                              onChange={onedit}
                            />
                          ) : localStorage.getItem("lang") === "1" ? (
                            <HindiKeyboard
                              inputvalue={updateEve.title}
                              keyinput="title"
                              onchange={inputUrdu}
                            />
                          ) : localStorage.getItem("lang") === "2" ? (
                            <UrduKeyboard
                              inputvalue={updateEve.title}
                              inputUrdu={inputUrdu}
                              inputkey="title"
                            />
                          ) : (
                            ""
                          )}
                          <label className="form-label">
                            Enter Chief Guest
                          </label>
                          {localStorage.getItem("lang") === "0" ? (
                            <input
                              type="text"
                              className={`form-control   m-0`}
                              name="title"
                              id=""
                              aria-describedby="helpId"
                              placeholder="Enter state"
                              defaultValue={updateEve.chief_guest}
                              onChange={onedit}
                            />
                          ) : localStorage.getItem("lang") === "1" ? (
                            <HindiKeyboard
                              inputvalue={updateEve.chief_guest}
                              keyinput="chief_guest"
                              onchange={inputUrdu}
                            />
                          ) : localStorage.getItem("lang") === "2" ? (
                            <UrduKeyboard
                              inputvalue={updateEve.chief_guest}
                              inputUrdu={inputUrdu}
                              inputkey="chief_guest"
                            />
                          ) : (
                            ""
                          )}

                          <label className="form-label">Enter Speakers</label>
                          {localStorage.getItem("lang") === "0" ? (
                            <input
                              type="text"
                              className={`form-control   m-0`}
                              name="speakers"
                              id=""
                              aria-describedby="helpId"
                              placeholder="Enter state"
                              defaultValue={updateEve.speakers}
                              onChange={onedit}
                            />
                          ) : localStorage.getItem("lang") === "1" ? (
                            <HindiKeyboard
                              inputvalue={updateEve.speakers}
                              keyinput="speakers"
                              onchange={inputUrdu}
                            />
                          ) : localStorage.getItem("lang") === "2" ? (
                            <UrduKeyboard
                              inputvalue={updateEve.speakers}
                              inputUrdu={inputUrdu}
                              inputkey="speakers"
                            />
                          ) : (
                            ""
                          )}
                        </div>

                        <div className="col-6">
                          <label className="form-label">Enter Email Id</label>
                          <input
                            type="text"
                            className="form-control    m-0"
                            name="email"
                            id=""
                            aria-describedby="helpId"
                            value={updateEve.email}
                            placeholder="Email"
                            onChange={onedit}
                          />
                          <label className="form-label">
                            Enter Phone Number
                          </label>
                          <input
                            type="number"
                            className="form-control    m-0"
                            name="phone"
                            id=""
                            aria-describedby="helpId"
                            value={updateEve.phone}
                            placeholder="Phone"
                            onChange={onedit}
                          />
                          <div className="form-group">
                            <label className="form-label">Enter Date</label>
                            <input
                              type="date"
                              className="form-control    m-0"
                              name="createDate"
                              id=""
                              aria-describedby="helpId"
                              defaultValue={updateEve.createDate}
                              placeholder="Update Date"
                              onChange={onedit}
                            />
                          </div>
                          <label className="form-label">Enter Address</label>
                          {localStorage.getItem("lang") === "0" ? (
                            <textarea
                              type="textarea"
                              className={`form-control   m-0`}
                              name="address"
                              id=""
                              aria-describedby="helpId"
                              placeholder="Enter state"
                              defaultValue={updateEve.address}
                              onChange={onedit}
                            />
                          ) : localStorage.getItem("lang") === "1" ? (
                            <HindiKeyboard
                              inputvalue={updateEve.address}
                              keyinput="address"
                              onchange={inputUrdu}
                            />
                          ) : localStorage.getItem("lang") === "2" ? (
                            <UrduKeyboard
                              inputvalue={updateEve.address}
                              inputUrdu={inputUrdu}
                              inputkey="address"
                            />
                          ) : (
                            ""
                          )}
                        </div>

                        <div className="form-group">
                          <label className="form-label">
                            Enter Event Details
                          </label>
                          <JoditEditor
                            ref={editor}
                            value={content}
                            onChange={(newContent) => setContent(newContent)}
                          />
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="mt-3">
                    <button
                      type="reset"
                      className="btn btn-outline-primary me-2 my-btn"
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="btn orange-btn btn-primary me-2"
                      onClick={handleUpdate}
                    >
                      Update Data
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}

      {addEventModal && (
        <div className="modal reviewModal" style={style}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Add New Event
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={openData}
                ></button>
              </div>
              <div className="modal-body">
                <form
                  id="formAccountSettings"
                  method="POST"
                  onSubmit={handleClick}
                >
                  <div className="row">
                    {/* category add section */}
                    <div className="col-md-6  my-1">
                      <div className="form-group">
                        <label className="form-label">Enter User Id</label>
                        <select
                          className="form-control"
                          name="user_id"
                          onChange={onChange}
                        >
                          <option value={0}>Select user</option>
                          {user.map((u, i) => {
                            return <option value={u._id}>{u.fname}</option>;
                          })}
                        </select>
                      </div>
                      <div className="form-group">
                        <label className="form-label">Enter title</label>
                        {localStorage.getItem("lang") === "0" ? (
                          <input
                            type="text"
                            className={`form-control   m-0`}
                            name="title"
                            id=""
                            aria-describedby="helpId"
                            placeholder="Enter Title"
                            onChange={onChange}
                          />
                        ) : localStorage.getItem("lang") === "1" ? (
                          <HindiKeyboard
                            keyinput="title"
                            onchange={inputUrdu}
                          />
                        ) : localStorage.getItem("lang") === "2" ? (
                          <UrduKeyboard
                            inputUrdu={inputUrdu}
                            inputkey="title"
                          />
                        ) : (
                          ""
                        )}

                        <label className="form-label">Enter Speakers</label>
                        {localStorage.getItem("lang") === "0" ? (
                          <input
                            type="text"
                            className={`form-control   m-0`}
                            name="speakers"
                            id=""
                            aria-describedby="helpId"
                            placeholder="Enter speakers"
                            onChange={onChange}
                          />
                        ) : localStorage.getItem("lang") === "1" ? (
                          <HindiKeyboard
                            keyinput="speakers"
                            onchange={inputUrdu}
                          />
                        ) : localStorage.getItem("lang") === "2" ? (
                          <UrduKeyboard
                            inputUrdu={inputUrdu}
                            inputkey="speakers"
                          />
                        ) : (
                          ""
                        )}

                        <label className="form-label">Enter Phone Number</label>
                        <input
                          type="number"
                          className="form-control    m-0"
                          name="phone"
                          id=""
                          aria-describedby="helpId"
                          placeholder="Phone"
                          onChange={onChange}
                        />

                        <label className="form-label">Enter Address</label>
                        {localStorage.getItem("lang") === "0" ? (
                          <input
                            type="text"
                            className={`form-control   m-0`}
                            name="address"
                            id=""
                            aria-describedby="helpId"
                            placeholder="Enter Address"
                            onChange={onChange}
                          />
                        ) : localStorage.getItem("lang") === "1" ? (
                          <HindiKeyboard
                            keyinput="address"
                            onchange={inputUrdu}
                          />
                        ) : localStorage.getItem("lang") === "2" ? (
                          <UrduKeyboard
                            inputUrdu={inputUrdu}
                            inputkey="address"
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </div>

                    <div className="col-md-6 my-1">
                      <div className="form-group">
                        <label className="form-label">Enter Chief Guest</label>
                        {localStorage.getItem("lang") === "0" ? (
                          <input
                            type="text"
                            className={`form-control   m-0`}
                            name="chief_guest"
                            id=""
                            aria-describedby="helpId"
                            placeholder="Chief Guest"
                            onChange={onChange}
                          />
                        ) : localStorage.getItem("lang") === "1" ? (
                          <HindiKeyboard
                            keyinput="chief_guest"
                            onchange={inputUrdu}
                          />
                        ) : localStorage.getItem("lang") === "2" ? (
                          <UrduKeyboard
                            inputUrdu={inputUrdu}
                            inputkey="chief_guest"
                          />
                        ) : (
                          ""
                        )}
                      </div>

                      <div className="form-group">
                        <label className="form-label">Enter Email Id</label>
                        <input
                          type="text"
                          className="form-control    m-0"
                          name="email"
                          id=""
                          aria-describedby="helpId"
                          placeholder="Email"
                          onChange={onChange}
                        />
                      </div>
                      <div className="form-group">
                        <label className="form-label">Enter Date</label>
                        <input
                          type="date"
                          className="form-control    m-0"
                          name="createDate"
                          id=""
                          aria-describedby="helpId"
                          placeholder="Enter Date"
                          onChange={onChange}
                        />
                      </div>

                      <div className="form-group">
                        <label className="form-label"> Status</label>
                        <table width="100%">
                          <tr>
                            <td>
                              <div className="form-check">
                                <input
                                  type="radio"
                                  className="form-check-input"
                                  name="status"
                                  value={false}
                                  onChange={onChange}
                                />
                                <label className="form-label"> Draft</label>
                              </div>
                            </td>
                            <td>
                              <div className="form-check">
                                <input
                                  type="radio"
                                  className="form-check-input"
                                  name="status"
                                  value={true}
                                  onChange={onChange}
                                />
                                <label className="form-label"> Publish</label>
                              </div>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="form-group">
                        <label className="form-label">
                          Enter Event Details
                        </label>
                        <JoditEditor
                          ref={editor}
                          value={content}
                          onChange={(newContent) => setContent(newContent)}
                        />
                      </div>
                    </div>
                    {/* category add section end */}
                  </div>
                  <div className="mt-3">
                    <button
                      type="reset"
                      className="btn btn-outline-primary me-2 my-btn"
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="btn orange-btn btn-primary me-2"
                      onClick={handleClick}
                    >
                      Add
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="content-wrapper">
        <div className="container-fluid flex-grow-1 container-p-y">
          <div className="fixed-button">
            <div
              className={`btn btn-sm fixed_button btn-outline-primary ${
                addEventModal ? "d-none" : "d-block"
              }`}
              onClick={openData}
            >
              + Add Event
            </div>
          </div>
          <div className="row">
            <h4 className="card-header c_heading">
              <span className="headingcontent">My Events({event.length})</span>
            </h4>

            <div className="col-lg-12 mb-4 ">
              <div className="card">
                <h5 className="card-header">
                  <div className="row">
                    <div className="col-lg-6 font-bold">
                      <ul className="slider_button slider-white plain-orange">
                        <li className="slider-active">Events</li>
                      </ul>
                    </div>
                    <div className="col-lg-6 text-right">
                      <input
                        type="text"
                        className="form-control search"
                        placeholder="Search Events..."
                        onChange={(e) => {
                          setquery(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </h5>

                <div className="table-responsive text-nowrap mb-1">
                  <table className="table table-hover">
                    <thead className="order_history_header">
                      <tr>
                        {/*   <th>User id</th> */}
                        <th>Title</th>
                        <th>Chief Guest</th>
                        <th>Speakers</th>
                        <th>Email</th>
                        <th>Date</th>
                        {/* <th>Phone</th>
                        <th>Address</th> */}
                        {/*  <th>Event Details</th> */}

                        <th className="action">Action</th>
                      </tr>
                    </thead>
                    <tbody className="table-border-bottom-0 order_history_body">
                      {event
                        .filter((event) => {
                          return (
                            event.title
                              ?.toLowerCase()
                              .includes(query?.toLowerCase()) ||
                            event.chief_guest
                              ?.toLowerCase()
                              .includes(query?.toLowerCase()) ||
                            event.speakers
                              ?.toLowerCase()
                              .includes(query?.toLowerCase()) ||
                            event.phone
                              ?.toString()
                              .toLowerCase()
                              .includes(query?.toLowerCase())
                          );
                        })
                        .map((event, index) => {
                          return (
                            <tr key={index}>
                              {/*  <td>{event.user_id === null ? "..." : event.user_id.fname}</td> */}
                              <td>{event.title}</td>
                              <td> {event.chief_guest}</td>
                              <td>
                                {" "}
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: event.speakers,
                                  }}
                                />{" "}
                              </td>
                              <td>{event.email}</td>
                              <td>{event.createDate}</td>
                              {/*  <td>{event.phone}</td>
                            <td>{event.address}</td> */}

                              {/*    <td>{event.event_details}</td> */}

                              <td className="action">
                                <div className="dropdown ">
                                  <i
                                    className="bx bx-trash me-1"
                                    onClick={() => {
                                      delete_Event(event._id);
                                    }}
                                  ></i>

                                  <i
                                    className="bx bx-edit me-1"
                                    onClick={() => {
                                      openEveEdit(event);
                                    }}
                                  ></i>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
